import Grid from "@material-ui/core/Grid"
import React, { Component } from "react"
import { connect } from "react-redux"
import { Router } from "react-router-dom"
import styled from "styled-components"

import GlobalAlertContainer from "./alerts/containers/GlobalAlertContainer"
import MissingDetailsContainer from "./alerts/containers/MissingDetailsContainer"
import { fetchCurrentUser } from "./auth/actions/fetchCurrentUser"
import HeartbeatContainer from "./auth/containers/HeartbeatContainer"
import { isCurrentUser } from "./auth/selectors/currentUserGetters"
import { hasFetched } from "./auth/selectors/hasFetched"
import FooterWrapper from "./footer/components/FooterWrapper"
import AppWrapper from "./helpers/components/AppWrapper"
import LoadingWrapper from "./helpers/components/LoadingWrapper"
import NavbarContainer from "./navbar/containers/NavbarContainer"
import ErrorSnackbarContainer from "./notifications/containers/ErrorSnackbarContainer"
import SuccessSnackbarContainer from "./notifications/containers/SuccessSnackbarContainer"
import Routes from "./routes/components/Routes"
import ScrollResetGrid from "./routes/components/ScrollResetGrid"
import SidebarContainer from "./sidebar/containers/SidebarContainer"
import { history, State } from "./store"

const mapStateToProps = (state: State): Partial<Props> => {
  return {
    isUserLoaded: hasFetched(state.auth),
    isLoggedIn: isCurrentUser(state),
    user: state.auth.currentUser,
  }
}

const mapDispatchToProps = {
  fetchLoggedInUser: fetchCurrentUser,
}

interface Props {
  isUserLoaded: boolean
  isLoggedIn: boolean
  user: User | null
  errorMessage?: string
  fetchLoggedInUser: () => void
}

class App extends Component<Props> {
  componentDidMount() {
    if (!this.props.isUserLoaded) {
      this.props.fetchLoggedInUser()
    }
  }

  render() {
    const { isUserLoaded, isLoggedIn, user } = this.props
    return (
      <div className="App">
        <Router history={history}>
          <AppWrapper>
            <HeartbeatContainer />
            <SuccessSnackbarContainer />
            <ErrorSnackbarContainer />
            <LoadingWrapper isLoading={!isUserLoaded} large>
              <Grid
                container
                wrap="nowrap"
                spacing={0}
                style={{ height: "100vh" }}
              >
                <SidebarGrid item>
                  <SidebarContainer />
                </SidebarGrid>
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    spacing={0}
                    style={{ height: "100%" }}
                  >
                    <FullwidthGrid item>
                      <NavbarContainer />
                    </FullwidthGrid>
                    <FullwidthGrid item>
                      <MissingDetailsContainer />
                    </FullwidthGrid>
                    <FullwidthGrid item>
                      <GlobalAlertContainer />
                    </FullwidthGrid>
                    <ScrollResetGrid>
                      <Routes isLoggedIn={isLoggedIn} user={user}>
                        <FooterWrapper />
                      </Routes>
                    </ScrollResetGrid>
                  </Grid>
                </Grid>
              </Grid>
            </LoadingWrapper>
          </AppWrapper>
        </Router>
      </div>
    )
  }
}

const SidebarGrid = styled(Grid)`
  z-index: 1200;
  height: 100%;
`
const FullwidthGrid = styled(Grid)`
  width: 100%;
`

export default connect(mapStateToProps, mapDispatchToProps)(App)
