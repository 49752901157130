import { gql } from "apollo-boost"

export default gql`
  query GetFooter {
    footer {
      columnOne
      columnTwo
      columnThree
    }
  }
`
