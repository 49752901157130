import { createSelector } from "reselect"

import { AuthState } from "../reducers"

const currentError = (state: AuthState) => state.error
const fieldErrors = (state: AuthState) => state.errors

export const getEmailError = createSelector(
  [currentError, fieldErrors],
  (error: ApiError | undefined, errors: RestError | undefined) => {
    if (error && error.name === "UserExistsError") {
      return "A user with that email address already exists" as string
    }
    if (errors && errors["email"]) {
      return errors["email"].join(". ")
    }
  }
)

export const getPasswordError = createSelector(
  [fieldErrors],
  (errors: RestError | undefined) => {
    if (errors && errors["password1"]) {
      return "This password is too common" as string
    }
    if (errors && errors["password2"]) {
      return "These passwords do not match" as string
    }
  }
)
