import { gql } from "apollo-boost"

export default gql`
  query GetLatestCourses {
    availableShopCourses(first: 3, homePinned: true) {
      id
      pinned
      course {
        id
        title
        courseType
        price
        hours
      }
    }
  }
`
