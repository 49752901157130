import { FieldErrors } from "../components/v2/core/ControlledProps"

interface FieldError {
  type: string
  types?: Record<string, string | boolean> // if criteriaMode is set to 'all'
  message: string
}

export const getErrorFromName = (
  name: string,
  errors: FieldErrors
): undefined => {
  const isNullOrUndefined = (value: any) =>
    value === null || value === undefined
  const result = name
    .split(/[,[\].]+?/)
    .filter(Boolean)
    .reduce((r, key) => (isNullOrUndefined(r) ? r : r[key]), errors)
  return result === undefined || result === errors ? errors[name] : result
}

export const getErrorMessageFromFieldError = (
  fieldError?: FieldError
): string | undefined => {
  if (!fieldError) {
    return undefined
  }
  if (fieldError.message) {
    return String(fieldError.message)
  }
  switch (fieldError.type) {
    case "required":
      return "This field is required"
    case "maxLength":
      return "The input data has exceeded the max length"
    default:
      return undefined
  }
}
