import { Middleware } from "redux"

import { Status } from "../../helpers/reducers"
import { createErrorNotification } from "../actions/createErrorNotification"
import { createSuccessNotification } from "../actions/createSuccessNotification"
import { NOTIFICATION_ERROR_TYPE } from "../operators/errorBuilder"

export const handleAsyncFailures: Middleware = (api) => (next) => (action) => {
  const isFailureAction = action.type.endsWith(Status.FAILED)
  const isErrorNotification =
    action.meta?.error?.type === NOTIFICATION_ERROR_TYPE
  if (isFailureAction && isErrorNotification) {
    api.dispatch(createErrorNotification(action.meta.error.message))
  }
  const isSuccessAction = action.type.endsWith(Status.SUCCEEDED)
  const isSuccessNotification = action.meta?.success?.message
  if (isSuccessAction && isSuccessNotification) {
    api.dispatch(createSuccessNotification(action.meta.success.message))
  }
  return next(action)
}
