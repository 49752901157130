import React from "react"
import { LoadingComponentProps } from "react-loadable"
import styled from "styled-components"

import LoadingWrapper from "../helpers/components/LoadingWrapper"

const ErrorWrapper = styled.div`
  padding: 1em;
`

const Loading = (props: LoadingComponentProps) => {
  if (props.error) {
    return (
      <ErrorWrapper>
        There was an error loading this page!{" "}
        <button onClick={props.retry}>Retry</button>
      </ErrorWrapper>
    )
  } else if (props.pastDelay) {
    return <LoadingWrapper isLoading large />
  } else {
    return null
  }
}

export default Loading
