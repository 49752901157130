import { Middleware } from "redux"

import { routes } from "../../routes/constants/routes"
import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
} from "../constants/ActionTypes"

export const handleAuth: Middleware = () => (next) => (action) => {
  const { type } = action
  switch (type) {
    case LOGIN_USER.SUCCEEDED:
      window.location.assign(routes.APP.DASHBOARD)
      break
    case LOGOUT_USER.SUCCEEDED:
      window.location.assign(routes.AUTH.LOGIN)
      break
    case REGISTER_USER.SUCCEEDED:
      window.location.assign(routes.AUTH.REGISTER)
      break
    default:
      return next(action)
  }
}
