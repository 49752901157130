import { Action, handleActions } from "redux-actions"

import {
  CLOSE_ERROR_NOTIFICATION,
  CLOSE_SUCCESS_NOTIFICATION,
  NOTIFICATION_ERROR_MESSAGE,
  NOTIFICATION_SUCCESS_MESSAGE,
} from "../constants/ActionTypes"

export interface NotificationState {
  successes: string[]
  errors: string[]
}

const defaultState: NotificationState = {
  successes: [],
  errors: [],
}

export const notificationReducer = handleActions<NotificationState, any>(
  {
    [NOTIFICATION_ERROR_MESSAGE](state, action: Action<string>) {
      const errors = [...state.errors]
      const { payload: errorMessage } = action
      if (errorMessage) {
        errors.push(errorMessage)
      }
      return {
        ...state,
        errors,
      }
    },
    [NOTIFICATION_SUCCESS_MESSAGE](state, action: Action<string>) {
      const successes = [...state.successes]
      const { payload: message } = action
      if (message) {
        successes.push(message)
      }
      return {
        ...state,
        successes,
      }
    },
    [CLOSE_ERROR_NOTIFICATION](state) {
      return {
        ...state,
        errors: state.errors.slice(1),
      }
    },
    [CLOSE_SUCCESS_NOTIFICATION](state) {
      return {
        ...state,
        successes: state.successes.slice(1),
      }
    },
  },
  defaultState
)
