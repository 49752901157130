import React, { Component } from "react"
import { RouteComponentProps } from "react-router-dom"

import { getPathParams } from "../../../helpers/operators/getPathParams"
import { OnSaveProps } from "../../../notifications/containers/withOnSaveMessage"
import { routes } from "../../../routes/constants/routes"
import ConfirmForm from "./ConfirmForm"

interface Props extends RouteComponentProps, OnSaveProps {
  isLoading: boolean
  resetFailed: boolean
  onPasswordReset: (
    newPassword1: string,
    newPassword2: string,
    uid: string,
    token: string
  ) => void
  resetStatus: () => void
}

class ConfirmWrapper extends Component<Props> {
  private timeout?: number

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (!this.props.resetFailed) {
        this.props.onSaveMessage(
          "Password reset successfully. Redirecting to login..."
        )
        this.timeout = window.setTimeout(() => {
          this.props.resetStatus()
          window.location.assign(routes.AUTH.LOGIN)
        }, 3000)
      }
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  render() {
    const { isLoading, resetFailed } = this.props
    return (
      <ConfirmForm
        requestLoading={isLoading}
        requestError={resetFailed}
        onSubmit={this.onSubmit}
      />
    )
  }

  private readonly onSubmit = ({
    password,
    confirmPassword,
  }: {
    password: string
    confirmPassword: string
  }) => {
    const token = this.getToken()
    const uid = this.getUid()
    this.props.onPasswordReset(password, confirmPassword, uid, token)
  }

  private getToken() {
    const { token } = getPathParams(this.props)
    return token
  }

  private getUid() {
    const { uid } = getPathParams(this.props)
    return uid
  }
}

export default ConfirmWrapper
