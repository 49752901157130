import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useForm } from "react-hook-form"

import Divider from "../../../helpers/components/Divider"
import FullwidthGrid from "../../../helpers/components/FullwidthGrid"
import RouterLink from "../../../helpers/components/RouterLink"
import PasswordField from "../../../input/components/v2/auth/PasswordField"
import ControlledCheckbox from "../../../input/components/v2/core/ControlledCheckbox"
import ControlledTextField from "../../../input/components/v2/core/ControlledTextField"
import { emailPattern } from "../../../input/constants/validationPatterns"
import { routes } from "../../../routes/constants/routes"
import FormHolder from "../FormHolder"
import LoadingButton from "../LoadingButton"

interface Props {
  onSubmit: (payload: State) => void
  requestLoading: boolean
  requestError?: string
}

export interface State {
  email: string
  password: string
  remember: boolean
}

const LoginForm = ({ onSubmit, requestError, requestLoading }: Props) => {
  const { handleSubmit, control, errors } = useForm<State>({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      email: "",
      password: "",
      remember: false,
    },
  })
  return (
    <FormHolder
      maxWidth={450}
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Typography variant="h5" gutterBottom>
        Sign In
      </Typography>
      <Grid container>
        <FullwidthGrid item sm={12}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="email"
            autoComplete="username"
            label="Email address"
            rules={{ required: true, pattern: emailPattern }}
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <PasswordField
            control={control}
            errors={errors}
            name="password"
            autoComplete="current-password"
            label="Password"
            error={Boolean(requestError)}
            helperText={requestError}
            rules={{ required: true, validate: () => true }}
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={requestLoading}
            size="large"
            fullWidth
          >
            Login
          </LoadingButton>
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              <ControlledCheckbox
                control={control}
                errors={errors}
                name="remember"
                color="primary"
              >
                <Typography align="left">Remember me</Typography>
              </ControlledCheckbox>
            </Grid>
            <Grid item>
              <RouterLink to={routes.AUTH.RESET} blue>
                Forgot Password?
              </RouterLink>
            </Grid>
          </Grid>
        </FullwidthGrid>
      </Grid>

      <Divider />

      <Grid container justify="center">
        <Grid item sm={12}>
          <Typography align="center">
            Need to create an account?{" "}
            <RouterLink to={routes.AUTH.REGISTER} blue>
              Register here
            </RouterLink>
          </Typography>
        </Grid>
      </Grid>
    </FormHolder>
  )
}

export default LoginForm
