import Typography from "@material-ui/core/Typography"
import React, { useState } from "react"
import { useCountUp } from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

interface Props {
  end: number
}

const StatisticsNumber = ({ end }: Props) => {
  const [hasRun, setHasRun] = useState(false)
  const { countUp, start } = useCountUp({
    end,
    startOnMount: false,
    duration: 0.8,
    separator: ",",
  })

  const onVisibilityChange = (isVisible: boolean) => {
    if (hasRun) {
      return
    }
    if (isVisible) {
      start()
      setHasRun(true)
    }
  }

  return (
    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
      <Typography variant="h2" color="primary">
        <b>{countUp}</b>
      </Typography>
    </VisibilitySensor>
  )
}

export default StatisticsNumber
