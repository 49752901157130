import Grid from "@material-ui/core/Grid"
import React from "react"
import { useForm } from "react-hook-form"

import LoadingButton from "../../auth/components/LoadingButton"
import PasswordField from "../../input/components/v2/auth/PasswordField"
import { ErrorMessages } from "../api/resetPassword"

interface Props {
  onSubmit: (state: State, reset: () => void) => void
  requestErrors?: ErrorMessages
  requestLoading: boolean
}

export interface State {
  newPassword: string
  confirmNewPassword: string
}

const ChangePasswordForm = ({
  onSubmit,
  requestErrors,
  requestLoading,
}: Props) => {
  const { handleSubmit, control, errors, watch, reset } = useForm<State>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
  })

  const trueReset = () => reset({ newPassword: "", confirmNewPassword: "" })

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data, trueReset))}>
      <Grid container>
        <Grid item sm={12}>
          <PasswordField
            showPhrase
            control={control}
            errors={errors}
            name="newPassword"
            autoComplete="new-password"
            label="New password"
            error={Boolean(requestErrors?.newError)}
            helperText={requestErrors?.newError}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item sm={12}>
          <PasswordField
            control={control}
            errors={errors}
            name="confirmNewPassword"
            autoComplete="new-password"
            label="Confirm new password"
            rules={{
              required: true,
              validate: (data: any) =>
                data === watch("newPassword") || "Passwords do not match",
            }}
            error={Boolean(requestErrors?.confirmError)}
            helperText={requestErrors?.confirmError}
          />
        </Grid>
        <Grid item sm={12}>
          <LoadingButton
            type="submit"
            isLoading={requestLoading}
            variant="contained"
            color="primary"
          >
            Reset Password
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default ChangePasswordForm
