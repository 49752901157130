import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"
import { ChildDataProps } from "react-apollo"

import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import {
  AllBlogPosts,
  AllBlogPosts_blogPosts,
} from "../queries/types/AllBlogPosts"
import BlogListItem from "./BlogListItem"

export type PassthroughProps = ChildDataProps<unknown, AllBlogPosts>

class BlogList extends Component<PassthroughProps> {
  renderList(posts: AllBlogPosts_blogPosts[]) {
    if (!posts.length) {
      return <Typography>There are no blog posts</Typography>
    }
    return (
      <List>
        {posts.map((post, index) => {
          const isLastItem = index + 1 === posts.length
          return (
            <React.Fragment key={index}>
              <BlogListItem blogPost={post} />
              {!isLastItem && <Divider variant="inset" component="li" />}
            </React.Fragment>
          )
        })}
      </List>
    )
  }

  render() {
    const {
      data: { blogPosts, loading },
    } = this.props
    return (
      <div className="container full-width">
        <TitledWrapper title="Blog" showBanner gapTop>
          <Card>
            <CardContent>
              <LoadingWrapper isLoading={loading && !blogPosts}>
                {blogPosts ? (
                  this.renderList(blogPosts)
                ) : (
                  <Typography>Unable to load blog posts</Typography>
                )}
              </LoadingWrapper>
            </CardContent>
          </Card>
        </TitledWrapper>
      </div>
    )
  }
}

export default BlogList
