import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import React from "react"

import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import RouterLink from "../../../helpers/components/RouterLink"
import { routes } from "../../../routes/constants/routes"

interface Props {
  loading: boolean
}

const UnsubscribeBody = ({ loading }: Props) => {
  if (loading) {
    return (
      <div>
        <Typography variant="h6">Unsubscribing...</Typography>
        <LoadingWrapper isLoading />
      </div>
    )
  }
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        You&apos;ve unsubscribed from email communications
      </Typography>
      <Typography gutterBottom style={{ marginBottom: 16 }}>
        You will no longer receive non-essential communications at your
        configured email address. For security purposes, you will still receive
        emails regarding changes to your account and password reset requests. If
        you wish to resubscribe, you may do so by changing your preferences on
        the &quot;My Profile&quot; page.
      </Typography>
      <RouterLink to={routes.AUTH.PROFILE}>
        <Button variant="contained" color="primary">
          Continue to My Profile
        </Button>
      </RouterLink>
    </div>
  )
}

export default UnsubscribeBody
