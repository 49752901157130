import React from "react"
import { SortableHandle } from "react-sortable-hoc"
import FontAwesome from "./FontAwesome"

const DraggableHandle = SortableHandle(() => (
  <FontAwesome
    icon="fas fa-arrows-alt-v"
    gapRight
    style={{ cursor: "row-resize" }}
  />
))

export default DraggableHandle
