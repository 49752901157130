import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

interface Props {
  name: string
  quote: string
  active: boolean
}

const TestimonialText = ({ name, quote, active }: Props) => {
  return (
    <TextWrapper active={active}>
      <Quote>“</Quote>
      <Typography className="typography" component="div">
        {quote}
      </Typography>
      <Typography variant="caption">- {name}</Typography>
    </TextWrapper>
  )
}

interface TextWrapperProps {
  active: boolean
}
const TextWrapper = styled.div`
  opacity: ${({ active }: TextWrapperProps) => (active ? "1" : "0.25")};
  transition: opacity 0.25s;
  width: 300px;
  display: inline-block;
  position: relative;
  margin-left: 32px;
  .typography {
    color: #fff !important;
  }
`
const Quote = styled.div`
  position: absolute;
  top: -16px;
  left: -32px;
  margin: 0;
  font-size: 72px;
  font-family: "Quattrocento", sans-serif;
`
export default TestimonialText
