import React from "react"

import { phoneNumberValidate } from "../../../constants/validationPatterns"
import { Rules } from "../core/ControlledProps"
import ControlledTextField, {
  ControlledTextFieldProps,
} from "../core/ControlledTextField"

type Props = Omit<ControlledTextFieldProps, "type">

const formatNumber = (currentValue: string) => {
  const cvLength = currentValue.length

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) {
    return currentValue
  }

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
  }

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )}-${currentValue.slice(6, 10)}`
}

const PhoneNumberField = ({
  name,
  control,
  rules: propRules,
  ...rest
}: Props) => {
  const onControlChange = ([event]: [React.ChangeEvent<HTMLInputElement>]) => {
    const { value } = event.target
    if (!value) {
      return value
    }

    const previousValue = control.getValues()[name]

    // Adding to the end
    if (!previousValue || previousValue.length < value.length) {
      const currentValue = value.replace(/[^\d]/g, "")
      return formatNumber(currentValue)
    } else if (
      // Deleting a character from the end
      previousValue.length === value.length + 1 &&
      previousValue.includes(value)
    ) {
      const currentValue = value.replace(/[^\d]/g, "")
      return formatNumber(currentValue)
    }
    return value
  }
  const rules: Rules = {
    ...propRules,
    validate: phoneNumberValidate,
  }
  return (
    <ControlledTextField
      label="Phone Number"
      name={name}
      control={control}
      onControlChange={onControlChange}
      rules={rules}
      autoComplete="tel-national"
      {...rest}
    />
  )
}

export default PhoneNumberField
