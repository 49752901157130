import axios, { AxiosError, AxiosResponse } from "axios"

import { reportError } from "../../errors/api/reportError"

const BASE_URL = "/api"

const doSend = (method: "GET" | "DELETE" | "POST" | "PUT" | "PATCH") => async <
  T
>(
  destination: string,
  data?: any,
  headers: any = {},
  baseUrl = true,
  onUploadProgress?: (progressEvent: ProgressEvent) => void
): Promise<T> => {
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...headers,
  }

  const url = `${baseUrl ? BASE_URL : ""}${destination}`

  return axios({
    url,
    method,
    data,
    headers,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    onUploadProgress,
  })
    .then(handleResponse)
    .catch((err) => handleError(err, url, method))
}

const doGet = doSend("GET")
const doDelete = doSend("DELETE")
const doPost = doSend("POST")
const doPut = doSend("PUT")
const doPatch = doSend("PATCH")

const handleResponse = async <T>(response: AxiosResponse<T>): Promise<T> =>
  response.data

const handleError = (error: AxiosError, url: string, method: string) => {
  reportError(error, {
    name: error.name,
    message: error.message,
    request: error.request,
    response: error.response,
    url,
    method,
  })
  if (error.response) {
    const code = error.response.status
    const message = error.response.data || error.message
    // eslint-disable-next-line
    throw { code, message } as ApiError
  }
  throw error
}

export const api = {
  get: doGet,
  post: doPost,
  put: doPut,
  patch: doPatch,
  delete: doDelete,
}
