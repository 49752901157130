import { green } from "@material-ui/core/colors"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import CloseIcon from "@material-ui/icons/Close"
import React, { Component } from "react"

import NotificationIcon from "./NotificationIcon"

interface Props extends WithStyles {
  messages: string[]
  errorState: boolean
  onClose: () => void
}

class NotificationSnackbar extends Component<Props> {
  onClose = () => {
    const { messages, onClose } = this.props
    if (messages.length) {
      onClose()
    }
  }

  renderMessage() {
    const {
      messages,
      errorState,
      classes: { notificationMessage },
    } = this.props
    return (
      <span className={notificationMessage}>
        <NotificationIcon count={messages.length} errorState={errorState} />
        {messages[0]}
      </span>
    )
  }

  renderAction() {
    const { onClose } = this.props
    return (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    )
  }

  render() {
    const {
      messages,
      errorState,
      classes: {
        anchorOriginBottomLeft,
        redBackground,
        greenBackground,
        snackbarAction,
        snackbarMessage,
      },
    } = this.props
    return (
      <Snackbar
        open={!!messages.length}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={8000}
        onClose={this.onClose}
        classes={{
          anchorOriginBottomLeft,
        }}
      >
        <SnackbarContent
          message={this.renderMessage()}
          action={this.renderAction()}
          classes={{
            root: errorState ? redBackground : greenBackground,
            message: snackbarMessage,
            action: snackbarAction,
          }}
        />
      </Snackbar>
    )
  }
}

export default withStyles((theme) => ({
  redBackground: {
    backgroundColor: theme.palette.error.dark,
  },
  greenBackground: {
    backgroundColor: green[600],
  },
  anchorOriginBottomLeft: {
    marginRight: theme.spacing(3),
  },
  notificationMessage: {
    display: "flex",
    alignItems: "center",
  },
  snackbarMessage: {
    flex: 1,
  },
  snackbarAction: {
    flex: 0,
  },
}))(NotificationSnackbar)
