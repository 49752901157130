import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import React from "react"
import { useQuery } from "react-apollo"

import ContactCTA from "../../../footer/components/ContactCTA"
import Divider from "../../../helpers/components/Divider"
import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import PageError from "../../../helpers/components/PageError"
import TitledWrapper from "../../../sidebar/components/TitledWrapper"
import loadFaqPage from "../queries/loadFaqPage"
import { LoadFAQPage } from "../queries/types/LoadFAQPage"
import FAQGroup from "./FAQGroup"
import FAQTitle from "./FAQTitle"

const FAQWrapper = () => {
  const { data, loading, refetch } = useQuery<LoadFAQPage>(loadFaqPage, {})

  return (
    <div className="container">
      <TitledWrapper title="Frequently Asked Questions" gapTop showBanner>
        <Card elevation={1}>
          <CardContent>
            <LoadingWrapper isLoading={loading}>
              {data?.faqPage ? (
                <div>
                  <FAQTitle
                    title={data.faqPage.title}
                    body={data.faqPage.body}
                  />
                  {data.faqPage.groups.map((group, index) => (
                    <div key={index}>
                      <Divider />
                      <FAQGroup name={group.name} content={group.content} />
                    </div>
                  ))}
                </div>
              ) : (
                <PageError refetch={refetch} />
              )}
            </LoadingWrapper>

            <Divider />

            <ContactCTA />
          </CardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

export default FAQWrapper
