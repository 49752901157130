import moment from "moment"

export const buildEmptyUser = (): User => ({
  id: undefined as any,
  email: "",
  firstName: "",
  lastName: "",
  isStaff: false,
  address: buildEmptyAddress(),
  phone: "",
  dob: "",
  licenseNumber: "",
  ssn: "",
  referralEmail: "",
})

export const buildEmptyAddress = (): Address => ({
  id: undefined as any,
  street: "",
  city: "",
  state: "",
  zipCode: "",
})

export const convertToUser = ({
  id,
  email,
  first_name,
  last_name,
  is_staff,
  phone,
  dob,
  license_number,
  ssn,
  referral_email,
  address,
  impersonating,
}: BE_User): User => {
  return {
    id,
    email,
    firstName: first_name || "",
    lastName: last_name || "",
    isStaff: is_staff,
    phone: phone || "",
    dob,
    licenseNumber: license_number || "",
    ssn: ssn || "",
    referralEmail: referral_email || "",
    address: address ? convertToAddress(address) : buildEmptyAddress(),
    impersonating,
  }
}

export const convertUserToBE = ({
  id,
  email,
  firstName,
  lastName,
  phone,
  dob,
  licenseNumber,
  ssn,
  referralEmail,
  address,
}: User): BE_User => {
  return {
    id,
    email,
    first_name: firstName,
    last_name: lastName,
    is_staff: undefined as any,
    phone,
    dob: dob ? moment(dob).format("YYYY-MM-DD") : dob,
    license_number: licenseNumber,
    ssn,
    referral_email: referralEmail,
    address: convertAddressToBE(address),
  }
}

export const convertToAddress = ({
  id,
  street,
  city,
  state,
  zip_code,
}: BE_Address): Address => ({
  id,
  street: street || "",
  city: city || "",
  state: state || "",
  zipCode: zip_code || "",
})

export const convertAddressToBE = ({
  id,
  street,
  city,
  state,
  zipCode,
}: Address): BE_Address => ({
  id,
  street,
  city,
  state,
  zip_code: String(zipCode),
})
