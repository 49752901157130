import React, { PureComponent } from "react"
import styled from "styled-components"

import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"
import { getTransition } from "../operators/getTransition"

interface Props {
  extended: boolean
  absolute: boolean
}

class LogoHolder extends PureComponent<Props> {
  render() {
    const { extended, absolute } = this.props
    return (
      <Wrapper extended={extended} absolute={absolute}>
        <LogoWrapper>
          <RouterLink to={routes.HOME} underline="none">
            <ImageWrapper
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="logo"
              extended={extended}
            />
          </RouterLink>
          <ArrowDown extended={extended} />
          <ArrowDownInternal extended={extended} />
        </LogoWrapper>
      </Wrapper>
    )
  }
}

const PADDING_LEFT = 30

const Wrapper = styled.div`
  background: #fff;
  padding: ${({ extended }: Props) =>
    extended ? `${PADDING_LEFT}px` : "5px 30px"};
  position: ${({ absolute }: Props) => (absolute ? "absolute" : "relative")};
  top: 0;
  height: ${({ extended }: Props) => (extended ? "auto" : "80px")};
  width: ${({ extended }: Props) => (extended ? "300px" : "160px")};
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  transition: ${getTransition("0.25s all")};
`
const LogoWrapper = styled.div`
  position: relative;
  text-align: center;
`
interface ExtendedProps {
  extended: boolean
}
const ImageWrapper = styled.img`
  height: ${({ extended }: ExtendedProps) => (extended ? "100px" : "60px")};
  transition: ${getTransition("0.25s all")};
`
const ArrowDown = styled.div`
  width: 0;
  height: 0;
  left: -${PADDING_LEFT}px;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-top: ${({ extended }: ExtendedProps) =>
    extended ? "40px solid #dfdfdf" : "0px"};
  position: absolute;
  top: ${({ extended }: ExtendedProps) =>
    extended ? "calc(100% + 31px)" : "100%"};
  transition: ${getTransition("0.25s all")};
`
const ArrowDownInternal = styled(ArrowDown)`
  top: calc(100% + 30px) !important;
  border-top-color: #fff !important;
`

export default LogoHolder
