import { createSelector } from "reselect"

import { isCurrentUser } from "./currentUserGetters"
import { getAuthState } from "./getAuthState"

const heartbeatSuccessful = createSelector(getAuthState, (authState) => {
  return authState.heartbeatLoggedIn
})

export const isHeartbeatExpired = createSelector(
  [isCurrentUser, heartbeatSuccessful],
  (isLoggedIn, heartbeatSuccessful) => {
    return isLoggedIn && !heartbeatSuccessful
  }
)

export const shouldPollHeartbeat = createSelector(
  [isCurrentUser, heartbeatSuccessful],
  (isLoggedIn, heartbeatSuccessful) => {
    return isLoggedIn && heartbeatSuccessful
  }
)
