import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import React from "react"
import { ChildDataProps } from "react-apollo"

import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import PageError from "../../helpers/components/PageError"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import { TransactionStatus } from "../../types/Global-Types"
import { MyTransactions } from "../queries/types/MyTransactions"
import MyPurchasesList from "./list/MyPurchasesList"

export interface Props extends ChildDataProps<unknown, MyTransactions> {
  setPaymentId: (referenceId: string, status: TransactionStatus) => void
}

const MyPurchases = ({ data, setPaymentId }: Props) => {
  const hasTransactions = (data.myTransactions?.length || 0) > 0
  return (
    <div>
      <LoadingWrapper isLoading={data.loading && !hasTransactions}>
        <TitledWrapper title="My Purchases">
          <Card elevation={1}>
            <CardContent>
              {data.myTransactions ? (
                <MyPurchasesList
                  transactions={data.myTransactions}
                  setPaymentId={setPaymentId}
                />
              ) : (
                <PageError refetch={data.refetch} />
              )}
            </CardContent>
          </Card>
        </TitledWrapper>
      </LoadingWrapper>
    </div>
  )
}

export default MyPurchases
