import { Middleware } from "redux"

import { routes } from "../../../routes/constants/routes"
import { history } from "../../../store"
import {
  ADD_COURSE,
  REMOVE_ACTIVE_COURSE,
  SET_ACTIVE_COURSE,
} from "../constants/ActionTypes"

export const handleCourseRedirects: Middleware = () => (next) => (action) => {
  const { type, payload } = action
  switch (type) {
    case SET_ACTIVE_COURSE:
      if (payload) {
        const route = routes.ADMIN_NAV.COURSES.EDITOR_ACTIVE.replace(
          ":id",
          payload
        )
        history.push(route)
        return
      }
      history.push(routes.ADMIN_NAV.COURSES.EDITOR)
      break
    case ADD_COURSE:
      history.push(routes.ADMIN_NAV.COURSES.EDITOR)
      break
    case REMOVE_ACTIVE_COURSE:
      history.push(routes.ADMIN_NAV.COURSES.LIST)
      break
  }
  return next(action)
}
