/* eslint-disable */
// @ts-nocheck
// TODO: Fix nocheck
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import Autocomplete, { AutocompleteProps } from "@material-ui/lab/Autocomplete"
import { UseAutocompleteProps } from "@material-ui/lab/useAutocomplete"
import React from "react"
import { Controller } from "react-hook-form"
import styled from "styled-components"

import {
  getErrorFromName,
  getErrorMessageFromFieldError,
} from "../../../operators/errorGetters"
import { ControlledProps } from "./ControlledProps"

type SelectProps<T> = Omit<
  AutocompleteProps<T, boolean, boolean, boolean>,
  "renderInput"
> &
  UseAutocompleteProps<T, boolean, boolean, boolean>

export type ControlledSelectProps<T> = ControlledProps<
  Omit<SelectProps<T> & TextFieldProps, "onChange"> & {
    textAutoComplete?: string
    onChange?: (value: T) => void
  }
>

const ControlledSelect: <T extends string | number>(
  p: ControlledSelectProps<T>
) => React.ReactElement<ControlledSelectProps<T>> = ({
  name,
  control,
  errors,
  rules,
  helperText,
  error,
  value: propValue,
  textAutoComplete,
  onChange: onPropChange,
  options,
  ...rest
}: SelectProps<T>) => {
  const fieldError = getErrorFromName(name, errors)
  const errorMessage = getErrorMessageFromFieldError(fieldError)
  return (
    <Controller
      render={({ onChange, onBlur, value }) => (
        <StyledAutoComplete
          {...rest}
          onBlur={onBlur}
          value={value}
          onChange={(_: React.ChangeEvent<HTMLInputElement>, newValue: any) => {
            if (onPropChange) {
              onPropChange(newValue)
            }
            onChange(newValue)
          }}
          options={options}
          renderInput={(params) => (
            <TextField
              label={rest.label}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: textAutoComplete,
              }}
              autoComplete={textAutoComplete}
              required={rules?.required}
              helperText={errorMessage || helperText}
              error={Boolean(errorMessage) || error}
            />
          )}
        />
      )}
      name={name}
      control={control}
      defaultValue={propValue}
      rules={rules}
    />
  )
}

const StyledAutoComplete = styled(Autocomplete)`
  > div > div {
    min-height: 46px;
  }
`

export default ControlledSelect
