import { green } from "@material-ui/core/colors"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"

import FontAwesome from "../../helpers/components/FontAwesome"
import StyledHTML from "../../helpers/components/StyledHTML"
import { getUserAnswer } from "../operators/getUserAnswer"
import { Answer, Question } from "../types/Question"
import FillInTheBlankQuestion from "./FillInTheBlankQuestion"
import MultipleChoiceQuestion from "./MultipleChoiceQuestion"
import TrueFalseQuestion from "./TrueFalseQuestion"

interface Props extends WithStyles {
  index: number
  disabled: boolean
  question: Question
  answer?: Answer
  onAnswerChange: (answer: string) => void
}

class QuestionTaker extends Component<Props> {
  renderQuestionBody() {
    const {
      question,
      index,
      answer,
      classes: { correct: correctClass, incorrect, questionBody },
    } = this.props
    const correct = this.isAnswerCorrect()
    const icon = answer && (
      <FontAwesome
        className={correct ? correctClass : incorrect}
        icon={`fas ${correct ? "fa-check" : "fa-times"}`}
      />
    )
    return (
      <div className={questionBody}>
        <Typography>
          {`${index + 1}.`}
          {icon}
        </Typography>
        <StyledHTML html={question.description} />
      </div>
    )
  }

  renderQuestionInput() {
    const {
      question: { questionType, content },
      answer,
      disabled,
      onAnswerChange,
    } = this.props
    const submissionAnswer = answer?.answer
    switch (questionType) {
      case "TRUE_FALSE":
        return (
          <TrueFalseQuestion
            showAnswer={disabled}
            submissionAnswer={submissionAnswer}
            onAnswerChange={onAnswerChange}
          />
        )
      case "MULTIPLE_CHOICE":
        return (
          <MultipleChoiceQuestion
            content={content}
            showAnswer={disabled}
            submissionAnswer={submissionAnswer}
            onAnswerChange={onAnswerChange}
          />
        )
      case "FILL_IN_BLANK":
        return (
          <FillInTheBlankQuestion
            content={content}
            showAnswer={disabled}
            submissionAnswer={submissionAnswer}
            onAnswerChange={onAnswerChange}
          />
        )
      default:
        return null
    }
  }

  renderCorrectSnackbar() {
    if (!this.props.answer || !this.props.answer.correct) {
      return null
    }
    const {
      classes: { correctSnackbar },
    } = this.props
    return (
      <SnackbarContent
        className={correctSnackbar}
        message="This question was answered correctly"
      />
    )
  }

  renderIncorrectSnackbar() {
    if (this.isAnswerCorrect()) {
      return null
    }
    const {
      question,
      answer,
      classes: { incorrectSnackbar },
    } = this.props
    const submissionAnswer = getUserAnswer(question, answer)
    const message = (
      <span>
        The following answer is incorrect: <b>{submissionAnswer}</b>
      </span>
    )
    return <SnackbarContent className={incorrectSnackbar} message={message} />
  }

  render() {
    return (
      <div>
        {this.renderQuestionBody()}
        {this.renderQuestionInput()}
        {this.renderCorrectSnackbar()}
        {this.renderIncorrectSnackbar()}
      </div>
    )
  }

  private isAnswerCorrect() {
    return Boolean(!this.props.answer || this.props.answer.correct)
  }
}

export default withStyles((theme) => ({
  correct: {
    marginLeft: 8,
    color: green[700],
  },
  incorrect: {
    marginLeft: 8,
    color: theme.palette.secondary.main,
  },
  correctSnackbar: {
    backgroundColor: green[700],
  },
  incorrectSnackbar: {
    backgroundColor: theme.palette.secondary.dark,
  },
  questionBody: {
    marginBottom: 8,
  },
}))(QuestionTaker)
