import { amber } from "@material-ui/core/colors"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import React from "react"

interface Props extends WithStyles {
  children: React.ReactNode
  shouldShow: boolean
}

const AmberAlert = ({
  children,
  shouldShow,
  classes: { root, flex },
}: Props) => {
  if (!shouldShow) {
    return null
  }
  return (
    <div className={root}>
      <div className={flex}>{children}</div>
    </div>
  )
}
const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    height: 40,
    backgroundColor: amber[700],
    boxShadow: "inset 0px 0px 5px 0px rgba(0,0,0,0.15)",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}

export default withStyles(styles)(AmberAlert)
