import { gql } from "apollo-boost"

export default gql`
  query GetMyCourses {
    myStudentCourses {
      id
      status
      tag
      dateCompleted
      course {
        id
        title
        courseType
        hours
        lessons {
          id
          order
        }
      }
      activeLesson {
        id
      }
    }
  }
`
