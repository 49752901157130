import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"

import StyledHTML from "../../../helpers/components/StyledHTML"
import { CurrentLicenseRequirements_cycleLicenseRequirements } from "../queries/types/CurrentLicenseRequirements"

interface Props {
  body: string
  requirements: CurrentLicenseRequirements_cycleLicenseRequirements
}

const replaceAll = (delimiter: string, value: string) => (body: string) => {
  let formattedBody = body
  while (formattedBody.includes(delimiter)) {
    formattedBody = formattedBody.replace(delimiter, value)
  }
  return formattedBody
}

const LicenseTypography = ({
  body,
  requirements: {
    cycleName,
    startDate,
    endDate,
    totalHoursRequirement,
    yearlyHoursRequirement,
  },
}: Props) => {
  const numberYears = Math.max(
    Math.ceil(moment(endDate).diff(startDate, "years", true)),
    1
  )
  const formatters = [
    replaceAll("{{ cycleName }}", cycleName),
    replaceAll("{{ startDate }}", moment(startDate).format("MMMM D, YYYY")),
    replaceAll("{{ endDate }}", moment(endDate).format("MMMM D, YYYY")),
    replaceAll("{{ numberYears }}", String(numberYears)),
    replaceAll("{{ totalHoursRequirement }}", String(totalHoursRequirement)),
    replaceAll("{{ yearlyHoursRequirement }}", String(yearlyHoursRequirement)),
  ]
  let formattedBody = body
  formatters.forEach((callbackfn) => {
    formattedBody = callbackfn(formattedBody)
  })

  return (
    <Typography component="div">
      <StyledHTML html={formattedBody} />
    </Typography>
  )
}

export default LicenseTypography
