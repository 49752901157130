import { QuestionQuestionType } from "../../types/Global-Types"
import { Answer, Question } from "../types/Question"
import { FillInTheBlankSerializer } from "./FillInTheBlankSerializer"

export const getUserAnswer = (question: Question, answer?: Answer): string => {
  if (!answer) {
    return ""
  }
  switch (question.questionType) {
    case QuestionQuestionType.TRUE_FALSE:
    case QuestionQuestionType.MULTIPLE_CHOICE:
      return answer.answer
    case QuestionQuestionType.FILL_IN_BLANK:
      const userAnswers = FillInTheBlankSerializer.deserialize(answer.answer)
      if (!Array.isArray(userAnswers)) {
        return ""
      }
      return userAnswers.join(", ")
    default:
      return ""
  }
}
