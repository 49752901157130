import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import React, { PureComponent } from "react"
import { Query } from "react-apollo"
import styled from "styled-components"

import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import PaddedGrid from "../../../helpers/components/PaddedGrid"
import RouterLink from "../../../helpers/components/RouterLink"
import { routes } from "../../../routes/constants/routes"
import getLatestCourses from "../../queries/getLatestCourses"
import { GetLatestCourses } from "../../queries/types/GetLatestCourses"
import CourseCard from "./CourseCard"

type Props = unknown

class LatestCoursesWrapper extends PureComponent<Props> {
  render() {
    return (
      <div className="wrapper">
        <Typography variant="h3" className="underline" color="primary">
          <b>Latest Courses</b>
        </Typography>
        <PaddedGrid>
          <Query<GetLatestCourses, unknown> query={getLatestCourses}>
            {({ data, loading }) => (
              <LoadingWrapper isLoading={loading}>
                {data?.availableShopCourses ? (
                  data.availableShopCourses.map((shopCourse) => {
                    return (
                      <CourseCard
                        key={shopCourse.id}
                        shopCourseId={shopCourse.id}
                        course={shopCourse.course}
                      />
                    )
                  })
                ) : (
                  <Typography>Unable to load recent courses</Typography>
                )}
              </LoadingWrapper>
            )}
          </Query>
        </PaddedGrid>
        <RouterLink to={routes.SHOP.COURSE_LIST}>
          <ViewAllButton color="primary" variant="contained" size="large">
            View All
          </ViewAllButton>
        </RouterLink>
      </div>
    )
  }
}

const ViewAllButton = styled(Button)`
  width: 140px;
  margin: 50px 0;
  padding: 12px;
`

export default LatestCoursesWrapper
