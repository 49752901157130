import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { BOTTOM_TOOLBAR, TOP_TOOLBAR } from "../../../navbar/components/Navbar"
import ScrollEventBus from "../../../scroll/ScrollEventBus"

interface Props {
  children: JSX.Element
}

const PADDING = 24

const FixedTopElement = ({ children }: Props) => {
  const [startingHeight, setStartingHeight] = useState(0)
  const [top, setTop] = useState(0)
  const divRef = useRef<HTMLDivElement>(null)

  const getElementTop = () =>
    divRef.current?.getBoundingClientRect()?.top || undefined
  const getElementHeight = () => {
    const bounding = divRef.current?.getBoundingClientRect()
    if (!bounding) {
      return 0
    }
    return bounding.height
  }
  const getContainerHeight = () => {
    const bounding = divRef.current?.parentElement?.parentElement?.getBoundingClientRect()
    if (!bounding) {
      return 0
    }
    return bounding.height
  }

  const onScroll = (scrollTop?: number) => {
    if (window.innerWidth <= 960) {
      return
    }

    if (scrollTop !== undefined) {
      const trueScrollTop = scrollTop + TOP_TOOLBAR + BOTTOM_TOOLBAR
      const topDiff = trueScrollTop - startingHeight + PADDING
      if (topDiff > 0) {
        const elementHeight = getElementHeight()
        const containerHeight = getContainerHeight()
        if (topDiff + elementHeight + PADDING >= containerHeight) {
          setTop(containerHeight - elementHeight - PADDING)
        } else {
          setTop(topDiff)
        }
      } else if (top !== 0) {
        setTop(0)
      }
    }
  }

  const onLoad = () => {
    const elementTop = getElementTop()

    if (elementTop !== undefined && startingHeight < elementTop) {
      setStartingHeight(elementTop)
    }
  }

  // Once all page content has loaded, the layout stops shifting and we grab top
  useEffect(() => {
    window.addEventListener("load", onLoad)
    return () => window.removeEventListener("load", onLoad)
  })

  useEffect(() => {
    ScrollEventBus.subscribe(onScroll)
    return () => ScrollEventBus.unsubscribe(onScroll)
  })

  // Falls back to this effect when the content has already loaded
  useEffect(() => {
    const elementTop = getElementTop()
    if (elementTop !== undefined && elementTop > startingHeight) {
      setStartingHeight(elementTop)
    }
  }, [startingHeight, setStartingHeight])

  return (
    <Wrapper>
      <div
        className="scrolling-item"
        ref={divRef}
        style={{ transform: `translateY(${top}px)` }}
      >
        {children}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media only screen and (max-width: 960px) {
    .scrolling-item {
      transform: none !important;
    }
  }
`

export default FixedTopElement
