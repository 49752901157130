import * as Sentry from "@sentry/browser"
import { ExtraErrorData } from "@sentry/integrations"
import { createBrowserHistory } from "history"
import { applyMiddleware, combineReducers, createStore, Reducer } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"

import { reportsReducer, ReportState } from "../admin/reports/reducers"
import { authReducer, AuthState } from "../auth/reducers"
import { checkoutReducer, CheckoutState } from "../checkout/reducers"
import { sentryMiddleware } from "../errors/middleware/sentryMiddleware"
import { subscribeToErrors } from "../graphql/operators/subscribeToErrors"
import { isDevelopment } from "../helpers/operators/isDevelopment"
import {
  notificationReducer,
  NotificationState,
} from "../notifications/reducers"
import appMiddlewares from "./middleware"

export interface State {
  auth: AuthState
  checkout: CheckoutState
  notifications: NotificationState
  reports: ReportState
}

const getRelease = () => {
  return `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
}

const filterEvent = (event: Sentry.Event) => {
  if (event.message?.includes && typeof event.message.includes === "function") {
    const { message } = event
    if (
      message.includes("SecurityError: The operation is insecure.") ||
      message.includes("Invalid or expired discount code provided")
    ) {
      return null
    }
  }

  return event
}

if (!isDevelopment()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: getRelease(),
    integrations: [new ExtraErrorData()],
    beforeSend: filterEvent,
  })
}

const rootReducer: Reducer<State> = combineReducers<State>({
  auth: authReducer,
  checkout: checkoutReducer,
  notifications: notificationReducer,
  reports: reportsReducer,
})

const composeEnhancers = composeWithDevTools
const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  appMiddlewares,
  applyMiddleware(sentryMiddleware)
)

export const history = createBrowserHistory({
  basename: "",
})

export const store = createStore(rootReducer, enhancer)

subscribeToErrors(store.dispatch)
