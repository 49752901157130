import Typography from "@material-ui/core/Typography"
import React from "react"
import { ChildDataProps } from "react-apollo"
import styled from "styled-components"

import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import { StudentCourseStatus } from "../../types/Global-Types"
import { FilterOptions } from "../operators/filterOptions"
import {
  GetMyCourses,
  GetMyCourses_myStudentCourses,
} from "../queries/types/GetMyCourses"
import CoursesSection from "./CoursesSection"
import Filter from "./Filter"

type StudentCourse = GetMyCourses_myStudentCourses

export interface Props extends ChildDataProps<unknown, GetMyCourses> {
  title: string
  navigateToCourse: (courseId: string) => void
}

interface State {
  filter?: FilterOptions
}

class MyCourses extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  renderZeroState() {
    return (
      <Typography variant="body1">
        You have no courses. If you&apos;d like to add one, you may{" "}
        <RouterLink to={routes.SHOP.COURSE_LIST} blue>
          click here
        </RouterLink>
        .
      </Typography>
    )
  }

  renderCourses(studentCourses: StudentCourse[]) {
    const { filter } = this.state
    if (filter) {
      studentCourses = studentCourses.filter(this.shouldFilter)
    }
    return (
      <>
        <CoursesSection
          title="In Progress"
          studentCourses={this.coursesByStatus(
            studentCourses,
            StudentCourseStatus.IN_PROGRESS
          )}
        />
        <CoursesSection
          title="Not Started"
          divider
          studentCourses={this.coursesByStatus(
            studentCourses,
            StudentCourseStatus.NOT_STARTED
          )}
        />
        <CoursesSection
          title="Finished"
          divider
          studentCourses={this.coursesByStatus(
            studentCourses,
            StudentCourseStatus.FINISHED
          ).sort(this.sortByDateCompleted)}
        />
        <CoursesSection
          title="Expired"
          divider
          studentCourses={this.coursesByStatus(
            studentCourses,
            StudentCourseStatus.EXPIRED
          )}
        />
      </>
    )
  }

  render() {
    const { data } = this.props
    const studentCourses: StudentCourse[] = data.myStudentCourses || []
    const hasCourses = Boolean(studentCourses.length)
    return (
      <Wrapper>
        <TitledWrapper
          title="My Courses"
          upperRightComponent={
            hasCourses ? <Filter onChange={this.setFilterOptions} /> : undefined
          }
        >
          <LoadingWrapper isLoading={data.loading && !hasCourses}>
            {hasCourses
              ? this.renderCourses(studentCourses)
              : this.renderZeroState()}
          </LoadingWrapper>
        </TitledWrapper>
      </Wrapper>
    )
  }

  private readonly setFilterOptions = (filter: FilterOptions) => {
    this.setState({ filter })
  }

  private readonly shouldFilter = (studentCourse: StudentCourse) => {
    const { filter } = this.state
    if (!filter) {
      return false
    }
    if (!filter.law && studentCourse.course.courseType === "LAW") {
      return false
    }
    if (!filter.nonLaw && studentCourse.course.courseType === "NON_LAW") {
      return false
    }
    if (!filter.notStarted && studentCourse.status === "NOT_STARTED") {
      return false
    }
    if (!filter.inProgress && studentCourse.status === "IN_PROGRESS") {
      return false
    }
    if (!filter.completed && studentCourse.status === "FINISHED") {
      return false
    }
    return true
  }

  private readonly coursesByStatus = (
    studentCourses: StudentCourse[],
    status: StudentCourseStatus
  ) => {
    return studentCourses.filter(
      (studentCourse) => studentCourse.status === status
    )
  }

  /**
   * A sort function for returning student courses in date completed order,
   * where the most recent completion is first. Negative implies a comes first,
   * whereas positive implies b comes first.
   *
   * @param a first compare value
   * @param b second compare value
   */
  private readonly sortByDateCompleted = (
    a: StudentCourse,
    b: StudentCourse
  ): number => {
    return a.dateCompleted <= b.dateCompleted ? 1 : -1
  }
}

const Wrapper = styled.div`
  .titled-content-grid {
    display: flex;
    width: 100%;
    .titled-content {
      width: 100%;
    }
  }
`

export default MyCourses
