import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"
import { ChildDataProps } from "react-apollo"
import styled from "styled-components"

import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import RouterLink from "../../helpers/components/RouterLink"
import StyledHTML from "../../helpers/components/StyledHTML"
import { routes } from "../../routes/constants/routes"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import { BlogPost } from "../queries/types/BlogPost"

type Props = ChildDataProps<unknown, BlogPost>

const goBackButton = (
  <RouterLink to={routes.SECONDARY_NAV.BLOG}>
    <Button variant="outlined" color="primary">
      Go back to blog list
    </Button>
  </RouterLink>
)

const BlogPostImage = ({ image }: { image?: string | null }) => {
  if (!image) {
    return null
  }
  return (
    <ImageWrapper>
      <img src={image} alt={image} />
    </ImageWrapper>
  )
}

const BlogPostComponent = ({ data: { loading, blogPost } }: Props) => {
  const title = blogPost?.title || "Blog Post"
  const content = blogPost?.content || "This blog post failed to load"
  const author = blogPost?.author
    ? `${blogPost.author.firstName} ${blogPost.author.lastName}`
    : "Admin"
  const lastUpdated = moment(blogPost?.modified).format(
    "MMMM D YYYY, h:mm:ss a"
  )
  return (
    <div className="container full-width">
      <TitledWrapper
        title={title}
        showBanner
        gapTop
        upperRightComponent={goBackButton}
      >
        <Card>
          <StyledCardContent>
            <LoadingWrapper isLoading={loading && !blogPost}>
              <BlogPostImage image={blogPost?.headerImage?.file} />
              <Typography variant="h4" align="left" gutterBottom>
                {title}
              </Typography>
              <Typography variant="caption" align="left" component="p">
                By: {author}
              </Typography>
              <Typography
                variant="caption"
                align="left"
                component="p"
                gutterBottom
              >
                Last Modified: {lastUpdated}
              </Typography>
              <Typography align="justify" component="div">
                <StyledHTML html={content} />
              </Typography>
            </LoadingWrapper>
          </StyledCardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

const StyledCardContent = styled(CardContent)`
  overflow: hidden;
`
const ImageWrapper = styled.div`
  width: 100%;
  margin: -32px;
  margin-bottom: 24px;
  img {
    width: calc(100% + 64px);
  }
`

export default BlogPostComponent
