import React from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"

import ConfirmEmailContainer from "../../auth/containers/ConfirmEmailContainer"
import LoginFormContainer from "../../auth/containers/LoginFormContainer"
import PasswordResetConfirmContainer from "../../auth/containers/PasswordResetConfirmContainer"
import PasswordResetPromptContainer from "../../auth/containers/PasswordResetPromptContainer"
import RegisterFormContainer from "../../auth/containers/RegisterFormContainer"
import UnsubscribeContainer from "../../auth/containers/UnsubscribeContainer"
import BlogListContainer from "../../blog/containers/BlogListContainer"
import BlogPostContainer from "../../blog/containers/BlogPostContainer"
import ContactFormContainer from "../../contact/containers/ContactFormContainer"
import NotFoundPage from "../../helpers/components/NotFoundPage"
import HomePage from "../../home/components/HomePage"
import AsyncAdminRoutes from "../../loadable/AsyncAdminRoutes"
import AsyncShopRoutes from "../../loadable/AsyncShopRoutes"
import ScrollEventBus from "../../scroll/ScrollEventBus"
import AboutContainer from "../../static-pages/about/containers/AboutContainer"
import FAQContainer from "../../static-pages/faq/containers/FAQContainer"
import LicenseRequirementsContainer from "../../static-pages/license-requirements/containers/LicenseRequirementsContainer"
import PrivacyPolicyContainer from "../../static-pages/policy/containers/PrivacyPolicyContainer"
import UserAgreementContainer from "../../static-pages/policy/containers/UserAgreementContainer"
import AttributionContainer from "../../static-pages/resources/containers/AttributionContainer"
import { ADMIN_ROOT, APP, routes, SHOP_ROOT } from "../constants/routes"
import { AppRoutes } from "./AppRoutes"
import ProtectedRoute from "./ProtectedRoute"

interface Props {
  isLoggedIn: boolean
  user: User | null
}

class Routes extends React.Component<Props> {
  componentDidMount() {
    ScrollEventBus.componentDidMount()
  }

  componentWillUnmount() {
    ScrollEventBus.componentWillUnmount()
  }

  render() {
    const { isLoggedIn, user } = this.props
    const loggedInRedirectRoute = user?.isStaff
      ? routes.ADMIN_NAV.HOME
      : routes.APP.DASHBOARD
    return (
      <RoutesWrapper ref={ScrollEventBus.ref} className="scroll-container">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <ProtectedRoute
            exact
            isAllowed={!isLoggedIn}
            anonymous
            path={routes.AUTH.LOGIN}
            component={LoginFormContainer}
            redirectRoute={loggedInRedirectRoute}
          />
          <ProtectedRoute
            exact
            isAllowed={isLoggedIn}
            path={routes.AUTH.PROFILE}
            component={AppRoutes}
            redirectRoute={loggedInRedirectRoute}
          />
          <ProtectedRoute
            path={APP}
            isAllowed={isLoggedIn}
            component={AppRoutes}
            redirectRoute={loggedInRedirectRoute}
          />
          <ProtectedRoute
            path={ADMIN_ROOT}
            isAllowed={Boolean(user?.isStaff)}
            component={AsyncAdminRoutes}
            redirectRoute={loggedInRedirectRoute}
          />
          <Route
            exact
            anonymous
            path={routes.AUTH.REGISTER}
            component={RegisterFormContainer}
          />
          <Route
            exact
            path={routes.AUTH.RESET}
            component={PasswordResetPromptContainer}
          />
          <Route
            exact
            path={routes.AUTH.RESET_CONFIRM}
            component={PasswordResetConfirmContainer}
          />
          <Route
            exact
            path={routes.AUTH.CONFIRM_EMAIL}
            component={ConfirmEmailContainer}
          />
          <Route
            exact
            path={routes.AUTH.UNSUBSCRIBE}
            component={UnsubscribeContainer}
          />
          <Route
            exact
            path={routes.SECONDARY_NAV.REQUIREMENTS}
            component={LicenseRequirementsContainer}
          />
          <Route
            exact
            path={routes.SECONDARY_NAV.BLOG}
            component={BlogListContainer}
          />
          <Route
            exact
            path={routes.SECONDARY_NAV.BLOG_POST}
            component={BlogPostContainer}
          />
          <Route path={routes.SECONDARY_NAV.ABOUT} component={AboutContainer} />
          <Route path={routes.SECONDARY_NAV.FAQ} component={FAQContainer} />
          <Route
            path={routes.SECONDARY_NAV.POLICIES}
            component={PrivacyPolicyContainer}
          />
          <Route
            path={routes.SECONDARY_NAV.USER_AGREEMENT}
            component={UserAgreementContainer}
          />
          <Route
            path={routes.SECONDARY_NAV.ATTRIBUTION}
            component={AttributionContainer}
          />
          <Route
            path={routes.SECONDARY_NAV.CONTACT_US}
            component={ContactFormContainer}
          />
          <Route path={SHOP_ROOT} component={AsyncShopRoutes} />
          <Route component={NotFoundPage} />
        </Switch>
        {this.props.children}
      </RoutesWrapper>
    )
  }
}

const RoutesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
`

export default Routes
