import { Action, createAction } from "redux-actions"
import { ThunkAction, ThunkDispatch } from "redux-thunk"

import { State } from "../../store"
import { AsyncAction } from "../reducers"

interface Meta {
  success?: any
  error?: any
}

interface AsyncOptions {
  successMetaCreator: () => Record<string, unknown>
}

export const createAsyncAction = <T, A extends any[] = []>(
  asyncActionTypes: AsyncAction,
  actionPromise: (...args: A) => Promise<T>,
  options?: AsyncOptions
) => {
  return (
    ...args: A
  ): ThunkAction<Promise<Action<T>>, State, undefined, any> => async (
    dispatch: ThunkDispatch<State, undefined, any>
  ) => {
    dispatch(createAction(`${asyncActionTypes.PENDING}`)(...args))
    try {
      const result = await actionPromise(...args)
      const actionType = String(asyncActionTypes.SUCCEEDED)
      if (options?.successMetaCreator) {
        return dispatch(
          createAction<T, Meta>(
            actionType,
            (payload) => payload,
            () => ({ success: options.successMetaCreator() })
          )(result)
        )
      }
      return dispatch(createAction<T>(actionType)(result))
    } catch (err) {
      console.error(err)
      return dispatch(
        (createAction<A, Meta>(
          `${asyncActionTypes.FAILED}`,
          (payload) => payload,
          () => ({ error: err })
        )(...args) as any) as Action<T>
      )
    }
  }
}
