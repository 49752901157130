import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React from "react"

import withPageTitle from "../../routes/containers/withPageTitle"
import TitledWrapper from "../../sidebar/components/TitledWrapper"

interface Props {
  showBanner?: boolean
}

const NotFoundPage = ({ showBanner }: Props) => {
  return (
    <div className="container">
      <TitledWrapper
        title="404 Not Found"
        gapTop
        showBanner={showBanner !== false}
      >
        <Card elevation={1}>
          <CardContent>
            <Typography variant="h6" align="left" gutterBottom>
              <b>Well this is awkward...</b>
            </Typography>

            <Typography align="justify" gutterBottom>
              This page appears to be missing. This can happen if the link you
              received is out of date or belongs to our old website. If you
              think something should be here and this is an error, please
              contact us at{" "}
              <a href="mailto:info@123ConEd.com">info@123ConEd.com</a>.
            </Typography>
          </CardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

export default withPageTitle("404")(NotFoundPage)
