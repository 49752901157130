import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import React, { Component } from "react"
import { Mutation } from "react-apollo"

import LoadingButton from "../../auth/components/LoadingButton"
import CaptchaField from "../../input/components/v2/auth/CaptchaField"
import { input } from "../../input/operators/handleChange"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import sendContactForm from "../mutations/sendContactForm"
import {
  SendContactForm,
  SendContactFormVariables,
} from "../mutations/types/SendContactForm"
import ContactFormContent from "./ContactFormContent"

interface Props {
  user?: User
  onSuccess: (message: string) => void
  onFailure: (message: string) => void
}

interface State {
  name: string
  email: string
  message: string
  captchaToken: string
  failureCount: number
}

const MAX_FAILURE_COUNT = 5

class ContactForm extends Component<Props, State> {
  private onChange = input(this.state, this.handleChange.bind(this))
  private failureTimer?: number

  constructor(props: Props) {
    super(props)
    this.state = {
      name: props.user ? `${props.user.firstName} ${props.user.lastName}` : "",
      email: props.user ? props.user.email : "",
      message: "",
      captchaToken: "",
      failureCount: 0,
    }
  }

  componentDidUpdate(prevProps: Props) {
    // Update the current user details if we have just loaded the user
    if (prevProps.user !== this.props.user) {
      this.setContactUserFromProps()
    }
    // Prevent the user from spamming contact form requests even when they appear legitimate
    if (this.state.failureCount >= MAX_FAILURE_COUNT && !this.failureTimer) {
    }
  }

  setCaptchaToken = (token: string) => {
    this.setState({ captchaToken: token })
  }

  handleChange(state: State) {
    this.setState({
      ...state,
    })
  }

  render() {
    const InputLabelProps = {
      shrink: true,
    }
    return (
      <div className="container">
        <TitledWrapper title="Contact Us" showBanner gapTop>
          <Card elevation={1}>
            <CardContent>
              <ContactFormContent />

              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    InputLabelProps={InputLabelProps}
                    value={this.state.name}
                    label="Name"
                    variant="outlined"
                    onChange={this.onChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    InputLabelProps={InputLabelProps}
                    value={this.state.email}
                    label="Email Address"
                    variant="outlined"
                    onChange={this.onChange("email")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    multiline
                    InputLabelProps={InputLabelProps}
                    value={this.state.message}
                    label="Message"
                    rows="4"
                    variant="outlined"
                    onChange={this.onChange("message")}
                  />
                </Grid>
                <CaptchaField
                  onVerifyCaptcha={this.setCaptchaToken}
                  action="contactform"
                />
                <Grid item xs={12}>
                  <Mutation<SendContactForm, SendContactFormVariables>
                    mutation={sendContactForm}
                  >
                    {(doMutation, { loading }) => {
                      const onClick = async () => {
                        if (this.state.failureCount >= MAX_FAILURE_COUNT) {
                          this.props.onFailure(
                            `Your request to send a message has failed ${MAX_FAILURE_COUNT} times in the last couple of minutes. Please wait or try refreshing the page.`
                          )
                          return
                        }

                        try {
                          const response = await doMutation({
                            variables: {
                              input: {
                                name: this.state.name,
                                email: this.state.email,
                                message: this.state.message,
                                captchaToken: this.state.captchaToken,
                              },
                            },
                          })
                          // Check for GraphQL errors
                          if (response.errors) {
                            throw Error(
                              response.errors
                                ?.map((error) => error.message)
                                ?.join(". ")
                            )
                          }
                          // Check for timeout
                          if ((response as Error)?.name === "Error") {
                            throw response
                          }
                          this.props.onSuccess("Message sent successfully")
                          this.setState({ message: "" })
                        } catch (e) {
                          console.error(e)
                          const failureCount = this.state.failureCount + 1
                          this.setState({ failureCount })
                          this.props.onFailure(
                            "Unable to send message. Please try again later"
                          )
                        }
                      }

                      return (
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          isLoading={loading}
                          disabled={!this.canSendForm() || loading}
                          onClick={onClick}
                        >
                          Send
                        </LoadingButton>
                      )
                    }}
                  </Mutation>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </TitledWrapper>
      </div>
    )
  }

  private setContactUserFromProps() {
    const { user } = this.props
    if (user) {
      this.setState({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      })
    }
  }

  private canSendForm(): boolean {
    const { name, email, message } = this.state
    return Boolean(name && email && message)
  }
}

export default ContactForm
