import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import React from "react"
import styled from "styled-components"

import { CourseCourseType } from "../../../types/Global-Types"
import { GetMyCourses_myStudentCourses } from "../../queries/types/GetMyCourses"
import Gutter from "./Gutter"
import PrimaryButton from "./PrimaryButton"
import SecondaryButton from "./SecondaryButton"
import TextContent from "./TextContent"

type StudentCourse = GetMyCourses_myStudentCourses

interface Props {
  studentCourse: StudentCourse
}

const CourseCard = ({ studentCourse }: Props) => {
  const isLaw = studentCourse.course.courseType === CourseCourseType.LAW

  return (
    <Wrapper>
      <StyledCard>
        <Gutter isLaw={isLaw} />
        <StyledCardContent>
          <TextContent studentCourse={studentCourse} />
        </StyledCardContent>
        <StyledCardActions>
          <PrimaryButton
            isLaw={isLaw}
            studentCourseId={studentCourse.id}
            studentCourseStatus={studentCourse.status}
          />
          <SecondaryButton
            studentCourseId={studentCourse.id}
            studentCourseStatus={studentCourse.status}
          />
        </StyledCardActions>
      </StyledCard>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
`
const StyledCard = styled(Card)`
  overflow: hidden;
  position: relative;
  height: 100%;
`
const StyledCardContent = styled(CardContent)`
  padding: 24px;
  margin-left: 50px;
  height: calc(100% - 55px);
`
const StyledCardActions = styled(CardActions)`
  border-top: 1px solid #dfdfdf;
  height: 55px;
  margin-left: 50px;
`

export default CourseCard
