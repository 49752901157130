import * as Sentry from "@sentry/browser"

export const reportError = (error: any, info: any) => {
  if (process.env.NODE_ENV === "development") {
    return
  }

  // https://github.com/getsentry/sentry-javascript/issues/2210
  error =
    error instanceof Error || typeof error === "string"
      ? error
      : typeof error === "object" && error.message
      ? new Error(error.message)
      : new Error(JSON.stringify(error))

  Sentry.withScope((scope) => {
    Object.keys(info).forEach((key) => {
      scope.setExtra(key, info[key])
    })
    Sentry.captureException(error, scope)
  })
}
