import React from "react"

import PaddedGrid from "../../../helpers/components/PaddedGrid"
import {
  Dashboard_cycleLicenseRequirements,
  Dashboard_dashboardPage,
} from "../../queries/types/Dashboard"
import DateRangeCard from "./cards/DateRangeCard"
import DaysRemainingCard from "./cards/DaysRemainingCard"
import LegalDaysRemainingCard from "./cards/LegalDaysRemainingCard"

interface Props {
  dashboardPage?: Dashboard_dashboardPage
  licenseRequirements?: Dashboard_cycleLicenseRequirements
}

const StatsWrapper = ({ dashboardPage, licenseRequirements }: Props) => {
  return (
    <PaddedGrid>
      <LegalDaysRemainingCard
        body={dashboardPage?.calendarDaysText}
        endDate={licenseRequirements?.endDate}
      />
      <DaysRemainingCard
        body={dashboardPage?.cycleDaysText}
        endDate={licenseRequirements?.endDate}
      />
      <DateRangeCard
        body={dashboardPage?.cycleRangeText}
        startDate={licenseRequirements?.startDate}
        endDate={licenseRequirements?.endDate}
      />
    </PaddedGrid>
  )
}

export default StatsWrapper
