import { CourseCourseType, StudentCourseStatus } from "../../types/Global-Types"

export const getCourseTypeString = (courseType: CourseCourseType) => {
  switch (courseType) {
    case "LAW":
      return "Law"
    case "NON_LAW":
      return "Non-Law"
    default:
      return "Unknown"
  }
}

export const getHourString = (hours: number) => {
  return hours === 1 ? `${hours} hour` : `${hours} hours`
}

export const getFriendlyStatusString = (type: StudentCourseStatus) => {
  switch (type) {
    case "NOT_STARTED":
      return "Not Started"
    case "IN_PROGRESS":
      return "In Progress"
    case "FINISHED":
      return "Finished"
    case "EXPIRED":
      return "Expired"
    default:
      return "Unknown"
  }
}
