import { gql } from "apollo-boost"

export default gql`
  query BlogPost($postId: ID!) {
    blogPost(postId: $postId) {
      id
      modified
      title
      content
      author {
        id
        firstName
        lastName
      }
      headerImage {
        id
        file
      }
    }
  }
`
