import { gql } from "apollo-boost"

export default gql`
  mutation FinishStudentCourse($studentCourseId: ID!, $timeElapsed: Int) {
    finishStudentCourse(
      studentCourseId: $studentCourseId
      timeElapsed: $timeElapsed
    ) {
      ok
    }
  }
`
