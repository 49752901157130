import { createSelector } from "reselect"

import {
  getCurrentUser,
  isCurrentUserLoading,
} from "../../auth/selectors/currentUserGetters"
import { missingProfileDetails } from "../../models/user/operators/missingProfileDetails"

export const shouldShowMissingDetailsContainer = createSelector(
  [isCurrentUserLoading, getCurrentUser],
  (isLoading, currentUser) => {
    if (isLoading) {
      return false
    }
    return Boolean(currentUser && missingProfileDetails(currentUser))
  }
)
