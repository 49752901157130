import { DocumentNode } from "graphql"
import { Middleware } from "redux"

import getCart from "../../cart/queries/getCart"
import { CheckoutPayload } from "../../checkout/actions/checkout"
import { CHECKOUT_STATUS_CHANGE } from "../../checkout/constants/ActionTypes"
import { client } from "../../graphql/client/client"
import getLatestCourses from "../../home/queries/getLatestCourses"
import availableShopCourses from "../../shop-courses/queries/availableShopCourses"
import getMyTransactions from "../queries/getMyTransactions"

const updateQueryIfExists = (query: DocumentNode) => {
  try {
    const result = client.readQuery({ query })
    if (result) {
      client.query({ query, fetchPolicy: "network-only" })
    }
  } catch (e) {
    // https://github.com/apollographql/apollo-feature-requests/issues/1
  }
}

export const handleFinalizedTransaction: Middleware = () => (next) => (
  action
) => {
  const { type } = action
  if (type === CHECKOUT_STATUS_CHANGE) {
    const { payload }: { payload: CheckoutPayload } = action
    if (payload.status === "SUCCEEDED" || payload.status === "FAILED") {
      updateQueryIfExists(getMyTransactions)
      updateQueryIfExists(getCart)
      updateQueryIfExists(availableShopCourses)
      updateQueryIfExists(getLatestCourses)
    }
  }
  return next(action)
}
