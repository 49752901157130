import Button from "@material-ui/core/Button"
import React from "react"

import { useInterval } from "../../../input/hooks/useInterval"

interface Props {
  shouldPoll: boolean
  onSave: (redirect: boolean) => void
}

const ONE_MINUTE = 60000

// Destructring parameters breaks in Edge: https://github.com/microsoft/ChakraCore/issues/2606
const PauseButton = (props: Props) => {
  // Autosave every interval
  useInterval(() => {
    props.onSave(false)
  }, ONE_MINUTE)

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => props.onSave(true)}
    >
      Pause
    </Button>
  )
}

export default PauseButton
