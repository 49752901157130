import { connect } from "react-redux"

import { hasFailed } from "../../helpers/selectors/hasFailed"
import { isLoading } from "../../helpers/selectors/isLoading"
import withOnSaveMessage from "../../notifications/containers/withOnSaveMessage"
import withPageTitle from "../../routes/containers/withPageTitle"
import { State } from "../../store"
import { passwordResetConfirm } from "../actions/passwordResetConfirm"
import { resetStatus } from "../actions/resetStatus"
import ConfirmWrapper from "../components/password/ConfirmWrapper"

const mapStateToProps = (state: State) => ({
  isLoading: isLoading(state.auth),
  resetFailed: hasFailed(state.auth),
})

const mapDispatchToProps = {
  onPasswordReset: passwordResetConfirm,
  resetStatus,
}

const ConnectedPasswordResetConfirm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withOnSaveMessage(ConfirmWrapper))

export default withPageTitle("Reset Password")(ConnectedPasswordResetConfirm)
