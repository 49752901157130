import { graphql } from "react-apollo"

import withPageTitle from "../../routes/containers/withPageTitle"
import DashboardWrapper from "../components/DashboardWrapper"
import dashboard from "../queries/dashboard"
import { Dashboard } from "../queries/types/Dashboard"

export default withPageTitle("Dashboard")(
  graphql<unknown, Dashboard, unknown, unknown>(dashboard, {
    options: { fetchPolicy: "cache-and-network" },
  })(DashboardWrapper)
)
