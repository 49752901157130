import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core/styles/createMuiTheme"
import React, { memo } from "react"
import styled from "styled-components"

import LogoHolder from "../../navbar/components/LogoHolder"
import { isActiveLink } from "../../navbar/operators/isActiveLink"
import { SidebarAdminNavLinks } from "../constants/SidebarAdminNavLinks"
import { SidebarNavigationLinks } from "../constants/SidebarNavigationLinks"
import { useCollapsed } from "../hooks/useCollapsed"
import AdminSidebarExtension from "./AdminSidebarExtension"
import CollapseButton from "./CollapseButton"
import SidebarLink from "./SidebarLink"

export interface Props {
  navigationLinks: NavigationLink[]
  activeLink: string
  isVisible: boolean
  isAdmin: boolean
}

const Sidebar = memo(({ activeLink, isVisible, isAdmin }: Props) => {
  const [collapsed, setCollasped] = useCollapsed()
  const styleProps = { collapsed, isVisible }
  const { root, list, listItem, listTop } = useStyles(styleProps)

  const onClick = () => setCollasped(!collapsed)

  return (
    <SidebarWrapper isVisible={isVisible}>
      <LogoWrapper>
        <LogoHolder absolute={false} extended={!collapsed} />
        <CollapseButton collapsed={collapsed} onClick={onClick} />
      </LogoWrapper>
      <Grid
        container
        className={root}
        direction="column"
        spacing={0}
        wrap="nowrap"
      >
        <Grid item className={list}>
          <Grid container spacing={0}>
            <Grid item className={listTop}>
              <List>
                {SidebarNavigationLinks.map((link, index) => (
                  <ListItem key={index} button className={listItem}>
                    <SidebarLink
                      {...link}
                      isActive={isActiveLink(link, activeLink)}
                      collapsed={collapsed}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
            {isAdmin && (
              <AdminSidebarExtension
                navigationLinks={SidebarAdminNavLinks}
                activeLink={activeLink}
                collapsed={collapsed}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </SidebarWrapper>
  )
})

Sidebar.displayName = "Sidebar"

interface StyleProps {
  collapsed: boolean
  isVisible: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => {
    if (!props.isVisible) {
      return { width: 0, display: "none" }
    }
    const PADDING_TOP = props.collapsed ? 80 : 160
    const WIDTH = props.collapsed ? 80 : 300
    return {
      maxWidth: WIDTH,
      width: WIDTH,
      paddingTop: PADDING_TOP,
      paddingLeft: props.collapsed ? 0 : 16,
      paddingRight: props.collapsed ? 0 : 16,
      minHeight: 500,
      height: "100vh",
      background: theme.palette.primary.dark,
      boxShadow: "5px 0px 5px 0px rgba(0,0,0,0.15)",
    }
  },
  listTop: {
    marginTop: 40,
  },
  list: {
    overflowY: "auto",
    "& div": {
      width: "100%",
    },
  },
  listItem: {
    padding: 0,
  },
}))

interface WrapperProps {
  isVisible: boolean
}
const SidebarWrapper = styled(
  ({ isVisible, ...rest }: WrapperProps & React.PropsWithChildren<unknown>) => (
    <div {...rest} />
  )
)`
  display: ${({ isVisible }) => (isVisible ? "auto" : "none")};
`

const LogoWrapper = styled.div`
  position: relative;

  & > div {
    position: absolute;
  }
`

export default Sidebar
