import { connect } from "react-redux"

import { getCurrentUser } from "../../auth/selectors/currentUserGetters"
import withPageTitle from "../../routes/containers/withPageTitle"
import { State } from "../../store"
import Hero, { Props } from "../components/Hero"

type DefinedProps = Pick<Props, "loggedIn">

const mapStateToProps = (state: State): DefinedProps => ({
  loggedIn: Boolean(getCurrentUser(state)),
})

const ConnectedHero = connect<DefinedProps, unknown, unknown, unknown>(
  mapStateToProps
)(Hero)

export default withPageTitle("Home")(ConnectedHero)
