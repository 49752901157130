export const missingProfileDetails = (user: User) => {
  return (
    !user.email ||
    !user.firstName ||
    !user.lastName ||
    !user.ssn ||
    !user.licenseNumber ||
    !user.phone
  )
}
