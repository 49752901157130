export const emailPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  message: "Invalid email address",
}

export const passwordValidate = (data: any): string | boolean => {
  if (!data) {
    return "Password must have a value"
  }
  if (typeof data !== "string") {
    return "Password must be alphanumeric"
  }
  if (data.length < 8) {
    return "Password must be 8 characters in length"
  }
  if (!data.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)) {
    return "Password must contain at least one uppercase letter, one lowercase letter, and one number"
  }
  return true
}

export const zipCodeValidate = (data: any): string | boolean => {
  if (data && !data.match(/^\d{5}(?:[-\s]\d{4})?$/)) {
    return "Zip code must be 5 digits or 9 digits separated by a dash"
  }
  return true
}

export const ssnValidate = (data: any): string | boolean => {
  if (!data.match(/^\d{4}?$/)) {
    return "Must contain the last 4 digits of your social-security number"
  }
  return true
}

export const licenseValidate = (data: any): string | boolean => {
  if (!data.match(/^\d{10}?$/)) {
    return "License number must be exactly 10 digits"
  }
  if (`${data}`.startsWith("6505")) {
    return "A license number that begins with '6505' belongs to a company. Please enter your personal license number."
  }
  return true
}

export const phoneNumberValidate = (data: any): string | boolean => {
  if (data.replace(/[^\d]/g, "").length !== 10) {
    return "Phone number must contain 10 digits"
  }
  return true
}

export const percentageValidate = (data: any): string | boolean => {
  if (Number.isNaN(Number(data))) {
    return "Percentages must be numbers"
  }
  if (Number(data) > 100 || Number(data) < 0) {
    return "A percentage must be between 0 and 100"
  }
  return true
}

export const colonDelimitedValidate = (data: any): string | boolean => {
  if (data.split(",").length > 1) {
    return "Commas not allowed"
  }
  for (const email of data.split(";")) {
    const isLegal = emailPattern.value.test(email.trim())
    if (!isLegal) {
      if (email.trim() === "") {
        return "Colon characters should only be used between two email addresses and not at the beginning or end"
      }
      return `${email} is an invalid email address`
    }
  }
  return true
}
