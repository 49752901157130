import { getItem, setItem } from "../../helpers/operators/localStorage"

const HIDDEN_ALERT_IDS = "HIDDEN_ALERT_IDS"

const getKeys = (): string[] => {
  const keys = JSON.parse(getItem(HIDDEN_ALERT_IDS) || "[]")
  if (Array.isArray(keys)) {
    return keys.map((i) => `${i}`)
  } else {
    return []
  }
}

const setKeys = (keys: string[]) => {
  const str = JSON.stringify(keys)
  setItem(HIDDEN_ALERT_IDS, str)
}

export const isAlertIdHidden = (alertId: string) => getKeys().includes(alertId)

export const hideAlert = (alertId: string) => {
  alertId = String(alertId)
  const existingKeys = getKeys()
  if (!existingKeys.includes(alertId)) {
    existingKeys.splice(0, 0, alertId)
  }
  setKeys(existingKeys)
}
