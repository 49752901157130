import Typography from "@material-ui/core/Typography"
import React from "react"
import { useQuery } from "react-apollo"

import Divider from "../../helpers/components/Divider"
import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import StyledHTML from "../../helpers/components/StyledHTML"
import loadContactPage from "../queries/loadContactPage"
import { LoadContactPage } from "../queries/types/LoadContactPage"

const ContactFormContent = () => {
  const { data, loading } = useQuery<LoadContactPage>(loadContactPage)
  return (
    <LoadingWrapper isLoading={loading && !data?.contactPage}>
      {data?.contactPage ? (
        <Typography component="div" align="justify" gutterBottom>
          <StyledHTML html={data.contactPage.body} />
        </Typography>
      ) : (
        <div>Unable to load contact information and hours of operation</div>
      )}
      <Divider />
    </LoadingWrapper>
  )
}

export default ContactFormContent
