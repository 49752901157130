import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import React from "react"
import styled from "styled-components"

import RouterLink from "../../helpers/components/RouterLink"
import StyledHTML from "../../helpers/components/StyledHTML"
import { GetFooter_footer } from "../queries/types/GetFooter"

interface Props {
  data: GetFooter_footer
}

const safeParse = (data: string) => {
  try {
    return JSON.parse(data)
  } catch (err) {
    console.error(err)
    return []
  }
}

const Footer = ({ data }: Props) => {
  const columnOne = safeParse(data.columnOne)
  const columnTwo = safeParse(data.columnTwo)
  const columnThree = data.columnThree.replace(
    "{{ year }}",
    String(new Date().getFullYear())
  )

  return (
    <FooterContainer>
      <div className="container">
        <Grid container alignItems="center">
          <FlexedGrid item md>
            <List>
              {columnOne.map((item: FooterLink, index: number) => (
                <ListItem key={index}>
                  <RouterLink to={item.route}>
                    <ListItemText primary={item.name} />
                  </RouterLink>
                </ListItem>
              ))}
            </List>
          </FlexedGrid>
          <FlexedGrid item md>
            <List>
              {columnTwo.map((item: FooterLink, index: number) => (
                <ListItem key={index}>
                  <RouterLink to={item.route}>
                    <ListItemText primary={item.name} />
                  </RouterLink>
                </ListItem>
              ))}
            </List>
          </FlexedGrid>
          <FlexedGrid item md>
            <StyledHTML html={columnThree} />
          </FlexedGrid>
        </Grid>
      </div>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  z-index: 0;
  flex-shrink: 0;
  background: #333;
  color: #fff;
  overflow: hidden;
  padding: 2em;
  text-align: center;

  a {
    color: #fff;
  }
`
const FlexedGrid = styled(Grid)`
  flex: auto;
  text-align: center;
`

export default Footer
