import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"
import { RouteComponentProps } from "react-router-dom"

import { getPathParams } from "../../helpers/operators/getPathParams"
import { routes } from "../../routes/constants/routes"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import { history } from "../../store"
import { confirmEmail } from "../api/confirmEmail"

export interface Props extends RouteComponentProps {
  isLoggedIn: boolean
}

interface State {
  loading: boolean
  error: boolean
}

class ConfirmEmail extends Component<Props, State> {
  private timeout?: number

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      error: false,
    }
  }

  componentDidMount() {
    this.confirmEmail()
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  renderStatus() {
    const { loading, error } = this.state
    switch (true) {
      case loading:
        return "Verifying email..."
      case error:
        return "There was an error verifying your email. Please refresh to try again"
      default:
        return "Email successfully verified! Redirecting in 3 seconds..."
    }
  }

  render() {
    return (
      <div className="container">
        <TitledWrapper title="Confirming Email" gapTop showBanner>
          <Card elevation={1}>
            <CardContent>
              <Typography variant="h6" align="left" gutterBottom>
                Confirming Email
              </Typography>
              <Typography align="left">{this.renderStatus()}</Typography>
            </CardContent>
          </Card>
        </TitledWrapper>
      </div>
    )
  }

  private getEmailKey(): string | undefined {
    const { id } = getPathParams(this.props)
    return id
  }

  private confirmEmail() {
    const key = this.getEmailKey()
    const { isLoggedIn } = this.props
    if (!key) {
      this.setState({ error: true })
    } else {
      this.setState({ loading: true })
      confirmEmail(key)
        .then(() => {
          this.timeout = window.setTimeout(() => {
            this.timeout = undefined
            history.push(isLoggedIn ? routes.APP.DASHBOARD : routes.HOME)
          }, 3000)
        })
        .catch((e) => {
          console.error(e)
          this.setState({ error: true })
        })
        .finally(() => this.setState({ loading: false }))
    }
  }
}

export default ConfirmEmail
