import Button from "@material-ui/core/Button"
import React from "react"

interface Props {
  onClick: (lessonId: string | null) => void
  currentLessonId: string | null
  previousLessonId?: string
}

const PreviousButton = ({
  currentLessonId,
  previousLessonId,
  onClick: propClick,
}: Props) => {
  if (previousLessonId) {
    const onClick = () => propClick(previousLessonId)
    return (
      <Button variant="contained" onClick={onClick}>
        Previous
      </Button>
    )
  }
  if (currentLessonId) {
    const onClick = () => propClick(null)
    return (
      <Button variant="contained" onClick={onClick}>
        Introduction
      </Button>
    )
  }
  return null
}

export default PreviousButton
