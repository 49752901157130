import { Action, combineActions, handleActions } from "redux-actions"

import { setCurrentUser } from "../../errors/api/setCurrentUser"
import {
  handleActionFailed,
  handleActionPending,
  handleActionSucceeded,
  StateWithStatus,
  Status,
} from "../../helpers/reducers"
import {
  FETCH_CURRENT_USER,
  HEARTBEAT,
  LOGIN_USER,
  LOGOUT_USER,
  PASSWORD_RESET_CONFIRM,
  PASSWORD_RESET_REQUEST,
  REGISTER_USER,
  RESET_STATUS,
  SAVE_PROFILE,
} from "../constants/ActionTypes"

export interface AuthState extends StateWithStatus {
  currentUser: User | null
  hasFetched: boolean
  heartbeatLoggedIn: boolean
  errors: RestError | undefined
  loginError?: string
}

const defaultState: AuthState = {
  currentUser: null,
  hasFetched: false,
  heartbeatLoggedIn: false,
  status: Status.UNINITIALIZED,
  errors: {},
}

const pendingActions = combineActions(
  LOGIN_USER.PENDING,
  FETCH_CURRENT_USER.PENDING,
  REGISTER_USER.PENDING,
  PASSWORD_RESET_REQUEST.PENDING,
  PASSWORD_RESET_CONFIRM.PENDING
)
const successActions = combineActions(
  PASSWORD_RESET_REQUEST.SUCCEEDED,
  PASSWORD_RESET_CONFIRM.SUCCEEDED
)
const failureActions = combineActions(
  PASSWORD_RESET_REQUEST.FAILED,
  PASSWORD_RESET_CONFIRM.FAILED
)
const successUserActions = combineActions(
  LOGIN_USER.SUCCEEDED,
  FETCH_CURRENT_USER.SUCCEEDED,
  REGISTER_USER.SUCCEEDED,
  SAVE_PROFILE.SUCCEEDED
)
const failureUserActions = combineActions(
  FETCH_CURRENT_USER.FAILED,
  REGISTER_USER.FAILED
)
const hasFetched = combineActions(
  FETCH_CURRENT_USER.SUCCEEDED,
  FETCH_CURRENT_USER.FAILED
)

const saveUser = (state: AuthState, { payload }: Action<User>) => {
  setCurrentUser(payload)
  return {
    ...state,
    error: undefined,
    currentUser: payload || null,
    heartbeatLoggedIn: Boolean(payload),
  }
}

const resetUser = (state: AuthState, { meta }: ActionWithErrorFields<User>) => {
  const errors = meta?.error.message
  return {
    ...state,
    errors,
    currentUser: null,
  }
}

export const authReducer = handleActions<AuthState>(
  {
    [pendingActions.toString()]: handleActionPending(),
    [successActions.toString()]: handleActionSucceeded(),
    [successUserActions.toString()]: handleActionSucceeded(saveUser),
    [failureActions.toString()]: handleActionFailed(),
    [failureUserActions.toString()]: handleActionFailed(resetUser),
    [SAVE_PROFILE.PENDING]: handleActionPending((state: AuthState) => {
      return {
        ...state,
        errors: undefined,
      }
    }),
    [SAVE_PROFILE.FAILED]: handleActionFailed(
      (state: AuthState, { meta }: ActionWithErrorFields<User>) => {
        if (meta?.error.message) {
          const { message, error } = meta.error
          if (typeof message === "string" && meta.error.code === 500) {
            return {
              ...state,
              errors: { generic: message },
            }
          }
          if (error?.message && typeof error?.message === "object") {
            return {
              ...state,
              errors: error?.message,
            }
          }
        }
        return { ...state }
      }
    ),
    [HEARTBEAT.SUCCEEDED]: (
      state: AuthState,
      action: Action<boolean>
    ): AuthState => {
      return { ...state, heartbeatLoggedIn: action.payload }
    },
    [LOGIN_USER.PENDING]: handleActionPending((state: AuthState) => {
      return {
        ...state,
        loginError: undefined,
      }
    }),
    [LOGIN_USER.FAILED]: handleActionFailed(
      (state: AuthState, { meta }: ActionWithErrorFields<User>) => {
        const message = meta?.error?.message
        if (message && typeof message === "object" && message.detail) {
          return {
            ...state,
            loginError: message.detail,
          }
        }
        return { ...state }
      }
    ),
    [hasFetched.toString()]: (state: AuthState): AuthState => {
      return { ...state, hasFetched: true }
    },
    [LOGOUT_USER.PENDING]: (state: AuthState): AuthState => {
      return { ...state, currentUser: null, hasFetched: false }
    },
    [LOGOUT_USER.SUCCEEDED]: resetUser,
    [LOGOUT_USER.FAILED]: resetUser,
    [RESET_STATUS](state: AuthState) {
      return { ...state, status: Status.UNINITIALIZED }
    },
  },
  defaultState
)
