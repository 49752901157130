import Badge from "@material-ui/core/Badge"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import React, { Component } from "react"
import { ChildDataProps } from "react-apollo"

import FontAwesome from "../../helpers/components/FontAwesome"
import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"
import { GetCart } from "../queries/types/GetCart"

export interface PassthroughProps {
  disabled: boolean
  fullWidth?: boolean
}

type Props = ChildDataProps<PassthroughProps, GetCart>

class CartButton extends Component<Props> {
  renderButton() {
    const { disabled, fullWidth } = this.props
    return (
      <Badge color="primary" badgeContent={this.getCartItemCount()}>
        <Button variant="contained" disabled={disabled} fullWidth={fullWidth}>
          <FontAwesome icon="fas fa-shopping-cart" gapRight />
          Cart
        </Button>
      </Badge>
    )
  }

  render() {
    const { disabled } = this.props
    if (disabled) {
      return (
        <Tooltip title={"You must sign in to add items to your cart"}>
          <div>{this.renderButton()}</div>
        </Tooltip>
      )
    }
    return <RouterLink to={routes.SHOP.CART}>{this.renderButton()}</RouterLink>
  }

  private getCartItemCount() {
    const {
      disabled,
      data: { shopCourseCart },
    } = this.props
    if (disabled || !shopCourseCart) {
      return 0
    }
    return shopCourseCart.shopCourses.length
  }
}

export default CartButton
