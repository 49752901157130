import { css } from "styled-components"

import { textBoxBackground, textBoxBorder } from "../../Theme"

export const editorCSS = css`
  body,
  p {
    font-family: Lato, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    line-height: 1.5;
  }

  p,
  ol,
  ul,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Quattrocento", sans-serif;
  }

  ol,
  ul {
    padding-left: 1.5em;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-size-large {
    font-size: 1.5em;
  }

  .text-box-wrapper {
    font-family: inherit;
    white-space: pre-wrap;
    border: 1px solid ${textBoxBorder};
    color: rgb(0, 0, 0);
    margin: 10px 0px;
    padding: 20px;
    line-height: normal;
    background-color: ${textBoxBackground};
  }

  .text-box-wrapper.white {
    background-color: #fff;
  }
`
