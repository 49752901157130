type ErrorSubscriber = (message: string) => void

class ErrorBus {
  private readonly subscribers: ErrorSubscriber[]
  private lastDispatch: number

  constructor() {
    this.subscribers = []
    this.lastDispatch = performance.now()
  }

  subscribe(subscriber: ErrorSubscriber) {
    this.subscribers.push(subscriber)
  }

  dispatch(message: string, lazy?: boolean) {
    const doDispatch = () =>
      this.subscribers.forEach((subscriber) => subscriber(message))
    if (lazy) {
      setTimeout(() => {
        const outsideWindow = performance.now() - this.lastDispatch > 500
        if (outsideWindow) {
          doDispatch()
          this.lastDispatch = performance.now()
        }
      }, 100)
    } else {
      doDispatch()
      this.lastDispatch = performance.now()
    }
  }
}

export default new ErrorBus()
