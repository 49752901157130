import * as Sentry from "@sentry/browser"

export const setCurrentUser = (user?: User) => {
  if (user) {
    Sentry.configureScope((scope) =>
      scope.setUser({
        ...user,
        id: String(user.id),
        email: user.email,
      })
    )
  }
}
