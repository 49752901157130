import React from "react"
import styled from "styled-components"

interface Props {
  active: boolean
}

const Indicator = ({ active }: Props) => {
  return (
    <SVG
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      fill="white"
      active={active}
    >
      <circle cx="12" cy="12" r="8" />
    </SVG>
  )
}

const SVG = styled.svg`
  width: 16px;
  height: 16px;
  cursor: pointer;
  opacity: ${({ active }: Props) => (active ? "1" : "0.25")};
  transition: opacity 0.25s;
  &:hover {
    opacity: 1;
  }
`

export default Indicator
