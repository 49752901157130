import { connect } from "react-redux"

import { getCurrentUser } from "../../auth/selectors/currentUserGetters"
import { State } from "../../store"
import BannerIcons from "../components/BannerIcons"

const mapStateToProps = (state: State) => ({
  user: getCurrentUser(state),
})

export default connect(mapStateToProps)(BannerIcons)
