import { routes } from "../../routes/constants/routes"

export const SidebarAdminNavLinks: NavigationLink[] = [
  {
    text: "Dashboard",
    url: routes.ADMIN_NAV.HOME,
    icon: "fa-tachometer-alt",
  },
  {
    text: "Courses",
    url: routes.ADMIN_NAV.COURSES.LIST,
    icon: "fa-book",
  },
  {
    text: "Students",
    url: routes.ADMIN_NAV.STUDENTS.LIST,
    icon: "fa-user-graduate",
  },
  {
    text: "Pages",
    url: routes.ADMIN_NAV.PAGES.ROOT,
    icon: "fa-file-alt",
  },
  {
    text: "Reports",
    url: routes.ADMIN_NAV.REPORTS,
    icon: "fa-chart-bar",
  },
  {
    text: "Transactions",
    url: routes.ADMIN_NAV.TRANSACTIONS.LIST,
    icon: "fa-shopping-cart",
  },
  {
    text: "Blog Posts",
    url: routes.ADMIN_NAV.BLOG.LIST,
    icon: "fa-blog",
  },
  {
    text: "Discounts",
    url: routes.ADMIN_NAV.DISCOUNTS.LIST,
    icon: "fa-tags",
  },
  {
    text: "Settings",
    url: routes.ADMIN_NAV.SETTINGS,
    icon: "fa-cogs",
  },
  {
    text: "Contact Submissions",
    url: routes.ADMIN_NAV.SUBMISSIONS,
    icon: "fa-envelope-open",
  },
]
