import { graphql } from "react-apollo"

import GlobalAlert from "../components/GlobalAlert"
import currentGlobalAlerts from "../queries/currentGlobalAlerts"
import { CurrentGlobalAlerts } from "../queries/types/CurrentGlobalAlerts"

export default graphql<any, CurrentGlobalAlerts, any, any>(
  currentGlobalAlerts,
  {
    options: {
      fetchPolicy: "cache-and-network",
    },
  }
)(GlobalAlert)
