import { applyMiddleware } from "redux"

import { handleCourseRedirects } from "../admin/courses/middleware/handleCourseRedirects"
import { handleDiscountRedirects } from "../admin/discounts/middleware/handleDiscountRedirects"
import { handleStudentRedirects } from "../admin/students/middleware/handleStudentRedirects"
import { handleAuth } from "../auth/middleware/handleAuth"
import { courseRedirect } from "../my-courses/middleware/courseRedirect"
import { handleFinalizedTransaction } from "../my-purchases/middleware/handleFinalizedTransaction"
import { handleAsyncFailures } from "../notifications/middleware/handleAsyncFailures"

const historyRedirects = [
  handleCourseRedirects,
  handleDiscountRedirects,
  handleStudentRedirects,
  handleAuth,
  handleFinalizedTransaction,
  courseRedirect,
]

export default applyMiddleware(...historyRedirects, handleAsyncFailures)
