import { RouteComponentProps } from "react-router"

export const getQueryParams = (params: RouteComponentProps) => {
  const { search } = params.location
  const queryStuff = search.split("?")
  const queryParams: { [key: string]: string | boolean } = {}

  // No query params
  if (queryStuff.length < 2) {
    return queryParams
  }

  // Iterate thru each
  queryStuff[1].split("&").forEach((combo) => {
    const key = combo.split("=")[0]
    const value = combo.split("=")[1]
    queryParams[key] = value || true
  })

  return queryParams
}
