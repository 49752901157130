import { api } from "../../helpers/api/handler"
import { convertToUser } from "../../models/user/serializers/User"
import { RegisterPayload } from "../actions/registerUser"
import { login } from "./login"

const EXISTING_USER_ERRORS = [
  "A user with that email address already exists",
  "A user is already registered with this e-mail address.",
]

export const register = ({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
}: RegisterPayload) =>
  api
    .post<{ user?: BE_User; message?: string }>(
      "/auth/register/",
      JSON.stringify({
        email,
        password1: password,
        password2: confirmPassword,
        first_name: firstName,
        last_name: lastName,
      })
    )
    .then(({ user, message }) => {
      if (user) {
        return convertToUser(user)
      } else {
        console.error(message || "Issue encountered during registration")
        return login(email, password, true).then()
      }
    })
    .catch((err: ApiError) => {
      if (EXISTING_USER_ERRORS.includes(err.message)) {
        err.name = "UserExistsError"
        throw err
      }
      throw err
    })
