export type HandleInput<T> = (
  property: keyof T
) => ({ target: { value } }: ChangeEventWithValue) => void

export const input = <T>(
  stateValue: T,
  changeHandler: (value: T) => void
): HandleInput<T> => (property: keyof T) => ({
  target: { value },
}: ChangeEventWithValue) => {
  const newValue = Object.assign({}, stateValue, { [property]: value }) as T
  changeHandler(newValue)
}

// export const handleChange = (student: ) => {
//   this.setState({
//     student
//   })
// }
