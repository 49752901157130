import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { useEffect, useState } from "react"

import Dialog from "../../../helpers/components/Dialog"
import { useInterval } from "../../../input/hooks/useInterval"
import LoginFormContainer from "../../containers/LoginFormContainer"
import styled from "styled-components"

export interface Props {
  shouldShowModal: boolean
  shouldPoll: boolean
  checkHeartbeat(): void
  logout(): void
}

const THREE_MINUTES = 180000

const HeartbeatWrapper = ({
  shouldShowModal,
  shouldPoll,
  checkHeartbeat,
  logout,
}: Props) => {
  const [open, setOpen] = useState(shouldShowModal)

  // Make request to heartbeat endpoint
  useInterval(() => {
    if (shouldPoll) {
      checkHeartbeat()
    }
  }, THREE_MINUTES)

  // Set modal open if session is expired
  useEffect(() => {
    setOpen(shouldShowModal)
  }, [shouldShowModal, setOpen])

  return (
    <StyledDialog open={open} onClose={logout}>
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          Your current session has expired. Please sign in below using the form
          to continue working, or close this dialog to log out.
        </DialogContentText>
        <LoginFormWrapper>
          <LoginFormContainer />
        </LoginFormWrapper>
      </DialogContent>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  overflow: hidden;
`
const LoginFormWrapper = styled.div`
  padding: 1em 0;

  & > div {
    margin: 0;
  }
`

export default HeartbeatWrapper
