import { StudentCourseStatus } from "../../types/Global-Types"

type StudentCourse = {
  status: StudentCourseStatus
  activeLesson: { id: string } | null
  course: {
    lessons: { id: string; order: number | null }[]
  }
}

export const getCurrentLessonInfo = (studentCourse: StudentCourse) => {
  const lessons = studentCourse.course.lessons.sort((lessonA, lessonB) => {
    if (lessonA.order === null || lessonA.order === undefined) {
      return 1
    }
    if (lessonB.order === null || lessonB.order === undefined) {
      return -1
    }
    return lessonA.order - lessonB.order
  })
  const { activeLesson } = studentCourse
  const curIndex = lessons.findIndex(
    (lesson) => activeLesson && lesson.id === activeLesson.id
  )
  return {
    currentLesson:
      studentCourse.status === "FINISHED" ? lessons.length : curIndex + 1,
    totalLessons: lessons.length,
  }
}
