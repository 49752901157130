import { connect } from "react-redux"
import { RouteChildrenProps, withRouter } from "react-router"

import { isCurrentUserAdmin } from "../../auth/selectors/currentUserGetters"
import { getPathname } from "../../navbar/selectors/getPathname"
import { State } from "../../store"
import Sidebar, { Props } from "../components/Sidebar"
import { isSidebarVisible } from "../operators/isSidebarVisible"

const mapStateToProps = (
  state: State,
  ownProps: Props & RouteChildrenProps
): Partial<Props> => ({
  activeLink: getPathname(state, ownProps),
  isVisible: isSidebarVisible(getPathname(state, ownProps)),
  isAdmin: isCurrentUserAdmin(state),
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar) as any
)
