import { AnyAction, Dispatch } from "redux"
import { createAction } from "redux-actions"

import { routes } from "../../routes/constants/routes"
import { history } from "../../store"
import { TransactionStatus } from "../../types/Global-Types"
import { CHECKOUT_STATUS_CHANGE } from "../constants/ActionTypes"
import { CheckoutPayload, CheckoutStatus } from "./checkout"

const checkoutStatusAction = createAction<CheckoutPayload>(
  CHECKOUT_STATUS_CHANGE
)

export const setPaymentId = (
  referenceId: string,
  status: TransactionStatus
) => (dispatch: Dispatch<AnyAction>) => {
  const actualStatus =
    status === "SUCCESSFUL" ? "SUCCEEDED" : (status as CheckoutStatus)
  dispatch(
    checkoutStatusAction({
      status: actualStatus,
      paymentIntent: { id: referenceId },
    })
  )
  history.push(routes.SHOP.CHECKOUT_RECEIPT)
}
