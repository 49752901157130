import ErrorBus from "../../errors/middleware/ErrorBus"

const serialize = (choices: string[]) => {
  return JSON.stringify(choices.map((choice) => choice.trim()))
}
const deserialize = (questionContent: string | null): string[] => {
  if (!questionContent) {
    return []
  }
  try {
    return JSON.parse(questionContent)
  } catch (err) {
    console.debug(`Content: ${questionContent}`)
    console.error(err)
    ErrorBus.dispatch(
      "There was an error building this question. Please try again or contact support if the issue persists"
    )
    return []
  }
}

export const MultipleChoiceSerializer = {
  serialize,
  deserialize,
}
