import React, { PureComponent } from "react"
import styled from "styled-components"

interface Props {
  activeTab: string
  tabValue: string
  keepMounted?: boolean
}

export default class TabContentWrapper extends PureComponent<Props> {
  render() {
    const { activeTab, tabValue, keepMounted, children } = this.props
    if (keepMounted) {
      return (
        <MountedHidden isActive={activeTab === tabValue}>
          {children}
        </MountedHidden>
      )
    }
    if (activeTab !== tabValue) {
      return null
    }
    return children
  }
}

const MountedHidden = styled.div`
  display: ${({ isActive }: { isActive: boolean }) =>
    isActive ? "block" : "none"};
`
