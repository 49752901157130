import Container, { ContainerProps } from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { Query } from "react-apollo"
import styled from "styled-components"

import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import getStatistics from "../../queries/getStatistics"
import { GetStatistics } from "../../queries/types/GetStatistics"
import StatisticsSection from "./StatisticsSection"

type Props = Pick<ContainerProps, "maxWidth">

const StatisticsWrapper = ({ maxWidth }: Props) => {
  return (
    <Wrapper>
      <Container maxWidth={maxWidth}>
        <Query<GetStatistics> query={getStatistics}>
          {({ loading, data }) => {
            return (
              <LoadingWrapper isLoading={loading && !data}>
                {data?.statistics ? (
                  <StatisticsSection {...data.statistics} />
                ) : (
                  <Typography>Failed to load statistics</Typography>
                )}
              </LoadingWrapper>
            )
          }}
        </Query>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 50px;
  background-color: #fff;
  @media screen and (max-width: 960px) {
    padding: 50px 0;
  }
`

export default StatisticsWrapper
