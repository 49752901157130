import ErrorBus from "../../errors/middleware/ErrorBus"

const regex = /\{\{\s*(.*?)\s*\}\}/gm
const DELIMITER = ";DELIMITER;"

const getAnswers = (questionContent: string): string[] => {
  const matches = []
  let match = regex.exec(questionContent)
  while (match) {
    matches.push(match[1])
    match = regex.exec(questionContent)
  }
  return matches
}

const getContentWithoutAnswers = (questionContent: string): string[] => {
  return questionContent.replace(regex, DELIMITER).split(DELIMITER)
}

const serialize = (answers: string[]) => JSON.stringify(answers)
const deserialize = (answers: string): string[] => {
  try {
    return JSON.parse(answers)
  } catch (err) {
    console.debug(`Answers: ${answers}`)
    console.error(err)
    ErrorBus.dispatch(
      "There was an error building this question. Please try again or contact support if the issue persists"
    )
    return []
  }
}

export const FillInTheBlankSerializer = {
  getAnswers,
  getContentWithoutAnswers,
  serialize,
  deserialize,
}
