import { gql } from "apollo-boost"

export default gql`
  query CurrentGlobalAlerts {
    currentGlobalAlerts {
      id
      message
    }
  }
`
