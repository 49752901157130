import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import { ApolloQueryResult } from "apollo-boost"
import React, { Component, createRef, RefObject } from "react"
import { findDOMNode } from "react-dom"

import { navigateToCertificate } from "../../certificate/operators/navigateToCertificate"
import ErrorBus from "../../errors/middleware/ErrorBus"
import { routes } from "../../routes/constants/routes"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import { history } from "../../store"
import { StudentCourseStatus } from "../../types/Global-Types"
import { finishCourseClient } from "../mutations/helpers/finishCourseClient"
import { updateActiveLesson } from "../mutations/helpers/updateActiveLesson"
import {
  GetMyCourse,
  GetMyCourse_myStudentCourse,
} from "../queries/types/GetMyCourse"
import CourseIntroduction from "./content/CourseIntroduction"
import LessonContent from "./content/LessonContent"
import QuizTaker from "./content/QuizTaker"
import StudentCourseFooter from "./footer/StudentCourseFooter"
import ProgressWrapper from "./progress/ProgressWrapper"
import TimeElapsed from "./progress/TimeElapsed"

type StudentCourse = GetMyCourse_myStudentCourse

interface Props extends WithStyles {
  studentCourse: StudentCourse
  minimumDurationPercentage: number
  shouldPoll: boolean
  refetch: () => Promise<ApolloQueryResult<GetMyCourse>>
  onSaveMessage: (message: string) => void
  onUpdateFailure: () => void
}

interface State {
  activeLessonId: string | null
  canFinishCourse: boolean
  showQuiz: boolean
}

class StudentCourseWrapper extends Component<Props, State> {
  private readonly wrapperRef: RefObject<TitledWrapper>
  private readonly progressRef: RefObject<TimeElapsed>

  constructor(props: Props) {
    super(props)
    this.wrapperRef = createRef()
    this.progressRef = createRef()
    this.state = {
      activeLessonId:
        (props.studentCourse.activeLesson &&
          props.studentCourse.activeLesson.id) ||
        null,
      canFinishCourse:
        props.studentCourse.timeElapsed >= this.getMinimumSecondsRequired(),
      showQuiz: false,
    }
    this.showQuiz = this.showQuiz.bind(this)
    this.hideQuiz = this.hideQuiz.bind(this)
    this.onCourseFinish = this.onCourseFinish.bind(this)
    this.setActiveLesson = this.setActiveLesson.bind(this)
    this.updateActiveLesson = this.updateActiveLesson.bind(this)
  }

  componentDidMount() {
    const { studentCourse } = this.props
    if (studentCourse.status === StudentCourseStatus.NOT_STARTED) {
      this.updateActiveLesson(false)
    }
  }

  componentDidUpdate(_: Props, { activeLessonId: prevLessonId }: State) {
    if (prevLessonId !== this.state.activeLessonId) {
      this.scrollTop()
    }
  }

  componentWillUnmount() {
    this.updateActiveLesson()
  }

  renderCardInnerContent() {
    const { studentCourse, refetch } = this.props
    const { activeLessonId, showQuiz } = this.state
    if (!studentCourse.activeLesson || !activeLessonId) {
      return (
        <CourseIntroduction introduction={studentCourse.course.introduction} />
      )
    }
    const activeLesson = this.getActiveLesson()
    if (!activeLesson) {
      return null
    }
    if (showQuiz) {
      const submission = this.getLessonSubmission()
      const reloadMyCourse = () => refetch()
      return (
        <QuizTaker
          lesson={activeLesson}
          studentCourseId={studentCourse.id}
          lastLessonSubmission={submission}
          refetchCourse={reloadMyCourse}
        />
      )
    }
    const lessonNumber =
      studentCourse.course.lessons.findIndex(
        (lesson) => lesson.id === activeLessonId
      ) + 1
    return <LessonContent lesson={activeLesson} lessonNumber={lessonNumber} />
  }

  renderFooter() {
    const hasCompletedCourse =
      this.props.studentCourse.status === StudentCourseStatus.FINISHED
    const onFinishCourse = () =>
      hasCompletedCourse ? this.viewCourseCertificate() : this.onCourseFinish()
    return (
      <StudentCourseFooter
        currentLessonId={this.state.activeLessonId}
        lessons={this.props.studentCourse.course.lessons}
        hasCompletedCourse={hasCompletedCourse}
        hasRequiredTimeElapsed={this.state.canFinishCourse}
        hasSubmission={Boolean(this.getLessonSubmission())}
        submissionHasErrors={this.submissionHasErrors()}
        showingQuiz={this.state.showQuiz}
        showQuiz={this.showQuiz}
        hideQuiz={this.hideQuiz}
        setActiveLesson={this.setActiveLesson}
        onFinishCourse={onFinishCourse}
      />
    )
  }

  render() {
    const { course } = this.props.studentCourse
    return (
      <TitledWrapper title={course.title} ref={this.wrapperRef}>
        <Grid container style={{ textAlign: "left" }}>
          <Grid item md={3}>
            <ProgressWrapper
              activeLessonId={this.state.activeLessonId}
              shouldPoll={this.props.shouldPoll}
              onSave={(redirect: boolean) =>
                this.updateActiveLesson(true, redirect)
              }
              setActiveLesson={this.setActiveLesson}
              allowCourseFinish={() => this.setState({ canFinishCourse: true })}
              secondsRequired={this.getMinimumSecondsRequired()}
              studentCourse={this.props.studentCourse}
              timeElapsedRef={this.progressRef}
            />
          </Grid>
          <Grid item md={9} style={{ flex: 1 }}>
            <Card elevation={1}>
              <CardContent>{this.renderCardInnerContent()}</CardContent>
              <Divider />
              <CardActions>{this.renderFooter()}</CardActions>
            </Card>
          </Grid>
        </Grid>
      </TitledWrapper>
    )
  }

  private showQuiz() {
    this.setState({
      showQuiz: true,
    })
    this.scrollTop()
  }

  private hideQuiz() {
    this.setState({
      showQuiz: false,
    })
    this.scrollTop()
  }

  private setActiveLesson(lessonId: string | null) {
    this.setState(
      {
        activeLessonId: lessonId,
        showQuiz: false,
      },
      this.updateActiveLesson
    )
  }

  private updateActiveLesson(notify = true, redirect = false) {
    // We don't want to save this in the backend
    if (this.props.studentCourse.status === StudentCourseStatus.FINISHED) {
      return
    }

    const lessonId = this.state.activeLessonId
    updateActiveLesson({
      timeElapsed: this.getTimeElapsed(),
      studentCourseId: this.props.studentCourse.id,
      lessonId,
    })
      .then((data) => {
        if (notify && !redirect) {
          this.props.onSaveMessage("Progress saved")
        }
        if (redirect) {
          history.push(routes.APP.MY_COURSES)
        }
        if (data instanceof Error) {
          throw data
        }
      })
      .catch((err) => {
        this.props.onUpdateFailure()
        console.error(err)
        ErrorBus.dispatch("Error updating the active lesson")
      })
  }

  private getLessonSubmission() {
    const activeLesson = this.getActiveLesson()
    if (!activeLesson) {
      return undefined
    }
    const { lastSubmissions } = this.props.studentCourse
    return (
      (lastSubmissions &&
        lastSubmissions.find((submission) => {
          return Boolean(submission?.lesson.id === activeLesson.id)
        })) ||
      undefined
    )
  }

  private submissionHasErrors(): boolean {
    const submission = this.getLessonSubmission()
    if (!submission) {
      return false
    }
    return submission.answers.some((answer) => !answer.correct)
  }

  private scrollTop() {
    const { current } = this.wrapperRef
    if (current) {
      // eslint-disable-next-line react/no-find-dom-node
      const node = findDOMNode(current) as Element
      if (node && typeof node.scrollIntoView === "function") {
        node.scrollIntoView()
      }
    }
  }

  private onCourseFinish() {
    finishCourseClient(this.props.studentCourse.id, this.getTimeElapsed()).then(
      (succeeded) => {
        if (succeeded) {
          this.props.onSaveMessage("Course completed successfully")
          this.viewCourseCertificate()
        }
      }
    )
  }

  private viewCourseCertificate() {
    navigateToCertificate(this.props.studentCourse.id)
  }

  private getActiveLesson() {
    return this.props.studentCourse.course.lessons.find(
      (lesson) => lesson.id === this.state.activeLessonId
    )
  }

  private getTimeElapsed() {
    if (this.progressRef.current) {
      return this.progressRef.current.getTimeElapsed()
    }
    return undefined
  }

  private getMinimumSecondsRequired(): number {
    const {
      minimumDurationPercentage,
      studentCourse: {
        course: { hours },
      },
    } = this.props
    return hours * 60 * 60 * minimumDurationPercentage
  }
}

export default withStyles({
  actionArea: {
    padding: 16,
  },
  flexOne: {
    flex: 1,
  },
})(StudentCourseWrapper)
