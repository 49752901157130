import { getItem, setItem } from "../../helpers/operators/localStorage"

const SHOWN_FIREFOX_CERTIFICATE_PROMPT = "SHOWN_FIREFOX_CERTIFICATE_PROMPT"

export const getHasShownFirefoxPrompt = (): boolean => {
  return getItem(SHOWN_FIREFOX_CERTIFICATE_PROMPT) === "true"
}

export const setHasShownFirefoxPrompt = () => {
  setItem(SHOWN_FIREFOX_CERTIFICATE_PROMPT, "true")
}
