import { api } from "../../helpers/api/handler"
import { convertToUser } from "../../models/user/serializers/User"

export const login = (email: string, password: string, remember: boolean) =>
  api
    .post<{ user: BE_User }>(
      "/auth/login/",
      JSON.stringify({ email, password, remember })
    )
    .then(({ user }) => convertToUser(user))
