import { graphql } from "react-apollo"
import { RouteComponentProps } from "react-router-dom"

import { getPathParams } from "../../helpers/operators/getPathParams"
import withPageTitle from "../../routes/containers/withPageTitle"
import BlogPostComponent from "../components/BlogPost"
import blogPost from "../queries/blogPost"
import { BlogPost, BlogPostVariables } from "../queries/types/BlogPost"

export default withPageTitle("Blog Post")(
  graphql<RouteComponentProps, BlogPost, BlogPostVariables, any>(blogPost, {
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: { postId: getPathParams(props).id },
    }),
  })(BlogPostComponent)
)
