import AppBar, { AppBarProps } from "@material-ui/core/AppBar"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import Toolbar, { ToolbarProps } from "@material-ui/core/Toolbar"
import MenuIcon from "@material-ui/icons/Menu"
import debounce from "lodash.debounce"
import React from "react"
import { RouteChildrenProps } from "react-router"
import styled from "styled-components"

import { routes } from "../../routes/constants/routes"
import { getTransition } from "../operators/getTransition"
import LinksNav from "./LinksNav"
import LogoHolder from "./LogoHolder"
import ProfileNav from "./ProfileNav"

export interface Props extends RouteChildrenProps {
  loggedInUser: User | null
  navigationLinks: NavigationLink[]
  activeLink: string
  isSidebarVisible: boolean
  logout: () => void
}

interface State {
  scrollTop: number
  width: number
  drawerOpen: boolean
}

class Navbar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      scrollTop: window.scrollY,
      width: window.innerWidth,
      drawerOpen: false,
    }
    this.handleScroll = debounce(this.handleScroll.bind(this), 100, {
      maxWait: 500,
    })
    this.handleResize = debounce(this.handleResize.bind(this), 100, {
      maxWait: 500,
    })
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
    // window.addEventListener("scroll", this.handleScroll)
  }

  componentDidUpdate({ activeLink: prevActiveLink }: Props) {
    // Hide drawer on navigation
    if (this.props.activeLink !== prevActiveLink && this.state.drawerOpen) {
      this.setState({ drawerOpen: false })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    this.setState({ scrollTop: window.scrollY })
  }

  handleResize() {
    const drawerOpen = this.state.drawerOpen && this.isMobile()
    this.setState({ width: window.innerWidth, drawerOpen })
  }

  goToProfile = () => {
    this.props.history.push(routes.AUTH.PROFILE)
  }

  isExtended() {
    return this.state.scrollTop <= 50
  }

  isMobile() {
    return this.state.width <= 960
  }

  renderMobileTray() {
    const { loggedInUser, logout, navigationLinks, activeLink } = this.props
    const onClose = () => this.setState({ drawerOpen: false })
    return (
      <Drawer
        anchor="right"
        open={this.isMobile() && this.state.drawerOpen}
        onClose={onClose}
      >
        <DrawerContainer>
          <ProfileNav
            mobile
            loggedInUser={loggedInUser}
            handleLogout={logout}
            handleProfile={this.goToProfile}
          />
          <Divider style={{ margin: 16 }} />
          <LinksNav
            mobile
            navigationLinks={navigationLinks}
            activeLink={activeLink}
          />
        </DrawerContainer>
      </Drawer>
    )
  }

  renderLogo() {
    const { isSidebarVisible } = this.props
    if (isSidebarVisible) {
      return null
    }
    const shouldExtend = this.isExtended() && !this.isMobile()
    return <LogoHolder absolute={true} extended={shouldExtend} />
  }

  renderTopNavigation() {
    const { loggedInUser, logout, isSidebarVisible } = this.props
    const shouldExtend = this.isExtended() && !this.isMobile()
    return (
      <TopToolbar extended={shouldExtend || (undefined as any)}>
        <ToolbarContainer isSidebarVisible={isSidebarVisible}>
          {this.renderLogo()}
          <Spacer />
          {shouldExtend && (
            <ProfileNav
              loggedInUser={loggedInUser}
              handleLogout={logout}
              handleProfile={this.goToProfile}
            />
          )}
        </ToolbarContainer>
      </TopToolbar>
    )
  }

  renderBottomNavigation() {
    const { navigationLinks, activeLink, isSidebarVisible } = this.props
    return (
      <BottomToolbar>
        <ToolbarContainer isSidebarVisible={isSidebarVisible} justifyEnd>
          {this.isMobile() ? (
            this.renderMenuIcon()
          ) : (
            <LinksNav
              navigationLinks={navigationLinks}
              activeLink={activeLink}
            />
          )}
        </ToolbarContainer>
      </BottomToolbar>
    )
  }

  renderMenuIcon() {
    const onClick = () => this.setState({ drawerOpen: true })
    return (
      <IconButton
        onClick={onClick}
        edge="start"
        color="inherit"
        aria-label="open drawer"
      >
        <MenuIcon />
      </IconButton>
    )
  }

  render() {
    const shouldExtend =
      (this.isExtended() && !this.isMobile()) || (undefined as any)
    return (
      <Wrapper extended={shouldExtend} className="no-print">
        <RootAppBar position="fixed" extended={shouldExtend}>
          {this.renderMobileTray()}
          {this.renderTopNavigation()}
          {this.renderBottomNavigation()}
        </RootAppBar>
      </Wrapper>
    )
  }
}

export const TOP_TOOLBAR = 80
export const BOTTOM_TOOLBAR = 60

interface StyleProps {
  extended: boolean
}
const Wrapper = styled.div`
  flex-grow: 1;
  height: ${({ extended }: StyleProps) =>
    extended ? TOP_TOOLBAR + BOTTOM_TOOLBAR : BOTTOM_TOOLBAR}px;
`
const RootAppBar = styled(({ extended, ...rest }: StyleProps & AppBarProps) => (
  <AppBar {...rest} />
))`
  && {
    height: ${(props) =>
      props.extended ? TOP_TOOLBAR + BOTTOM_TOOLBAR : BOTTOM_TOOLBAR}px;
    transition: ${getTransition("0.25s all")};
    box-shadow: none;
  }
`
const TopToolbar = styled(
  ({ extended, ...rest }: StyleProps & ToolbarProps) => <Toolbar {...rest} />
)`
  && {
    position: relative;
    z-index: 11;
    border-bottom: 1px solid #dfdfdf;
    transition: ${getTransition("0.25s all")};
    min-height: ${(props) => (props.extended ? TOP_TOOLBAR : 0)}px;
    max-height: ${(props) => (props.extended ? TOP_TOOLBAR : 0)}px;
  }
`
const BottomToolbar = styled(Toolbar)`
  && {
    z-index: 10;
    top: 0;
    border-bottom: 1px solid #dfdfdf;
    min-height: ${BOTTOM_TOOLBAR}px;
    max-height: ${BOTTOM_TOOLBAR}px;
    transition: ${getTransition("0.25s all")};
  }
`
interface ContainerProps {
  justifyEnd?: boolean
  isSidebarVisible: boolean
}
const Spacer = styled.div`
  flex-grow: 1;
`
const ToolbarContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  ${({ justifyEnd }: ContainerProps) =>
    justifyEnd && "justify-content: flex-end"};
  ${({ isSidebarVisible }: ContainerProps) =>
    !isSidebarVisible && "max-width: 1320px;"};
`
const DrawerContainer = styled.div`
  padding: 16px;
  text-align: center;
  overflow: hidden;
`

export default Navbar
