import Button from "@material-ui/core/Button"
import React from "react"

interface Props {
  currentLessonId: string | null
  hasSubmission: boolean
  showingQuiz: boolean
  showReturnButton: boolean
  onClick: () => void
}

// Destructring parameters breaks in Edge: https://github.com/microsoft/ChakraCore/issues/2606
const QuizButton = (props: Props) => {
  if (!props.currentLessonId) {
    return null
  }
  if (props.showingQuiz) {
    if (props.showReturnButton) {
      return (
        <Button
          color="primary"
          variant="outlined"
          onClick={() => props.onClick()}
        >
          Return to Lesson
        </Button>
      )
    } else {
      return null
    }
  }
  return (
    <Button color="primary" variant="outlined" onClick={() => props.onClick()}>
      {props.hasSubmission ? "View Quiz Results" : "Take Quiz"}
    </Button>
  )
}

export default QuizButton
