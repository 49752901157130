import Card, { CardProps } from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import { styled } from "@material-ui/styles"
import React, { Component, KeyboardEvent } from "react"

const styles = {
  root: {
    marginTop: 80,
    marginBottom: 80,
  },
  card: {
    maxWidth: 500,
    margin: "0 auto",
    padding: 20,
  },
}

interface Props extends WithStyles {
  maxWidth?: number
  disableEnter?: boolean
  onSubmit?: (event: React.FormEvent) => void
}

class FormHolder extends Component<Props> {
  private readonly card: React.ComponentType<CardProps>
  constructor(props: Props) {
    super(props)

    const { maxWidth } = props
    if (maxWidth) {
      this.card = styled(Card)({
        ...styles.card,
        maxWidth,
      })
    } else {
      this.card = styled(Card)(styles.card)
    }
  }

  render() {
    const { classes, onSubmit } = this.props
    const StyledCard = this.card
    return (
      <div className={classes.root}>
        <StyledCard elevation={1}>
          <CardContent>
            <form
              autoComplete="on"
              onKeyDown={this.handleKeyDown}
              onSubmit={onSubmit}
            >
              {this.props.children}
            </form>
          </CardContent>
        </StyledCard>
      </div>
    )
  }

  private readonly handleKeyDown = (e: KeyboardEvent) => {
    if (this.props.disableEnter && e.keyCode === 13) {
      e.preventDefault()
    }
  }
}

export default withStyles(styles)(FormHolder)
