import { makeStyles, Theme } from "@material-ui/core/styles"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import Tabs, { TabsProps } from "@material-ui/core/Tabs"
import React from "react"

import BreakpointGetter from "../../Theme"

interface StyleProps {
  horizontalWidth?: Breakpoint
}

interface Props extends Omit<TabsProps, "onChange">, StyleProps {
  onChange: (event: any, tabValue: any) => void
}

const VerticalTabs = ({ horizontalWidth, ...rest }: Props) => {
  const classes = useStyles({ horizontalWidth })
  return <Tabs {...rest} classes={classes} />
}

const useStyles = makeStyles<Theme, StyleProps>(
  (theme) => ({
    root: {
      border: "none",
    },
    flexContainer: ({ horizontalWidth }) => ({
      flexDirection: "column",
      [BreakpointGetter.getBreakpoints().down(horizontalWidth || "xs")]: {
        flexDirection: "row",
        "& > button": {
          borderRight: "none!important",
        },
      },
    }),
    indicator: ({ horizontalWidth }) => ({
      display: "none",
      [BreakpointGetter.getBreakpoints().down(horizontalWidth || "xs")]: {
        display: "block",
        backgroundColor: theme.palette.primary.main,
      },
    }),
  }),
  {
    name: "VerticalTabs",
  }
)

export default VerticalTabs
