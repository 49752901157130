import moment from "moment"
import React from "react"

import TotalHoursIcon from "../icons/TotalHoursIcon"
import BaseCard from "./BaseCard"

interface Props {
  body?: string
  startDate?: string
  endDate?: string
}

const replaceDelimiter = (
  body?: string,
  startDate?: string,
  endDate?: string
) => {
  const date = `${moment(startDate).format("LL")} — ${moment(endDate).format(
    "LL"
  )}`
  return body?.replace("{{ cycleRange }}", date)
}

const DateRangeCard = ({ body, startDate, endDate }: Props) => {
  return (
    <BaseCard
      title="Cycle"
      subtitle={replaceDelimiter(body, startDate, endDate)}
    >
      <TotalHoursIcon />
    </BaseCard>
  )
}

export default DateRangeCard
