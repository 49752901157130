import Loadable from "react-loadable"
import Loading from "./Loader"

const loader = () =>
  import(
    /* webpackChunkName: "shop-routes" */ "../routes/components/ShopRoutes"
  ) as Promise<any>

const AsyncShopRoutes = Loadable({
  loader,
  loading: Loading,
})

export default AsyncShopRoutes
