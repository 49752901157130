import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import React from "react"

import RouterLink from "../../../helpers/components/RouterLink"
import { routes } from "../../../routes/constants/routes"
import { StudentCourseStatus } from "../../../types/Global-Types"
import { Dashboard_mostRecentCourses } from "../../queries/types/Dashboard"
import RecentCourseListItem from "./RecentCourseListItem"

interface Props {
  mostRecentCourses: Dashboard_mostRecentCourses[]
}

const getLink = (studentCourseId: string, status: StudentCourseStatus) => {
  switch (status) {
    case StudentCourseStatus.NOT_STARTED:
    case StudentCourseStatus.IN_PROGRESS:
      return routes.APP.COURSE.replace(":id", studentCourseId)
    case StudentCourseStatus.FINISHED:
      return routes.APP.COURSE_CERTIFICATE.replace(":id", studentCourseId)
  }
}

const RecentCourseList = ({ mostRecentCourses }: Props) => {
  return (
    <List>
      {mostRecentCourses.map((studentCourse, index) => {
        const link = getLink(studentCourse.id, studentCourse.status)
        return link ? (
          <RouterLink key={index} to={link}>
            <ListItem divider button>
              <RecentCourseListItem studentCourse={studentCourse} />
            </ListItem>
          </RouterLink>
        ) : (
          <ListItem key={index} divider disabled>
            <RecentCourseListItem studentCourse={studentCourse} />
          </ListItem>
        )
      })}
    </List>
  )
}

export default RecentCourseList
