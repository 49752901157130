import Typography from "@material-ui/core/Typography"
import React from "react"

import RouterLink from "../../../helpers/components/RouterLink"
import ControlledCheckbox from "../../../input/components/v2/core/ControlledCheckbox"
import { BaseControlledProps } from "../../../input/components/v2/core/ControlledProps"
import { routes } from "../../../routes/constants/routes"

const AcceptPolicyCheckbox = (props: BaseControlledProps) => {
  return (
    <ControlledCheckbox {...props} color="primary">
      <Typography align="left">
        I have read and accept the 123 ConEd{" "}
        <RouterLink to={routes.SECONDARY_NAV.POLICIES} target="_blank" blue>
          policies
        </RouterLink>{" "}
        and{" "}
        <RouterLink
          to={routes.SECONDARY_NAV.USER_AGREEMENT}
          target="_blank"
          blue
        >
          user agreement
        </RouterLink>
      </Typography>
    </ControlledCheckbox>
  )
}

export default AcceptPolicyCheckbox
