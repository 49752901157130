import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import PaddedGrid from "../../../helpers/components/PaddedGrid"
import Completions from "./Completions"
import Courses from "./Courses"
import StatisticsNumber from "./StatisticsNumber"
import User from "./User"

interface Props {
  availableCourses: number
  courseCompletions: number
  registeredUsers: number
}

const StatisticsSection = ({
  availableCourses,
  courseCompletions,
  registeredUsers,
}: Props) => {
  return (
    <PaddedGrid>
      <StatItem>
        <Courses />
        <StatText>
          <StatisticsNumber end={availableCourses} />
          <Typography>Available courses</Typography>
        </StatText>
      </StatItem>

      <StatItem>
        <Completions />
        <StatText>
          <StatisticsNumber end={courseCompletions} />
          <Typography>Course completions</Typography>
        </StatText>
      </StatItem>

      <StatItem>
        <User />
        <StatText>
          <StatisticsNumber end={registeredUsers} />
          <Typography>Registered users</Typography>
        </StatText>
      </StatItem>
    </PaddedGrid>
  )
}

const StatItem = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`
const StatText = styled.div`
  margin-left: 24px;
  h2 {
    margin-bottom: -12px;
  }
`

export default StatisticsSection
