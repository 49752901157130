import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

interface Props {
  refetch: () => void
}

const PageError = ({ refetch }: Props) => {
  return (
    <ErrorWrapper className="container">
      <Typography variant="h4" gutterBottom>
        Uh Oh!
      </Typography>
      <Typography gutterBottom>
        We&apos;ve encountered an error while attempting to load this page.
        Please check your network connection and try again.
      </Typography>
      <Button
        onClick={() => refetch()}
        color="primary"
        variant="contained"
        size="large"
      >
        Retry
      </Button>
    </ErrorWrapper>
  )
}

const ErrorWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
`

export default PageError
