import { useTheme } from "@material-ui/core/styles"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import StyledHTML from "../../helpers/components/StyledHTML"

interface Props {
  title: string
  body: string
  listCard: string
}

const Philosophy = ({ title, body, listCard }: Props) => {
  const theme = useTheme()
  return (
    <Wrapper>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card elevation={1}>
            <PaddedCardContent>
              <TypographyHeader
                variant="h3"
                className="underline"
                color="primary"
              >
                {title}
              </TypographyHeader>
              <Typography component="div">
                <StyledHTML html={body} />
              </Typography>
            </PaddedCardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={6} alignItems="flex-start">
        <LeftGrid item md={6}>
          <LeftCard elevation={1} background={theme.palette.primary.main}>
            <PaddedCardContent>
              <TypographyHeader variant="h3">It&apos;s As</TypographyHeader>
              <TypographyHeader variant="h3">Easy As</TypographyHeader>
              <TypographyHeader variant="h1">1. 2. 3.</TypographyHeader>
            </PaddedCardContent>
          </LeftCard>
        </LeftGrid>
        <RightGrid item md={6}>
          <RightCard elevation={1}>
            <PaddedCardContent>
              <Typography className="typography" component="div">
                <StyledHTML html={listCard} />
              </Typography>
            </PaddedCardContent>
          </RightCard>
        </RightGrid>
      </Grid>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  z-index: 1;
`
const TypographyHeader = styled(Typography)`
  font-family: "Quattrocento", sans-serif;
  font-weight: bold;
`
const FullHeightCard = styled(Card)`
  display: flex;
  height: 100%;
  align-items: center;
`
const LeftGrid = styled(Grid)`
  z-index: 1;
  transform: translate(36px, -50px);
  width: 100%;
  @media screen and (max-width: 960px) {
    transform: none;
  }
`
const LeftCard = styled(FullHeightCard)`
  background: ${({ background }: { background: string }) => background};
  color: white;
  border: none;
`
const RightGrid = styled(Grid)`
  width: 100%;
  transform: translateX(-36px);
  @media screen and (max-width: 960px) {
    transform: none;
    margin-bottom: 24px;
  }
`
const RightCard = styled(FullHeightCard)`
  background: #cccccc;
  border: 1px solid #cccccc;
`
const PaddedCardContent = styled(CardContent)`
  padding: 50px !important;
  @media screen and (max-width: 375px) {
    padding: 24px !important;
  }
`

export default Philosophy
