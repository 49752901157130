import { Action, handleActions } from "redux-actions"

import { BuildReportPayload } from "../actions/buildReport"
import { BUILD_REPORT } from "../constants/actionTypes"

export interface ReportState {
  [key: string]: string
}

const defaultState: ReportState = {}

export const reportsReducer = handleActions<ReportState, any>(
  {
    [BUILD_REPORT](state: ReportState, action: Action<BuildReportPayload>) {
      const { taskType, taskId } = action.payload
      return { ...state, [taskType]: taskId }
    },
  },
  defaultState
)
