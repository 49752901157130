import React from "react"
import styled from "styled-components"

import LawIcon from "../../../helpers/components/LawIcon"
import { LAW_COLOR, NON_LAW_COLOR } from "../../../helpers/constants/lawColors"

interface Props {
  isLaw: boolean
}

const Gutter = ({ isLaw }: Props) => {
  return (
    <Wrapper isLaw={isLaw}>
      <ImageWrapper>
        <LawIcon isLaw={isLaw} />
      </ImageWrapper>
    </Wrapper>
  )
}

interface StyleProps {
  isLaw: boolean
}
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  background-color: ${({ isLaw }: StyleProps) =>
    isLaw ? LAW_COLOR : NON_LAW_COLOR};
`
const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  img {
    opacity: 0.2;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
  }
`

export default Gutter
