import { StateWithStatus } from "."

export enum Status {
  UNINITIALIZED = "UNINITIALIZED",
  PENDING = "PENDING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export interface AsyncAction {
  PENDING: string
  SUCCEEDED: string
  FAILED: string
}

export const asyncActionTypes = (actionType: string): AsyncAction => {
  return {
    PENDING: `${actionType}_${Status.PENDING}`,
    SUCCEEDED: `${actionType}_${Status.SUCCEEDED}`,
    FAILED: `${actionType}_${Status.FAILED}`,
  }
}

const handleActionWithStatus = <TState>(
  func?: (state: StateWithStatus, action: any) => TState,
  status?: Status
) => {
  return (state: StateWithStatus, action: any) => {
    state = { ...state, status: status ? status : state.status, error: void 0 }
    return func ? func(state, action) : ((state as any) as TState)
  }
}

export const handleActionPending = <TState>(
  func?: (state: StateWithStatus, action: any) => TState
) => {
  return handleActionWithStatus(func, Status.PENDING)
}

export const handleActionSucceeded = <TState>(
  func?: (state: StateWithStatus, action: any) => TState
) => {
  return handleActionWithStatus(func, Status.SUCCEEDED)
}

export const handleActionFailed = <TState>(
  func?: (state: StateWithStatus, action: any) => TState
) => {
  return handleActionWithStatus(func, Status.FAILED)
}
