import React from "react"

import { ssnValidate } from "../../../constants/validationPatterns"
import { Rules } from "../core/ControlledProps"
import ControlledTextField, {
  ControlledTextFieldProps,
} from "../core/ControlledTextField"

type Props = Omit<ControlledTextFieldProps, "type">

const SSNTextField = ({ rules: propRules, helperText, ...rest }: Props) => {
  const rules: Rules = {
    ...propRules,
    validate: ssnValidate,
  }
  return (
    <ControlledTextField
      label="Last 4 SSN"
      helperText={
        helperText !== undefined
          ? helperText
          : "Required by the Michigan Department of Licensing and Regulatory Affairs"
      }
      {...rest}
      rules={rules}
    />
  )
}

export default SSNTextField
