import React from "react"

import { zipCodeValidate } from "../../../constants/validationPatterns"
import { Rules } from "../core/ControlledProps"
import ControlledTextField, {
  ControlledTextFieldProps,
} from "../core/ControlledTextField"

type Props = Omit<ControlledTextFieldProps, "type">

const ZipCodeField = ({ rules: propRules, ...rest }: Props) => {
  const rules: Rules = {
    ...propRules,
    validate: zipCodeValidate,
  }
  return (
    <ControlledTextField
      autoComplete="postal-code"
      label="Zip Code"
      {...rest}
      rules={rules}
    />
  )
}

export default ZipCodeField
