import IconButton from "@material-ui/core/IconButton"
import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import styled from "styled-components"

import { useInterval } from "../../../input/hooks/useInterval"
import Indicator from "./Indicator"
import TestimonialText from "./TestimonialText"

interface Props {
  testimonials: { author: string; body: string }[]
}

const Testimonials = ({ testimonials }: Props) => {
  const [testimonialIndex, setIndex] = useState(0)
  const flickityRef = useRef<Flickity>()
  useInterval(() => {
    flickityRef.current?.next(true)
  }, 5000)
  const setIndexOnClick = (index: number) => () => {
    flickityRef.current?.selectCell(index, true)
  }
  useEffect(() => {
    const { current } = flickityRef
    current?.on("change", () => {
      setIndex(current.selectedIndex)
    })
  }, [flickityRef])
  return (
    <Wrapper>
      <Flickity
        elementType="div"
        disableImagesLoaded={false}
        flickityRef={(ref) => (flickityRef.current = ref)}
        options={{
          wrapAround: false,
          pageDots: false,
          prevNextButtons: false,
        }}
      >
        {testimonials.map((testimonial, index) => {
          return (
            <TestimonialText
              key={index}
              active={index === testimonialIndex}
              name={testimonial.author}
              quote={testimonial.body}
            />
          )
        })}
      </Flickity>
      <Indicators>
        {testimonials.map((_, index) => {
          return (
            <IconButton key={index} onClick={setIndexOnClick(index)}>
              <Indicator active={index === testimonialIndex} />
            </IconButton>
          )
        })}
      </Indicators>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  div:focus {
    outline: none;
  }
`
const Indicators = styled.div`
  margin-top: 16px;
  text-align: center;
`

export default Testimonials
