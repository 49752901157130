import Card, { CardProps } from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"
import styled from "styled-components"

import LawIcon from "../../../helpers/components/LawIcon"
import RouterLink from "../../../helpers/components/RouterLink"
import { LAW_COLOR, NON_LAW_COLOR } from "../../../helpers/constants/lawColors"
import { routes } from "../../../routes/constants/routes"
import { getFriendlyPrice } from "../../../shop-courses/operators/getFriendlyPrice"
import { GetLatestCourses_availableShopCourses_course } from "../../queries/types/GetLatestCourses"

type Course = GetLatestCourses_availableShopCourses_course

interface Props {
  shopCourseId: string
  course: Course
}

class CourseCard extends Component<Props> {
  render() {
    const { courseType, title, price } = this.props.course
    const isLaw = courseType === "LAW"
    const route = routes.SHOP.COURSE_DETAILS.replace(
      ":id",
      this.props.shopCourseId
    )
    return (
      <RouterLink to={route}>
        <StyledCourseCard elevation={1} isLaw={isLaw}>
          <Gutter>
            <LawIcon isLaw={isLaw} primary />
          </Gutter>
          <CourseCardContent>
            <FullHeightGrid
              container
              direction="column"
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h6" color="primary">
                  <b>{title}</b>
                </Typography>
              </Grid>
              <Grid item>
                <MetaSpan>
                  {getFriendlyPrice(price)} | {this.getHours()}
                </MetaSpan>
              </Grid>
            </FullHeightGrid>
          </CourseCardContent>
        </StyledCourseCard>
      </RouterLink>
    )
  }

  private getHours() {
    const { hours } = this.props.course
    return hours === 1 ? "1 hour" : `${hours} hours`
  }
}

const GUTTER_WIDTH = 80

interface StyledCardProps {
  isLaw: boolean
}

const StyledCourseCard = styled(
  ({ isLaw, ...rest }: CardProps & StyledCardProps) => <Card {...rest} />
)`
  border-width: 2px;
  border-color: ${({ isLaw }: StyledCardProps) =>
    isLaw ? LAW_COLOR : NON_LAW_COLOR};

  overflow: hidden;
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 150px;
  height: 100%;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    border-color: #ccc;
  }
`
const CourseCardContent = styled(CardContent)`
  flex: 1 1 0;
  min-width: 0;
  width: 100%;
  padding: 32px 24px !important;
`
const FullHeightGrid = styled(Grid)`
  height: 100%;
  flex-wrap: nowrap;
`
const Gutter = styled.div`
  width: ${GUTTER_WIDTH}px;
  min-width: ${GUTTER_WIDTH}px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    opacity: 0.25;
  }
`
const MetaSpan = styled(Typography)`
  && {
    font-size: 14px;
  }
`

export default CourseCard
