import React from "react"

type ScrollEventSubscriber = (scrollTop?: number) => void

class ScrollEventBus {
  ref: React.RefObject<HTMLDivElement>

  private subscribers: ScrollEventSubscriber[]

  constructor() {
    this.ref = React.createRef()
    this.subscribers = []
    this.listener = this.listener.bind(this)
  }

  componentDidMount() {
    this.ref.current?.addEventListener("scroll", this.listener)
  }

  componentWillUnmount() {
    this.ref.current?.removeEventListener("scroll", this.listener)
  }

  private listener() {
    this.subscribers.forEach((subscriber) =>
      subscriber(this.ref.current?.scrollTop)
    )
  }

  subscribe(subscriber: ScrollEventSubscriber) {
    this.subscribers.push(subscriber)
  }

  unsubscribe(subscriber: ScrollEventSubscriber) {
    this.subscribers = this.subscribers.filter((s) => s !== subscriber)
  }
}

export default new ScrollEventBus()
