import Button from "@material-ui/core/Button"
import { amber } from "@material-ui/core/colors"
import Typography from "@material-ui/core/Typography"
import React, { useEffect, useState } from "react"
import { ChildDataProps } from "react-apollo"
import styled from "styled-components"

import { hideAlert, isAlertIdHidden } from "../operators/localStorageAlerts"
import { CurrentGlobalAlerts } from "../queries/types/CurrentGlobalAlerts"

type Props = ChildDataProps<unknown, CurrentGlobalAlerts>

const GlobalAlert = ({ data }: Props) => {
  const [alerts, setAlerts] = useState(
    (data.currentGlobalAlerts || []).filter(
      (alert) => !isAlertIdHidden(alert.id)
    )
  )
  useEffect(() => {
    setAlerts(
      (data.currentGlobalAlerts || []).filter(
        (alert) => !isAlertIdHidden(alert.id)
      )
    )
  }, [data.currentGlobalAlerts])
  const firstAlert = alerts.length ? alerts[0] : null
  if (!firstAlert) {
    return null
  }

  const hideFirstAlert = () => {
    if (firstAlert) {
      hideAlert(firstAlert.id)
      setAlerts(alerts.filter((alert) => alert.id !== firstAlert.id))
    }
  }

  return (
    <AlertWrapper>
      <Typography style={{ marginRight: 8 }}>{firstAlert?.message}</Typography>
      <Button variant="outlined" size="small" onClick={hideFirstAlert}>
        X
      </Button>
    </AlertWrapper>
  )
}

const AlertWrapper = styled.div`
  background: ${amber[700]};
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  p {
    flex: 1;
    text-align: center;
  }
`

export default GlobalAlert
