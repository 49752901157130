import { gql } from "apollo-boost"

export default gql`
  query GetStatistics {
    statistics {
      availableCourses
      courseCompletions
      registeredUsers
    }
  }
`
