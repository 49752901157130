import Avatar from "@material-ui/core/Avatar"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"
import styled from "styled-components"

import RouterLink from "../../helpers/components/RouterLink"
import { LAW_COLOR } from "../../helpers/constants/lawColors"
import { routes } from "../../routes/constants/routes"
import { AllBlogPosts_blogPosts } from "../queries/types/AllBlogPosts"

interface Props {
  blogPost: AllBlogPosts_blogPosts
}

const BlogListItem = ({
  blogPost: { id, title, modified, thumbnail },
}: Props) => {
  const blogTitle = <Typography variant="h5">{title}</Typography>
  const date = (
    <Typography component="span" variant="body2" color="textPrimary">
      {moment(modified).format("MMMM D, YYYY")}
    </Typography>
  )
  return (
    <StyledLink to={routes.SECONDARY_NAV.BLOG_POST.replace(":id", id)}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <StyledAvatar
            className="avatar"
            sizes="large"
            alt={title}
            src={thumbnail?.file || undefined}
          >
            {title.slice(0, 1) || "B"}
          </StyledAvatar>
        </ListItemAvatar>
        <ListItemText
          className="blog-text"
          primary={blogTitle}
          secondary={date}
        />
      </ListItem>
    </StyledLink>
  )
}

const StyledAvatar = styled(Avatar)`
  width: 60px !important;
  height: 60px !important;
  margin-right: 16px;
`
const StyledLink = styled(RouterLink)`
  text-decoration: none !important;
  .blog-text {
    color: ${LAW_COLOR};
  }
  &:hover {
    .blog-text {
      text-decoration: underline;
    }
  }
`

export default BlogListItem
