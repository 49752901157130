import Button from "@material-ui/core/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { Component } from "react"

import Dialog from "../../../helpers/components/Dialog"
import RouterLink from "../../../helpers/components/RouterLink"
import { OnSaveProps } from "../../../notifications/containers/withOnSaveMessage"
import { routes } from "../../../routes/constants/routes"
import RequestForm from "./RequestForm"

interface Props extends OnSaveProps {
  isLoading: boolean
  resetFailed: boolean
  resetSucceeded: boolean
  onPasswordReset: (email: string) => any
  resetStatus: () => void
}

interface State {
  showSuccessDialog: boolean
}

class RequestWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showSuccessDialog: false,
    }
  }

  componentDidMount() {
    this.props.resetStatus()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isLoading && !this.props.isLoading) {
      if (!this.props.resetFailed) {
        this.props.onSaveMessage("Request sent successfully")
        this.setState({ showSuccessDialog: true })
      }
    }
  }

  renderSuccessDialog() {
    return (
      <Dialog
        open={this.state.showSuccessDialog}
        onClose={this.closeSuccessDialog}
      >
        <DialogTitle>Reset request sent</DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            Your request to reset the password has been received. An email will
            only be sent to existing users. If you have not received an email in
            your inbox, please check your Spam folder. If you are unable to
            access your account, you can contact us by{" "}
            <RouterLink to={routes.SECONDARY_NAV.CONTACT_US} blue>
              clicking here
            </RouterLink>
            .
          </DialogContentText>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={this.closeSuccessDialog}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    )
  }

  render() {
    const { isLoading, resetFailed } = this.props
    return (
      <>
        <RequestForm
          requestLoading={isLoading}
          requestError={resetFailed}
          onSubmit={this.onReset}
        />
        {this.renderSuccessDialog()}
      </>
    )
  }

  private readonly onReset = ({ email }: { email: string }) => {
    this.props.onPasswordReset(email)
  }

  private readonly closeSuccessDialog = () =>
    this.setState({ showSuccessDialog: false })
}

export default RequestWrapper
