import { createSelector } from "reselect"

import { isLoading } from "../../helpers/selectors"
import { getAuthState } from "./getAuthState"

export const getCurrentUser = createSelector(
  getAuthState,
  (authState) => authState.currentUser
)

export const isCurrentUser = createSelector(
  getCurrentUser,
  (user: User | null) => !!user
)

export const isCurrentUserAdmin = createSelector(
  getCurrentUser,
  (currentUser) => Boolean(currentUser?.isStaff)
)

export const isCurrentUserLoading = createSelector(getAuthState, (auth) =>
  isLoading(auth)
)
