import Container, { ContainerProps } from "@material-ui/core/Container"
import { useTheme } from "@material-ui/core/styles"
import React from "react"
import styled from "styled-components"

import Testimonials from "./Testimonials"

interface Props extends Pick<ContainerProps, "maxWidth"> {
  testimonials: { author: string; body: string }[]
}

const TestimonialsWrapper = ({ maxWidth, testimonials }: Props) => {
  const theme = useTheme()
  return (
    <Wrapper backgroundColor={theme.palette.primary.main}>
      <Container maxWidth={maxWidth}>
        <Testimonials testimonials={testimonials} />
      </Container>
    </Wrapper>
  )
}

interface Themer {
  backgroundColor: string
}
const Wrapper = styled.div`
  color: #fff !important;
  padding: 50px;
  overflow: hidden;
  background-color: ${({ backgroundColor }: Themer) => backgroundColor};
  h4 {
    text-align: right;
  }
  && p {
    color: #fff;
  }
  @media screen and (max-width: 960px) {
    padding: 50px 0;
  }
`

export default TestimonialsWrapper
