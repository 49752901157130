import React from "react"

import { licenseValidate } from "../../../constants/validationPatterns"
import { Rules } from "../core/ControlledProps"
import ControlledTextField, {
  ControlledTextFieldProps,
} from "../core/ControlledTextField"

type Props = Omit<ControlledTextFieldProps, "type">

const LicenseTextField = ({ rules: propRules, helperText, ...rest }: Props) => {
  const rules: Rules = {
    ...propRules,
    validate: licenseValidate,
  }
  return (
    <ControlledTextField
      label="License Number (all 10 numbers)"
      helperText={
        helperText !== undefined
          ? helperText
          : "This is your Michigan real-estate licensing number. Include all 10 digits"
      }
      {...rest}
      rules={rules}
    />
  )
}

export default LicenseTextField
