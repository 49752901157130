import { api } from "../../helpers/api/handler"
import {
  convertToUser,
  convertUserToBE,
} from "../../models/user/serializers/User"
import { buildNotificationError } from "../../notifications/operators/errorBuilder"

export const putProfile = async (profile: User) => {
  return api
    .put<BE_User>("/auth/user/", convertUserToBE(profile))
    .then(convertToUser)
    .then((newUser) => {
      if (profile.impersonating) {
        newUser.impersonating = true
      }
      return newUser
    })
    .catch(
      buildNotificationError(
        "Unable to save profile details. Check that your information is correct. If the error persists, please refresh and try again."
      )
    )
}
