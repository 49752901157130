import Typography from "@material-ui/core/Typography"
import React from "react"

import StyledHTML from "../../../helpers/components/StyledHTML"
import { GetMyCourse_myStudentCourse_course_lessons } from "../../queries/types/GetMyCourse"

interface Props {
  lesson: GetMyCourse_myStudentCourse_course_lessons
  lessonNumber: number
}

const LessonContent = ({ lesson: { title, content }, lessonNumber }: Props) => {
  const titlePrepend = lessonNumber === 0 ? "" : `Lesson ${lessonNumber}: `
  return (
    <div>
      <Typography variant="h6">{titlePrepend + title}</Typography>
      <StyledHTML html={content || ""} />
    </div>
  )
}

export default LessonContent
