import IconButton from "@material-ui/core/IconButton"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import React, { useState } from "react"

import { passwordPhrase } from "../../../../auth/constants/phrases"
import { passwordValidate } from "../../../constants/validationPatterns"
import ControlledTextField, {
  ControlledTextFieldProps,
} from "../core/ControlledTextField"

interface EndAdornmentProps {
  visible: boolean
  setVisible: (visible: boolean) => void
}

// Destructring parameters breaks in Edge: https://github.com/microsoft/ChakraCore/issues/2606
const EndAdornment = (props: EndAdornmentProps) => {
  return (
    <IconButton
      aria-label="toggle password visibility"
      onClick={() => props.setVisible(!props.visible)}
    >
      {props.visible ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  )
}

interface Props extends Omit<ControlledTextFieldProps, "type"> {
  showPhrase?: boolean
}

const PasswordField = ({ rules, showPhrase, helperText, ...rest }: Props) => {
  if (rules && !rules.validate) {
    rules.validate = passwordValidate
  }
  const [visible, setVisible] = useState(false)

  const actualHelperText =
    helperText || (showPhrase ? passwordPhrase : undefined)
  return (
    <ControlledTextField
      type={visible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <EndAdornment visible={visible} setVisible={setVisible} />
        ),
      }}
      rules={rules}
      helperText={actualHelperText}
      {...rest}
    />
  )
}

export default PasswordField
