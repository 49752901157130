import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"
import { Mutation, MutationFunction, MutationResult } from "react-apollo"

import LoadingButton from "../../../auth/components/LoadingButton"
import QuestionTaker from "../../../question/components/QuestionTaker"
import { Question } from "../../../question/types/Question"
import submitQuiz from "../../mutations/submitQuiz"
import {
  SubmitQuiz,
  SubmitQuizVariables,
} from "../../mutations/types/SubmitQuiz"
import {
  GetMyCourse_myStudentCourse_course_lessons,
  GetMyCourse_myStudentCourse_lastSubmissions,
} from "../../queries/types/GetMyCourse"

interface Props extends WithStyles {
  studentCourseId: string
  lesson: GetMyCourse_myStudentCourse_course_lessons
  lastLessonSubmission?: GetMyCourse_myStudentCourse_lastSubmissions
  refetchCourse: () => Promise<any>
}

interface State {
  retake: boolean
  stagedAnswers: {
    [key: string]: string
  }
}

class QuizTaker extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      retake: false,
      stagedAnswers: {},
    }
    this.renderSubmitButton = this.renderSubmitButton.bind(this)
  }

  setStagedAnswer(questionId: string, stagedAnswer: string) {
    const stagedAnswers = {
      ...this.state.stagedAnswers,
      [questionId]: stagedAnswer,
    }
    this.setState({ stagedAnswers })
  }

  submitQuiz(
    submitQuizFunc: MutationFunction<SubmitQuiz, SubmitQuizVariables>
  ) {
    const answers = Object.keys(this.state.stagedAnswers).map((key) => ({
      questionId: key,
      answer: this.state.stagedAnswers[key],
    }))
    submitQuizFunc({
      variables: {
        studentCourseId: this.props.studentCourseId,
        lessonId: this.props.lesson.id,
        answers,
      },
    })
      .then(() => this.props.refetchCourse())
      .then(() => {
        this.setState({ retake: false, stagedAnswers: {} })
      })
  }

  renderQuestions() {
    const { questions } = this.props.lesson
    return questions.map((question, index) => {
      const onAnswerChange = (stagedAnswer: string) => {
        this.setStagedAnswer(question.id, stagedAnswer)
      }
      const disabled = this.showAnswers()
      return (
        <div key={index}>
          <QuestionTaker
            disabled={this.showAnswers()}
            question={question}
            index={index}
            answer={disabled ? this.getAnswer(question) : undefined}
            onAnswerChange={onAnswerChange}
          />
          <Divider className={this.props.classes.questionDivider} />
        </div>
      )
    })
  }

  renderSubmitButton(
    submitQuizFunc: MutationFunction<SubmitQuiz, SubmitQuizVariables>,
    { loading }: MutationResult<SubmitQuiz>
  ) {
    if (this.showAnswers()) {
      if (this.quizAnswerHasError()) {
        return (
          <>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.setState({ retake: true })}
            >
              Retake Quiz
            </Button>
            <Typography color="error" align="center" style={{ marginTop: 8 }}>
              In order to advance, you must correctly answer all questions in
              this quiz.
            </Typography>
          </>
        )
      } else {
        return null
      }
    }

    const errors = this.disableSubmit()
    const errorMessage = errors.join(". ")
    const messageLimit = 100
    const onClick = () => this.submitQuiz(submitQuizFunc)
    const title =
      errorMessage.length > messageLimit
        ? `${errorMessage.substring(0, messageLimit)}...`
        : errorMessage
    return (
      <Tooltip title={title}>
        <div>
          <LoadingButton
            fullWidth
            isLoading={loading}
            variant="contained"
            color="primary"
            size="large"
            onClick={onClick}
            disabled={Boolean(errors.length)}
          >
            Submit Quiz
          </LoadingButton>
        </div>
      </Tooltip>
    )
  }

  render() {
    return (
      <div>
        {this.renderQuestions()}
        <Mutation<SubmitQuiz, SubmitQuizVariables> mutation={submitQuiz}>
          {this.renderSubmitButton}
        </Mutation>
      </div>
    )
  }

  private getAnswer(question: Question) {
    return (
      this.props.lastLessonSubmission &&
      this.props.lastLessonSubmission.answers.find(
        (answer) => answer.question.id === question.id
      )
    )
  }

  private quizAnswerHasError(): boolean {
    const submission = this.props.lastLessonSubmission
    if (!submission) {
      return false
    }
    return submission.answers.some((answer) => !answer.correct)
  }

  private disableSubmit() {
    return this.props.lesson.questions
      .map((question, index) => {
        if (!Object.keys(this.state.stagedAnswers).includes(question.id)) {
          return `Missing answer to question #${index + 1}`
        }
        return null
      })
      .filter(Boolean) as string[]
  }

  private showAnswers() {
    return Boolean(this.props.lastLessonSubmission && !this.state.retake)
  }
}

export default withStyles({
  questionDivider: {
    margin: "16px 0",
  },
})(QuizTaker)
