import { api } from "../../helpers/api/handler"

export interface ErrorMessages {
  newError: string
  confirmError: string
}

export const resetPassword = async (
  newPassword: string,
  confirmNewPassword: string
) => {
  const payload = {
    new_password1: newPassword,
    new_password2: confirmNewPassword,
  }
  return api
    .post("/auth/password/change/", JSON.stringify(payload))
    .catch((err) => {
      if (err?.message) {
        const newError = (err.message["new_password1"] || []).join(" ")
        const confirmError = (err.message["new_password2"] || []).join(" ")
        const messages: ErrorMessages = {
          newError,
          confirmError,
        }
        err["messages"] = messages
      }
      throw err
    })
}
