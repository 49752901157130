import { gql } from "apollo-boost"

export default gql`
  query GetFinishedCourse($studentCourseId: ID!) {
    myStudentCourse(studentCourseId: $studentCourseId) {
      id
      dateCompleted
      course {
        id
        title
        code
        number
        hours
        courseType
      }
    }
  }
`
