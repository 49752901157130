import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"

import LoadingButton from "../../auth/components/LoadingButton"
import Divider from "../../helpers/components/Divider"
import FullwidthGrid from "../../helpers/components/FullwidthGrid"
import StateSelectField from "../../input/components/v2/address/StateSelectField"
import ZipCodeField from "../../input/components/v2/address/ZipCodeField"
import ControlledTextField from "../../input/components/v2/core/ControlledTextField"
import LicenseTextField from "../../input/components/v2/profile/LicenseTextField"
import PhoneNumberField from "../../input/components/v2/profile/PhoneNumberField"
import SSNTextField from "../../input/components/v2/profile/SSNTextField"
import { emailPattern } from "../../input/constants/validationPatterns"

interface Props {
  onSubmit: (user: User) => void
  errors: RestError
  currentUser: User
  requestLoading: boolean
}

type State = User

const ProfileEditorForm = ({
  onSubmit,
  currentUser,
  requestLoading,
  errors: restErrors,
}: Props) => {
  const { handleSubmit, control, errors, trigger } = useForm<State>({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: currentUser,
  })
  const getError = (key: string): string | { [key: string]: string } => {
    let value: any = restErrors
    key.split(".").forEach((k) => {
      if (!value) {
        return ""
      } else if (typeof value === "object") {
        value = value[k]
      }
    })
    return value
  }
  // Highlights form errors on mount
  useEffect(() => {
    trigger()
  }, [trigger])

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Typography variant="h6" align="left" gutterBottom>
        Contact Information
      </Typography>
      <Typography align="left" gutterBottom>
        Your email address is used for login, receipt, and contact purposes.
      </Typography>
      <Grid container>
        <FullwidthGrid item lg={4}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="email"
            autoComplete="username"
            label="Email address"
            rules={{ required: true, pattern: emailPattern }}
            error={Boolean(getError("email"))}
            helperText={getError("email") || undefined}
          />
        </FullwidthGrid>
        <FullwidthGrid item lg>
          <ControlledTextField
            control={control}
            errors={errors}
            name="firstName"
            autoComplete="given-name"
            label="First name"
            rules={{ required: true }}
            error={Boolean(getError("first_name"))}
            helperText={getError("first_name") || undefined}
          />
        </FullwidthGrid>
        <FullwidthGrid item lg>
          <ControlledTextField
            control={control}
            errors={errors}
            autoComplete="family-name"
            label="Last name"
            name="lastName"
            rules={{ required: true }}
            error={Boolean(getError("last_name"))}
            helperText={getError("last_name") || undefined}
          />
        </FullwidthGrid>
        <FullwidthGrid item lg>
          <PhoneNumberField
            control={control}
            errors={errors}
            name="phone"
            rules={{ required: true }}
            error={Boolean(getError("phone"))}
            helperText={getError("phone") || undefined}
          />
        </FullwidthGrid>
      </Grid>

      <Divider />

      <Typography variant="h6" align="left" gutterBottom>
        Address Details
      </Typography>
      <Typography align="left" gutterBottom>
        This information is used to speed up the checkout process for your
        convenience. It is not required.
      </Typography>
      <Grid container>
        <FullwidthGrid item lg={5}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="address.street"
            autoComplete="street-address"
            label="Street"
            error={Boolean(getError("address.street"))}
            helperText={getError("address.street") || undefined}
            rules={{ maxLength: 100 }}
          />
        </FullwidthGrid>
        <FullwidthGrid item lg={3}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="address.city"
            autoComplete="address-level2"
            label="City"
            error={Boolean(getError("address.city"))}
            helperText={getError("address.city") || undefined}
            rules={{ maxLength: 100 }}
          />
        </FullwidthGrid>
        <FullwidthGrid item lg={2}>
          <StateSelectField
            control={control}
            errors={errors}
            name="address.state"
            error={Boolean(getError("address.state"))}
            helperText={getError("address.state") || undefined}
          />
        </FullwidthGrid>
        <FullwidthGrid item lg={2}>
          <ZipCodeField
            control={control}
            errors={errors}
            name="address.zipCode"
            error={Boolean(getError("address.zip_code"))}
            helperText={getError("address.zip_code") || undefined}
          />
        </FullwidthGrid>
      </Grid>

      <Divider />

      <Typography variant="h6" align="left" gutterBottom>
        License Details
      </Typography>
      <Typography align="left" gutterBottom>
        This information is required by Michigan Department of Licensing and
        Regulatory Affairs. Confirm that this information is valid in order to
        receive course credit.
      </Typography>
      <Grid container>
        <FullwidthGrid item lg>
          <LicenseTextField
            control={control}
            errors={errors}
            name="licenseNumber"
            rules={{ required: true }}
            error={Boolean(getError("license_number"))}
            helperText={getError("license_number") || undefined}
          />
        </FullwidthGrid>
        <FullwidthGrid item lg>
          <SSNTextField
            control={control}
            errors={errors}
            name="ssn"
            rules={{ required: true }}
            error={Boolean(getError("ssn"))}
            helperText={getError("ssn") || undefined}
          />
        </FullwidthGrid>
      </Grid>

      <Divider />

      <Grid container justify="flex-start">
        <FullwidthGrid item>
          <LoadingButton
            type="submit"
            isLoading={requestLoading}
            variant="contained"
            color="primary"
            size="large"
          >
            Save
          </LoadingButton>
        </FullwidthGrid>
      </Grid>
    </form>
  )
}

export default ProfileEditorForm
