import { connect } from "react-redux"

import { isLoading } from "../../helpers/selectors"
import { hasFailed } from "../../helpers/selectors/hasFailed"
import withPageTitle from "../../routes/containers/withPageTitle"
import { State } from "../../store"
import { loginUser } from "../actions/loginUser"
import LoginWrapper, { Props } from "../components/login/LoginWrapper"
import { getLoginError } from "../selectors/loginGetters"

const mapStateToProps = (state: State): Partial<Props> => ({
  isLoading: isLoading(state.auth),
  loginFailed: hasFailed(state.auth),
  loginError: getLoginError(state.auth),
})

const mapDispatchToProps = {
  onLogin: loginUser,
}

export default withPageTitle("Login")(
  connect(mapStateToProps, mapDispatchToProps)(LoginWrapper)
)
