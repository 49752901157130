import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"

import RouterLink from "../../../helpers/components/RouterLink"
import { routes } from "../../../routes/constants/routes"
import LoginForm, { State } from "./LoginForm"

export interface Props {
  onLogin: (email: string, password: string, remember: boolean) => void
  isLoading: boolean
  loginFailed: boolean
  loginError?: string
}

class LoginWrapper extends React.Component<Props> {
  renderFooter() {
    return (
      <Grid container>
        <Grid item sm={12}>
          <Typography>
            Need to create an account?{" "}
            <RouterLink to={routes.AUTH.REGISTER} blue>
              Register here
            </RouterLink>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { isLoading, loginError } = this.props

    return (
      <LoginForm
        requestLoading={isLoading}
        requestError={loginError}
        onSubmit={this.login}
      />
    )
  }

  private readonly login = ({ email, password, remember }: State) => {
    const { onLogin } = this.props
    onLogin(email, password, remember)
  }
}

export default LoginWrapper
