import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"

import { isSubscribed } from "../../auth/api/isSubscribed"
import { resubscribe } from "../../auth/api/resubscribe"
import { unsubscribeTokenless } from "../../auth/api/unsubscribeTokenless"
import LoadingButton from "../../auth/components/LoadingButton"
import ErrorBus from "../../errors/middleware/ErrorBus"
import LoadingWrapper from "../../helpers/components/LoadingWrapper"

type Props = unknown
interface State {
  initialLoading: boolean
  loading: boolean
  isSubscribed: boolean
}

class EmailSubscriptions extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      initialLoading: true,
      loading: false,
      isSubscribed: false,
    }
  }

  componentDidMount() {
    isSubscribed()
      .then((subscribed) => {
        this.setState({ isSubscribed: subscribed })
      })
      .catch((error) => {
        ErrorBus.dispatch(error?.message || "Unable to get subscription status")
      })
      .finally(() => {
        this.setState({ initialLoading: false })
      })
  }

  renderStatus() {
    return (
      <Typography gutterBottom style={{ margin: "16px 0" }}>
        Status: {this.state.isSubscribed ? "Subscribed" : "Unsubscribed"}
      </Typography>
    )
  }

  renderButton() {
    return (
      <LoadingButton
        variant="contained"
        isLoading={this.state.loading}
        onClick={this.toggleSubscription}
        color="primary"
      >
        {this.state.isSubscribed ? "Unsubscribe" : "Resubscribe"}
      </LoadingButton>
    )
  }

  render() {
    return (
      <Card elevation={1}>
        <CardContent>
          <Typography align="left" variant="h6" gutterBottom>
            Change Subscription Status
          </Typography>
          <Typography align="left" gutterBottom>
            Click the button below to{" "}
            {this.state.isSubscribed ? "unsubscribe" : "subscribe"}.{" "}
            {this.state.isSubscribed ? "When" : "While"} unsubscribed, you will
            no longer receive information regarding updates to this website and
            other newsletters. For security purposes, emails relating to secure
            account information, such as password resets, will still be
            delivered.
          </Typography>
          <LoadingWrapper isLoading={this.state.initialLoading}>
            {this.renderStatus()}
            {this.renderButton()}
          </LoadingWrapper>
        </CardContent>
      </Card>
    )
  }

  private readonly toggleSubscription = () => {
    const subscribed = this.state.isSubscribed
    const func = subscribed ? unsubscribeTokenless : resubscribe
    func()
      .then(() => {
        this.setState({
          loading: false,
          isSubscribed: !subscribed,
        })
      })
      .catch(() => {
        ErrorBus.dispatch("Unable to set subscription status")
        this.setState({ loading: false })
      })
  }
}

export default EmailSubscriptions
