import { connect } from "react-redux"

import { State } from "../../store"
import HeartbeatWrapper, {
  Props,
} from "../components/heartbeat/HeartbeatWrapper"
import { checkHeartbeat } from "./../actions/checkHeartbeat"
import { logoutUser } from "./../actions/logoutUser"
import {
  isHeartbeatExpired,
  shouldPollHeartbeat,
} from "./../selectors/heartbeatGetters"

const mapStateToProps = (state: State): Partial<Props> => ({
  shouldPoll: shouldPollHeartbeat(state),
  shouldShowModal: isHeartbeatExpired(state),
})

const mapDispatchToProps = {
  checkHeartbeat,
  logout: logoutUser,
}

export default connect<Partial<Props>>(
  mapStateToProps,
  mapDispatchToProps
)(HeartbeatWrapper)
