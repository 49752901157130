import Typography from "@material-ui/core/Typography"
import React from "react"

import StyledHTML from "../../../helpers/components/StyledHTML"

interface Props {
  title: string
  body: string
}

const FAQTitle = ({ title, body }: Props) => {
  return (
    <div>
      <Typography variant="h6" gutterBottom align="left">
        {title}
      </Typography>
      <Typography align="left" component="div">
        <StyledHTML html={body} />
      </Typography>
    </div>
  )
}

export default FAQTitle
