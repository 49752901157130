import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import FontAwesome from "../../helpers/components/FontAwesome"
import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"
import ScrollEventBus from "../../scroll/ScrollEventBus"

interface Props {
  user: User
}

const ICONS = [
  { title: "Our Courses", icon: "fas fa-book", route: routes.SHOP.COURSE_LIST },
  {
    title: "About Us",
    icon: "fas fa-info-circle",
    route: routes.SECONDARY_NAV.ABOUT,
  },
  {
    title: "License Requirements",
    icon: "fas fa-list",
    route: routes.SECONDARY_NAV.REQUIREMENTS,
  },
]

const LOGIN_ICONS = [
  { title: "Login Here", icon: "fas fa-sign-in-alt", route: routes.AUTH.LOGIN },
  {
    title: "Register Now",
    icon: "fas fa-user-plus",
    route: routes.AUTH.REGISTER,
  },
]

const BannerIcons = ({ user }: Props) => {
  const [fixed, setFixed] = useState(false)
  const [startingHeight, setStartingHeight] = useState(0)
  const [top, setTop] = useState(FIXED_TOP)
  const gridRef = useRef<HTMLDivElement>()

  const renderIcons = Boolean(user) ? ICONS : LOGIN_ICONS.concat(ICONS)

  const getElementTop = () =>
    gridRef.current?.getBoundingClientRect()?.top || undefined
  const getElementBottom = () =>
    gridRef.current?.getBoundingClientRect()?.bottom || undefined
  const getParentBottom = () =>
    gridRef.current?.parentElement?.getBoundingClientRect()?.bottom || undefined

  const onScroll = (scrollTop?: number) => {
    if (window.innerWidth <= 960) {
      return
    }

    if (scrollTop !== undefined) {
      setFixed(scrollTop > startingHeight - FIXED_TOP)
    }
    const elementBottom = getElementBottom()
    const parentBottom = getParentBottom()
    if (elementBottom !== undefined && parentBottom !== undefined) {
      const newTop = FIXED_TOP - (window.innerHeight - parentBottom + 20)
      if (elementBottom >= parentBottom - 20) {
        setTop(newTop)
      } else if (top < FIXED_TOP) {
        setTop(Math.min(newTop, FIXED_TOP))
      }
    }
  }

  const onLoad = () => {
    const elementTop = getElementTop()

    if (elementTop !== undefined && startingHeight < elementTop) {
      setStartingHeight(elementTop)
    }
  }

  // Once all page content has loaded, the layout stops shifting and we grab top
  useEffect(() => {
    window.addEventListener("load", onLoad)
    return () => window.removeEventListener("load", onLoad)
  })

  useEffect(() => {
    ScrollEventBus.subscribe(onScroll)
    return () => ScrollEventBus.unsubscribe(onScroll)
  })

  // Falls back to this effect when the content has already loaded
  useEffect(() => {
    const elementTop = getElementTop()
    if (elementTop !== undefined && elementTop > startingHeight) {
      setStartingHeight(elementTop)
    }
  }, [startingHeight, setStartingHeight])

  return (
    <Wrapper>
      <AboveWrapper />
      <Grid
        className={fixed ? "fixed" : ""}
        style={{ top }}
        innerRef={gridRef}
        container
        justify="center"
      >
        {renderIcons.map(({ title, icon, route }, index) => {
          return (
            <Grid item key={index} md="auto">
              <RouterLink key={index} to={route}>
                <IconWrapper>
                  <FontAwesome icon={icon} />
                  <Typography>{title}</Typography>
                </IconWrapper>
              </RouterLink>
            </Grid>
          )
        })}
      </Grid>
    </Wrapper>
  )
}
const AboveWrapper = styled.div`
  width: 100%;
  height: 60px;
  top: -60px;
  position: absolute;
  background: #ddd;
`
const FIXED_TOP = 220
const WIDTH = 260
const Wrapper = styled.div`
  width: ${WIDTH}px;
  height: 100%;
  background: #ddd;
  padding-top: 2em;
  padding-bottom: 2em;
  position: relative;

  &::before {
    content: "";
    background: #ddd;
  }

  @media only screen and (max-width: 960px) {
    width: 100%;
    padding-bottom: 1em;
  }

  @media only screen and (min-width: 961px) {
    min-height: 800px;

    .fixed {
      position: fixed;
      margin: 0;
      width: ${WIDTH}px;
      top: ${FIXED_TOP}px;
    }
  }
`
const IconWrapper = styled.div`
  padding: 2em;
  text-align: center;

  .fa,
  .fas {
    font-size: 40px;
  }

  @media only screen and (max-width: 960px) {
    padding: 1em;
  }
`

export default BannerIcons
