import Button, { ButtonProps } from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import withStyles from "@material-ui/core/styles/withStyles"
import React, { Component, createRef, RefObject } from "react"

interface Props extends ButtonProps {
  isLoading: boolean
}

interface State {
  contentWidth: number
}

class LoadingButton extends Component<Props, State> {
  buttonRef: RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.buttonRef = createRef()
    this.state = {
      contentWidth: 100,
    }
  }

  componentDidMount() {
    this.setContentWidth()
  }

  componentDidUpdate() {
    if (!this.props.isLoading) {
      this.setContentWidth()
    }
  }

  setContentWidth() {
    const { current } = this.buttonRef
    if (current && current.clientWidth !== this.state.contentWidth) {
      this.setState({
        contentWidth: current.clientWidth,
      })
    }
  }

  render() {
    const { isLoading, classes, disabled: propDisabled, ...rest } = this.props
    const disabled = propDisabled === undefined ? isLoading : propDisabled
    return (
      <Button
        innerRef={this.buttonRef}
        style={{
          width: "100%",
          minWidth: isLoading ? this.state.contentWidth : "100px",
        }}
        disabled={disabled}
        {...rest}
      >
        {isLoading ? (
          <div>
            <div className={(classes && classes.root) || ""}>
              <Progress />
            </div>
            &nbsp;
          </div>
        ) : (
          this.props.children
        )}
      </Button>
    )
  }
}

const Progress = withStyles({
  root: {
    color: "#fff",
  },
  svg: {
    color: "#fff",
  },
  circle: {
    color: "#fff",
  },
})(CircularProgress)

export default withStyles({
  root: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
})(LoadingButton as any)
