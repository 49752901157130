import { gql } from "apollo-boost"

export default gql`
  query GetMyCourse($studentCourseId: ID!) {
    myStudentCourse(studentCourseId: $studentCourseId) {
      id
      timeElapsed
      status
      course {
        id
        title
        introduction
        hours
        lessons {
          id
          order
          title
          content
          questions {
            id
            order
            questionType
            description
            content
          }
        }
      }
      lastSubmissions {
        id
        answers {
          id
          question {
            id
            answer
          }
          answer
          correct
        }
        lesson {
          id
        }
      }
      activeLesson {
        id
        order
      }
    }
    minimumDurationPercentage
  }
`
