import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React from "react"

import TitledWrapper from "../../../sidebar/components/TitledWrapper"

interface LinkProps {
  href: string
  title?: string
  children: React.ReactNode
}
const Link = ({ href, title, children }: LinkProps) => (
  <a href={href} title={title} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const Attribution = () => {
  return (
    <div className="container">
      <TitledWrapper title="Third-Party Resources" gapTop showBanner>
        <Card elevation={1}>
          <CardContent>
            <Typography align="justify" gutterBottom>
              This list is a compilation of all third-party resources and
              libraries used to power this website. None of your personal
              information is sold, exchanged, disclosed, or otherwise
              distributed to any of these third-parties. This page exists to
              provide proper attribution to these parties for their work.
            </Typography>

            <Typography variant="h6" align="left" gutterBottom>
              Icons
            </Typography>
            <Typography align="justify" component="div" gutterBottom>
              Icons made from{" "}
              <Link href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </Link>{" "}
              by:
              <ul>
                <li>
                  <Link
                    href="https://www.flaticon.com/authors/iconixar"
                    title="iconixar"
                  >
                    iconixar
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.flaticon.com/authors/prettycons"
                    title="prettycons"
                  >
                    prettycons
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.flaticon.com/authors/freepik"
                    title="Freepik"
                  >
                    Freepik
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.flaticon.com/authors/monkik"
                    title="monkik"
                  >
                    monkik
                  </Link>
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" align="left" gutterBottom>
              Backend Third-Party Libraries and Frameworks (in no order):
            </Typography>
            <Typography align="justify" component="div" gutterBottom>
              <ul>
                <li>
                  <Link href="https://www.djangoproject.com/">
                    Django Project
                  </Link>
                </li>
                <li>
                  <Link href="https://docs.graphene-python.org/projects/django/en/latest/">
                    Graphene-Django
                  </Link>
                </li>
                <li>
                  <Link href="https://www.django-rest-framework.org/">
                    Django REST Framework
                  </Link>
                </li>
                <li>
                  <Link href="https://gunicorn.org/">Gunicorn</Link>
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" align="left" gutterBottom>
              Frontend Third-Party Libraries and Frameworks (in no order):
            </Typography>
            <Typography align="justify" component="div" gutterBottom>
              <ul>
                <li>
                  <Link href="https://reactjs.org/">React.js</Link>
                </li>
                <li>
                  <Link href="https://material-ui.com/">Material-UI</Link>
                </li>
                <li>
                  <Link href="https://momentjs.com/">Moment.js</Link>
                </li>
                <li>
                  <Link href="https://www.apollographql.com/">Apollo</Link>
                </li>
                <li>
                  <Link href="https://stripe.com/">Stripe</Link>
                </li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

export default Attribution
