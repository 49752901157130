import moment from "moment"
import React from "react"

import LawIcon from "../icons/LawIcon"
import BaseCard from "./BaseCard"

interface Props {
  body?: string
  endDate?: string
}

const daysFromNow = (date?: string): number => {
  let endDate = moment(date).year(moment().year()).endOf("day")
  const today = moment()
  if (endDate.isBefore(today)) {
    endDate = endDate.year(today.year() + 1)
  }
  const diff = endDate.diff(today, "days")
  return Math.max(diff, 0)
}

const LegalDaysRemainingCard = ({ body, endDate }: Props) => {
  const daysLeft = daysFromNow(endDate)
  return (
    <BaseCard title={daysLeft} subtitle={body}>
      <LawIcon />
    </BaseCard>
  )
}

export default LegalDaysRemainingCard
