import moment from "moment"
import React from "react"

import CalendarIcon from "../icons/CalendarIcon"
import BaseCard from "./BaseCard"

interface Props {
  body?: string
  endDate?: string
}

const daysFromNow = (date?: string): number => {
  const endDate = moment(date).endOf("day")
  const today = moment()
  const diff = endDate.diff(today, "days")
  return Math.max(diff, 0)
}

const DaysRemainingCard = ({ body, endDate }: Props) => {
  const daysLeft = daysFromNow(endDate)
  return (
    <BaseCard title={daysLeft} subtitle={body}>
      <CalendarIcon />
    </BaseCard>
  )
}

export default DaysRemainingCard
