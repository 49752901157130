import amber from "@material-ui/core/colors/amber"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import { NON_LAW_COLOR } from "../../../helpers/constants/lawColors"
import { TransactionStatus } from "../../../types/Global-Types"

interface Props {
  netTotal: number
  refundTotal: number
  referenceId: string
  status: TransactionStatus
}

const toFriendlyStatus = (status: TransactionStatus, isFullRefund: boolean) => {
  switch (status) {
    case TransactionStatus.SUCCESSFUL:
      return (
        <Typography component="span" style={{ color: NON_LAW_COLOR }}>
          Succeeded
        </Typography>
      )
    case TransactionStatus.PENDING:
      return (
        <Typography component="span" style={{ opacity: 0.5 }}>
          Pending
        </Typography>
      )
    case TransactionStatus.FAILED:
      return (
        <Typography component="span" color="secondary">
          Failed
        </Typography>
      )
    case TransactionStatus.REFUNDED:
      return (
        <Typography component="span" style={{ color: amber[800] }}>
          {isFullRefund ? "Full Refund" : "Partial Refund"}
        </Typography>
      )
    default:
      return <Typography component="span">Unknown</Typography>
  }
}

const MyPurchaseStatus = ({ status, netTotal, refundTotal }: Props) => {
  const isFullRefund = netTotal === 0 && refundTotal > 0
  return <Wrapper>{toFriendlyStatus(status, isFullRefund)}</Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

export default MyPurchaseStatus
