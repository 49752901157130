import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import React, { Component } from "react"

import TabContentWrapper from "../../helpers/components/TabContentWrapper"
import VerticalTab from "../../helpers/components/VerticalTab"
import VerticalTabs from "../../helpers/components/VerticalTabs"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import ChangePasswordContainer from "../containers/ChangePasswordContainer"
import ProfileEditorContainer from "../containers/ProfileEditorContainer"
import EmailSubscriptions from "./EmailSubscriptions"

enum TabValue {
  PROFILE_EDITOR = "PROFILE_EDITOR",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  EMAIL_COMMUNICATIONS = "EMAIL_COMMUNICATIONS",
}

type Props = WithStyles

interface State {
  tab: TabValue
}

class Profile extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { tab: TabValue.PROFILE_EDITOR }
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  getTitle() {
    switch (this.state.tab) {
      case TabValue.PROFILE_EDITOR:
        return "Edit Profile"
      case TabValue.CHANGE_PASSWORD:
        return "Change Password"
      case TabValue.EMAIL_COMMUNICATIONS:
        return "Email Communications"
    }
  }

  handleTabChange(_: unknown, tab: TabValue) {
    this.setState({
      tab,
    })
  }

  render() {
    const {
      classes: { sidebar, sidebarContent },
    } = this.props
    return (
      <TitledWrapper title={this.getTitle()}>
        <Grid container>
          <Grid item>
            <Card className={sidebar} elevation={1}>
              <CardContent className={sidebarContent}>
                <VerticalTabs
                  value={this.state.tab}
                  onChange={this.handleTabChange}
                  horizontalWidth="sm"
                >
                  <VerticalTab
                    label="Profile Editor"
                    value={TabValue.PROFILE_EDITOR}
                  />
                  <VerticalTab
                    label="Change Password"
                    value={TabValue.CHANGE_PASSWORD}
                  />
                  <VerticalTab
                    label="Email Communications"
                    value={TabValue.EMAIL_COMMUNICATIONS}
                  />
                </VerticalTabs>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md>
            <TabContentWrapper
              tabValue={TabValue.PROFILE_EDITOR}
              activeTab={this.state.tab}
            >
              <ProfileEditorContainer />
            </TabContentWrapper>
            <TabContentWrapper
              tabValue={TabValue.CHANGE_PASSWORD}
              activeTab={this.state.tab}
            >
              <ChangePasswordContainer />
            </TabContentWrapper>
            <TabContentWrapper
              tabValue={TabValue.EMAIL_COMMUNICATIONS}
              activeTab={this.state.tab}
            >
              <EmailSubscriptions />
            </TabContentWrapper>
          </Grid>
        </Grid>
      </TitledWrapper>
    )
  }
}

export default withStyles({
  sidebar: {
    minWidth: 200,
  },
  sidebarContent: {
    padding: "8px !important",
  },
})(Profile)
