import Link, { LinkProps } from "@material-ui/core/Link"
import React, { forwardRef } from "react"
import {
  Link as RouterDomLink,
  LinkProps as RLinkProps,
} from "react-router-dom"
import styled from "styled-components"

interface RouterLinkStyles {
  blue?: boolean
}

export type RouterLinkProps = LinkProps & RLinkProps & RouterLinkStyles

export default class RouterLink extends React.Component<RouterLinkProps> {
  render() {
    const Route = forwardRef(
      (props: unknown, ref: React.RefObject<HTMLAnchorElement>) => (
        <RouterDomLink to={this.props.to} {...props} innerRef={ref} />
      )
    )
    Route.displayName = "Route"
    return (
      <StyledLink component={Route} {...this.props}>
        {this.props.children}
      </StyledLink>
    )
  }
}

const StyledLink = styled(({ blue, ...props }) => <Link {...props} />)`
  && {
    color: ${({ blue }: RouterLinkStyles) => (blue ? "#0645AD" : "inherit")};
    text-decoration: ${({ blue }: RouterLinkStyles) =>
      blue ? "underline" : "inherit"};
  }
`
