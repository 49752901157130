import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import FontAwesome from "../../helpers/components/FontAwesome"
import RouterLink, {
  RouterLinkProps,
} from "../../helpers/components/RouterLink"

interface Props {
  text: string
  url: string
  icon?: string
  isActive: boolean
  collapsed?: boolean
}

const SidebarLink = ({
  isActive,
  collapsed = false,
  text,
  url,
  icon,
}: Props) => {
  return (
    <StyledLink to={url} isActive={isActive} collapsed={collapsed}>
      <Tooltip title={text} placement="right" disableHoverListener={!collapsed}>
        <Typography variant="body2">
          <FontAwesomeWrapper collapsed={collapsed}>
            <FontAwesome icon={`fa fas ${icon}`} />
          </FontAwesomeWrapper>
          {collapsed ? null : text}
        </Typography>
      </Tooltip>
    </StyledLink>
  )
}

interface StyleProps {
  isActive?: boolean
  collapsed?: boolean
}

const StyledLink = styled(
  ({ isActive, collapsed, ref, ...rest }: StyleProps & RouterLinkProps) => (
    <RouterLink {...rest} />
  )
)`
  width: 100%;

  && p {
    color: #fff;
    width: 100%;
    padding: 10px;
    opacity: 0.5;
    font-weight: 600;
    font-size: 1rem;
    text-align: ${({ collapsed }: StyleProps) =>
      collapsed ? "center" : "left"};

    &:hover {
      opacity: 1;
    }

    ${({ isActive }: StyleProps) =>
      isActive
        ? `font-weight: 700;
      opacity: 1;
      text-decoration: underline;
    `
        : undefined}
  }
`
const FontAwesomeWrapper = styled.span`
  margin-right: ${({ collapsed }: StyleProps) => (collapsed ? 0 : 16)}px;
  vertical-align: middle;
  width: 24px;
  display: inline-block;
`

export default SidebarLink
