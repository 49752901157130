import { useState } from "react"

import { getItem, setItem } from "../../helpers/operators/localStorage"

const SIDEBAR_COLLAPSED = "SIDEBAR_COLLAPSED"

export const useCollapsed = (): [boolean, (value: boolean) => void] => {
  const initialCollapsedState = getItem(SIDEBAR_COLLAPSED) === "true"
  const [collapsed, setCollapsedState] = useState(initialCollapsedState)

  const setCollapsed = (shouldCollapse: boolean) => {
    setItem(SIDEBAR_COLLAPSED, String(shouldCollapse))
    setCollapsedState(shouldCollapse)
  }

  return [collapsed, setCollapsed]
}
