import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import React, { Component } from "react"

import { MultipleChoiceSerializer } from "../operators/MultipleChoiceSerializer"

interface Props {
  content: string | null
  showAnswer: boolean
  submissionAnswer?: string
  onAnswerChange: (stagedAnswer: string) => void
}

interface State {
  stagedAnswer: string
}

class MultipleChoiceQuestion extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      stagedAnswer: "",
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ stagedAnswer: value }, () =>
      this.props.onAnswerChange(value)
    )
  }

  componentDidUpdate({ showAnswer: prevShowAnswer }: Props) {
    if (this.props.showAnswer && !prevShowAnswer) {
      this.setState({ stagedAnswer: "" })
    }
  }

  render() {
    const disabled = Boolean(this.props.showAnswer)
    const value = disabled
      ? this.props.submissionAnswer
      : this.state.stagedAnswer
    const options = MultipleChoiceSerializer.deserialize(this.props.content)
    return (
      <RadioGroup value={value} onChange={this.handleChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option}
            disabled={disabled}
            value={option}
            control={<Radio color="primary" />}
            label={option}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    )
  }
}

export default MultipleChoiceQuestion
