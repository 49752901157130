import { useTheme } from "@material-ui/core/styles"
import React from "react"
import styled from "styled-components"

const TotalHoursIcon = () => {
  const theme = useTheme()
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="512"
      height="512"
      fill={theme.palette.primary.main}
    >
      <g>
        <g>
          <path
            d="M392.533,0H119.467c-14.114,0-25.6,11.486-25.6,25.6s11.486,25.6,25.6,25.6h273.067c14.114,0,25.6-11.486,25.6-25.6
			S406.647,0,392.533,0z M392.533,34.133H119.467c-4.702,0-8.533-3.823-8.533-8.533c0-4.71,3.831-8.533,8.533-8.533h273.067
			c4.702,0,8.533,3.823,8.533,8.533C401.067,30.31,397.235,34.133,392.533,34.133z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M392.533,460.8H119.467c-14.114,0-25.6,11.486-25.6,25.6s11.486,25.6,25.6,25.6h273.067c14.114,0,25.6-11.486,25.6-25.6
			S406.647,460.8,392.533,460.8z M392.533,494.933H119.467c-4.702,0-8.533-3.823-8.533-8.533s3.831-8.533,8.533-8.533h273.067
			c4.702,0,8.533,3.823,8.533,8.533S397.235,494.933,392.533,494.933z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M316.809,224.324C360.141,180.992,384,123.383,384,62.114V42.667c0-4.719-3.823-8.533-8.533-8.533H136.533
			c-4.71,0-8.533,3.814-8.533,8.533v19.447c0,61.278,23.859,118.878,67.183,162.21l8.576,8.576c6.084,6.084,9.574,14.498,9.574,23.1
			c0,8.602-3.49,17.016-9.566,23.1l-8.576,8.576C151.859,331.008,128,388.608,128,449.886v19.447c0,4.719,3.823,8.533,8.533,8.533
			h238.933c4.71,0,8.533-3.814,8.533-8.533v-19.447c0-61.269-23.859-118.878-67.191-162.21l-8.576-8.576
			c-6.17-6.17-9.566-14.37-9.566-23.1s3.396-16.93,9.566-23.1L316.809,224.324z M296.166,220.834
			C286.771,230.221,281.6,242.705,281.6,256s5.171,25.779,14.566,35.166l8.576,8.576c40.107,40.107,62.191,93.431,62.191,150.144
			V460.8H145.067v-10.914c0-56.721,22.084-110.037,62.182-150.144l8.576-8.576c9.404-9.387,14.575-21.88,14.575-35.166
			s-5.171-25.779-14.566-35.166l-8.576-8.576c-40.107-40.107-62.191-93.432-62.191-150.144V51.2h221.867v10.914
			c0,56.713-22.084,110.037-62.191,150.144L296.166,220.834z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M328.115,143.761c-0.862-2.167-3.797-7.228-12.382-7.228c-44.339,0-64.094-9.498-71.928-15.164
			c-9.771-7.074-22.306-8.926-33.562-4.941c-10.743,3.797-18.611,12.245-21.572,23.168c-0.768,2.842-0.905,4.685-0.905,4.685
			c-0.128,1.681,0.239,3.499,1.067,4.975c7.996,14.174,17.22,29.124,30.498,42.402l8.567,8.576
			c12.621,12.604,19.567,29.38,19.567,47.232c0,4.719,3.823,8.533,8.533,8.533c4.71,0,8.533-3.814,8.533-8.533
			c0-17.843,6.946-34.62,19.558-47.232l8.585-8.585c2.108-2.099,4.855-4.693,7.876-7.526
			C322.611,163.379,331.887,153.284,328.115,143.761z M288.845,171.691c-3.157,2.978-6.042,5.692-8.243,7.893l-8.585,8.585
			c-6.63,6.622-12.006,14.165-16.026,22.34c-4.019-8.175-9.395-15.718-16.026-22.34l-8.568-8.576
			c-10.479-10.487-18.15-22.033-26.129-35.951c2.108-7.125,7.569-10.027,10.667-11.128c5.956-2.108,12.638-1.109,17.86,2.68
			c10.871,7.868,32.307,17.143,73.37,18.287C301.892,159.42,293.615,167.211,288.845,171.691z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M349.278,434.534c-2.142-27.665-13.235-39.151-26.069-52.463c-1.732-1.801-3.541-3.669-5.393-5.649
			c-10.718-11.452-25.856-18.022-41.549-18.022h-40.542c-15.684,0-30.831,6.571-41.549,18.022c-1.852,1.98-3.652,3.84-5.385,5.641
			c-12.843,13.312-23.936,24.806-26.069,52.48c-0.188,2.372,0.631,4.71,2.244,6.46c1.621,1.741,3.883,2.731,6.263,2.731h169.54
			c2.372,0,4.642-0.99,6.263-2.739C348.646,439.245,349.457,436.898,349.278,434.534z M180.949,426.667
			c2.893-14.899,9.788-22.033,20.13-32.751c1.792-1.86,3.652-3.797,5.564-5.837c7.501-8.021,18.099-12.612,29.082-12.612h40.542
			c10.991,0,21.589,4.599,29.09,12.604c1.92,2.065,3.78,3.985,5.581,5.854c10.334,10.718,17.22,17.852,20.122,32.742H180.949z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.744,273.067c-4.71,0-8.533,3.814-8.533,8.533v8.533c0,4.719,3.823,8.533,8.533,8.533c4.71,0,8.533-3.814,8.533-8.533
			V281.6C264.277,276.881,260.454,273.067,255.744,273.067z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M255.744,315.733c-4.71,0-8.533,3.814-8.533,8.533v8.533c0,4.719,3.823,8.533,8.533,8.533c4.71,0,8.533-3.814,8.533-8.533
			v-8.533C264.277,319.548,260.454,315.733,255.744,315.733z"
          />
        </g>
      </g>
    </Wrapper>
  )
}

const Wrapper = styled.svg`
  height: 100px;
  width: 100px;
`

export default TotalHoursIcon
