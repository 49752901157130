/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum CourseCourseType {
  LAW = "LAW",
  NON_LAW = "NON_LAW",
}

/**
 * An enumeration.
 */
export enum CourseExpirationStatus {
  ACTIVE = "ACTIVE",
  HIDDEN = "HIDDEN",
  PENDING = "PENDING",
}

/**
 * An enumeration.
 */
export enum DiscountExpirationStatus {
  ACTIVE = "ACTIVE",
  HIDDEN = "HIDDEN",
  PENDING = "PENDING",
}

/**
 * An enumeration.
 */
export enum DiscountUseType {
  LIMITED_USE = "LIMITED_USE",
  MULTIPLE_USE = "MULTIPLE_USE",
  SINGLE_USE = "SINGLE_USE",
}

export enum EmailEnum {
  ADMIN = "ADMIN",
  BILLING = "BILLING",
  DEFAULT = "DEFAULT",
}

/**
 * An enumeration.
 */
export enum IPGroupType {
  LOCATION = "LOCATION",
  RANGE = "RANGE",
}

/**
 * An enumeration.
 */
export enum QuestionQuestionType {
  FILL_IN_BLANK = "FILL_IN_BLANK",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  SHORT_ANSWER = "SHORT_ANSWER",
  TRUE_FALSE = "TRUE_FALSE",
}

/**
 * An enumeration.
 */
export enum RefundReason {
  DUPLICATE = "DUPLICATE",
  FRAUDULENT = "FRAUDULENT",
  REQUESTED_BY_CUSTOMER = "REQUESTED_BY_CUSTOMER",
}

/**
 * An enumeration.
 */
export enum RuleAction {
  A = "A",
  D = "D",
}

/**
 * An enumeration.
 */
export enum StudentCourseStatus {
  EXPIRED = "EXPIRED",
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_STARTED = "NOT_STARTED",
}

/**
 * An enumeration.
 */
export enum TaskResultStatus {
  FAILURE = "FAILURE",
  PENDING = "PENDING",
  RECEIVED = "RECEIVED",
  RETRY = "RETRY",
  REVOKED = "REVOKED",
  STARTED = "STARTED",
  SUCCESS = "SUCCESS",
}

/**
 * An enumeration.
 */
export enum TransactionStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  REFUNDED = "REFUNDED",
  SUCCESSFUL = "SUCCESSFUL",
}

export interface AboutPageMutationInput {
  body: string;
  clientMutationId?: string | null;
}

export interface AddressSerializerInput {
  id?: number | null;
  street?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
}

export interface BlogPostMutationInput {
  id?: number | null;
  title: string;
  content?: string | null;
  thumbnailId?: string | null;
  headerImageId?: string | null;
  authorId?: string | null;
  clientMutationId?: string | null;
}

export interface BulkLessonOrderInput {
  lessonId: string;
  order: number;
}

export interface BulkPricingMutationInput {
  id?: number | null;
  hourOne?: number | null;
  hourTwo?: number | null;
  hourThree?: number | null;
  hourFour?: number | null;
  hourFive?: number | null;
  hourSix?: number | null;
  clientMutationId?: string | null;
}

export interface BulkQuestionOrderInput {
  questionId: string;
  order: number;
}

export interface ContactPageMutationInput {
  body: string;
  clientMutationId?: string | null;
}

export interface ContactSubmissionInputType {
  name: string;
  email: string;
  message: string;
  captchaToken: string;
}

export interface CourseIntroductionMutationInput {
  id?: number | null;
  introduction?: string | null;
  clientMutationId?: string | null;
}

export interface CourseMutationInput {
  id?: number | null;
  title?: string | null;
  number?: string | null;
  code?: string | null;
  classCode?: string | null;
  courseType?: string | null;
  hours: number;
  price: number;
  defaultPrice?: boolean | null;
  approvalId?: string | null;
  expirationDate?: any | null;
  expirationStatus?: string | null;
  lessons?: string | null;
  clientMutationId?: string | null;
}

export interface DashboardPageMutationInput {
  calendarDaysText: string;
  cycleDaysText: string;
  cycleRangeText: string;
  showCycleStats?: boolean | null;
  zeroStateBody: string;
  clientMutationId?: string | null;
}

export interface DiscountMutationInput {
  id?: number | null;
  code: string;
  percentage: number;
  useType: string;
  numberUses: number;
  maxUses: number;
  expirationDate?: any | null;
  expirationStatus?: string | null;
  clientMutationId?: string | null;
}

export interface FAQGroupSerializerInput {
  id?: number | null;
  name: string;
  content?: string | null;
  order: number;
}

export interface FAQPageMutationInput {
  title: string;
  body?: string | null;
  groups: (FAQGroupSerializerInput | null)[];
  clientMutationId?: string | null;
}

export interface FooterMutationInput {
  columnOne?: any | null;
  columnTwo?: any | null;
  columnThree: string;
  clientMutationId?: string | null;
}

export interface GlobalAlertsMutationInput {
  id?: number | null;
  message: string;
  startDate: any;
  endDate: any;
  clientMutationId?: string | null;
}

export interface HomePageMutationInput {
  heroTitle: string;
  heroSubtitle: string;
  philosophyTitle?: string | null;
  philosophyBody?: string | null;
  philosophyListCard?: string | null;
  michiganTitle?: string | null;
  michiganBody?: string | null;
  testimonials: (TestimonialSerializerInput | null)[];
  clientMutationId?: string | null;
}

export interface IPGroupMutationInput {
  id?: number | null;
  name: string;
  description?: string | null;
  type?: string | null;
  countryCodes?: (string | null)[] | null;
  ranges?: (IPRangeSerializerInput | null)[] | null;
  clientMutationId?: string | null;
}

export interface IPRangeSerializerInput {
  id?: number | null;
  description?: string | null;
  firstIp: string;
  lastIp?: string | null;
}

export interface LessonMutationInput {
  id?: number | null;
  courseId: string;
  title?: string | null;
  order: number;
  content?: string | null;
  clientMutationId?: string | null;
}

export interface LicenseRequirementsMutationInput {
  id?: number | null;
  cycleName: string;
  startDate: any;
  endDate: any;
  totalHoursRequirement: number;
  yearlyHoursRequirement: number;
  clientMutationId?: string | null;
}

export interface LicenseRequirementsPageMutationInput {
  body: string;
  clientMutationId?: string | null;
}

export interface PrivacyPolicyPageMutationInput {
  body: string;
  clientMutationId?: string | null;
}

export interface QuestionAnswerType {
  questionId: string;
  answer: string;
}

export interface QuestionMutationInput {
  id?: number | null;
  lessonId: string;
  questionType: string;
  order: number;
  description?: string | null;
  content?: string | null;
  answer?: string | null;
  clientMutationId?: string | null;
}

export interface RuleMutationInput {
  id?: number | null;
  urlPattern: string;
  ipGroup?: string | null;
  action?: string | null;
  rank?: number | null;
  clientMutationId?: string | null;
}

export interface SettingsMutationInput {
  id?: number | null;
  minimumDurationPercentage?: number | null;
  adminRecipients?: string | null;
  clientMutationId?: string | null;
}

export interface ShopCourseMutationInput {
  id?: number | null;
  courseId: string;
  description?: string | null;
  pinned?: boolean | null;
  homePinned?: boolean | null;
  clientMutationId?: string | null;
}

export interface ShopPageMutationInput {
  title: string;
  subtitle: string;
  description: string;
  boxOne: string;
  boxTwo: string;
  boxThree: string;
  blurbTitle: string;
  blurbContent: string;
  clientMutationId?: string | null;
}

export interface StudentCourseMutationInput {
  id?: number | null;
  userId: string;
  courseId: string;
  timeElapsed?: number | null;
  dateStarted?: any | null;
  dateCompleted?: any | null;
  status?: string | null;
  activeLesson?: string | null;
  clientMutationId?: string | null;
}

export interface StudentMutationInput {
  id?: number | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  isStaff?: boolean | null;
  address?: AddressSerializerInput | null;
  phone?: string | null;
  dob?: any | null;
  licenseNumber?: string | null;
  ssn?: string | null;
  referralEmail?: string | null;
  clientMutationId?: string | null;
}

export interface TestimonialSerializerInput {
  id?: number | null;
  author?: string | null;
  body?: string | null;
}

export interface UserAgreementPageMutationInput {
  body: string;
  clientMutationId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
