import { createAsyncAction } from "../../helpers/actions"
import { register } from "../api/register"
import { REGISTER_USER } from "../constants/ActionTypes"

export interface RegisterPayload {
  email: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
}

export const registerUser = createAsyncAction<User, [RegisterPayload]>(
  REGISTER_USER,
  register
)
