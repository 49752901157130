import React from "react"
import Helmet from "react-helmet"

const withPageTitle = (pageTitle: string, description?: string) => <
  T extends unknown
>(
  WrappedComponent: React.ComponentType<T>
) => {
  class PageTitleComponent extends React.Component<T> {
    componentDidMount() {
      this.checkAndRemoveDescription()
    }

    render() {
      return (
        <>
          <Helmet>
            <title>123 ConEd - {pageTitle}</title>
            {description && <meta name="description" content={description} />}
          </Helmet>
          <WrappedComponent {...this.props} />
        </>
      )
    }

    private checkAndRemoveDescription() {
      const descriptionMeta = document.querySelector("[name='description']")
      if (descriptionMeta) {
        const isHelmet = Boolean(
          descriptionMeta.attributes.getNamedItem("data-react-helmet")
        )
        if (!isHelmet) {
          descriptionMeta.remove()
        }
      }
    }
  }
  return PageTitleComponent
}

export default withPageTitle
