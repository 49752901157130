import { gql } from "apollo-boost"

export default gql`
  query AvailableShopCourses {
    availableShopCourses {
      id
      owned
      courseCodeOwned
      pinned
      course {
        id
        number
        code
        title
        hours
        price
        courseType
        expirationStatus
      }
    }
  }
`
