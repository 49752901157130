import { Middleware } from "redux"

import { routes } from "../../../routes/constants/routes"
import { history } from "../../../store"
import { ADD_STUDENT, SET_ACTIVE_STUDENT } from "../constants/ActionTypes"

export const handleStudentRedirects: Middleware = () => (next) => (action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_STUDENT:
      history.push(routes.ADMIN_NAV.STUDENTS.EDITOR)
      break
    case SET_ACTIVE_STUDENT:
      if (payload) {
        const route = routes.ADMIN_NAV.STUDENTS.EDITOR_ACTIVE.replace(
          ":id",
          payload
        )
        history.push(route)
      } else {
        history.push(routes.ADMIN_NAV.STUDENTS.EDITOR)
      }
      break
  }
  return next(action)
}
