import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { useState } from "react"

import Dialog from "../../helpers/components/Dialog"
import {
  getHasShownFirefoxPrompt,
  setHasShownFirefoxPrompt,
} from "../operators/hasShownFirefoxPrompt"

interface Props {
  href: string
}

const ViewCertificateButton = ({ href }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleClick = (e: React.MouseEvent) => {
    const isFirefox = window.navigator.userAgent.includes("Firefox")
    if (isFirefox) {
      if (!getHasShownFirefoxPrompt()) {
        e.preventDefault()
        setDialogOpen(true)
      }
    }
  }
  const onContinue = () => {
    setDialogOpen(false)
    setHasShownFirefoxPrompt()
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        href={href}
        onClick={handleClick}
        target="_blank"
        style={{ marginRight: 8 }}
      >
        View Certificate
      </Button>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you are using a Firefox browser, saving generated PDF documents
            using the keyboard shortcut (CTRL+S or CMD+S) is unsupported since
            it performs an insecure request to access this data. Instead, please
            use the save button on this page or the save button in the toolbar
            on the following page. This message will no longer display once you
            press continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            href={href}
            onClick={onContinue}
            target="_blank"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ViewCertificateButton
