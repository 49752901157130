import Grid from "@material-ui/core/Grid"
import React from "react"
import styled from "styled-components"

import { GetMyCourse_myStudentCourse_course_lessons } from "../../queries/types/GetMyCourse"
import NextButton from "./NextButton"
import PreviousButton from "./PreviousButton"
import QuizButton from "./QuizButton"

type Lesson = GetMyCourse_myStudentCourse_course_lessons

interface Props {
  currentLessonId: string | null
  lessons: Lesson[]
  hasCompletedCourse: boolean
  hasRequiredTimeElapsed: boolean
  hasSubmission: boolean
  onFinishCourse: () => void
  setActiveLesson: (lessonId: Lesson["id"] | null) => void
  showingQuiz: boolean
  showQuiz: () => void
  hideQuiz: () => void
  submissionHasErrors: boolean
}

const getNeighborLessonId = (
  previous: boolean,
  currentLessonId: string | null,
  lessons: Lesson[]
) => {
  const currentLessonOrder = lessons.find(
    (lesson) => lesson.id === currentLessonId
  )?.order
  // If we are looking at the introduction
  if (currentLessonOrder === null || currentLessonOrder === undefined) {
    if (previous || lessons.length === 0) {
      return undefined
    }
    return lessons[0].id
  }
  // If we are at the beginning or the end of the lessons
  if (
    (currentLessonOrder === 0 && previous) ||
    (currentLessonOrder === lessons.length - 1 && !previous)
  ) {
    return undefined
  }
  const nextOrder = previous ? currentLessonOrder - 1 : currentLessonOrder + 1
  return lessons.find((lesson) => lesson.order === nextOrder)?.id
}

const StudentCourseFooter = ({
  currentLessonId,
  lessons,
  hasCompletedCourse,
  hasRequiredTimeElapsed,
  hasSubmission,
  onFinishCourse,
  setActiveLesson,
  showingQuiz,
  showQuiz,
  hideQuiz,
  submissionHasErrors,
}: Props) => {
  const prevNeighborId = getNeighborLessonId(true, currentLessonId, lessons)
  const nextNeighborId = getNeighborLessonId(false, currentLessonId, lessons)
  return (
    <ActionGrid container justify="space-between" alignContent="center">
      <Grid item>
        <PreviousButton
          currentLessonId={currentLessonId}
          previousLessonId={prevNeighborId}
          onClick={setActiveLesson}
        />
      </Grid>
      <FlexGrid item container justify="flex-end">
        <Grid item>
          <QuizButton
            showReturnButton={Boolean(
              !nextNeighborId && !hasCompletedCourse && !hasRequiredTimeElapsed
            )}
            currentLessonId={currentLessonId}
            onClick={showingQuiz ? hideQuiz : showQuiz}
            showingQuiz={showingQuiz}
            hasSubmission={hasSubmission}
          />
        </Grid>
        <Grid item>
          <NextButton
            currentLessonId={currentLessonId}
            nextLessonId={nextNeighborId}
            onClick={setActiveLesson}
            onFinish={onFinishCourse}
            hasCompletedCourse={hasCompletedCourse}
            hasRequiredTimeElapsed={hasRequiredTimeElapsed}
            hasSuccessfullyCompletedQuiz={hasSubmission && !submissionHasErrors}
          />
        </Grid>
      </FlexGrid>
    </ActionGrid>
  )
}

const ActionGrid = styled(Grid)`
  padding: 16px;
`
const FlexGrid = styled(Grid)`
  flex: 1;
`

export default StudentCourseFooter
