import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import React, { Component } from "react"
import { Query, QueryResult } from "react-apollo"
import { RouteComponentProps } from "react-router"

import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import { getPathParams } from "../../helpers/operators/getPathParams"
import { OnSaveProps } from "../../notifications/containers/withOnSaveMessage"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import getStudentCourse from "../queries/getStudentCourse"
import { GetMyCourse, GetMyCourseVariables } from "../queries/types/GetMyCourse"
import StudentCourseWrapper from "./StudentCourseWrapper"

interface Props extends RouteComponentProps, OnSaveProps {
  shouldPoll: boolean
  checkHeartbeat(): void
}

class CourseFetcher extends Component<Props> {
  constructor(props: Props) {
    super(props)
    this.renderQueryData = this.renderQueryData.bind(this)
  }

  getStudentCourseId(): string | null {
    const { id } = getPathParams(this.props)
    return id ? id : null
  }

  renderErrorState(message?: string) {
    const errorMessage =
      message || `Course ID ${getPathParams(this.props).id} is invalid`
    return (
      <TitledWrapper title="">
        <Card elevation={1}>
          <CardContent>{errorMessage}</CardContent>
        </Card>
      </TitledWrapper>
    )
  }

  renderQueryData({
    data,
    loading,
    error,
    refetch,
  }: QueryResult<GetMyCourse, GetMyCourseVariables>) {
    if (error) {
      this.props.checkHeartbeat()
      const errors =
        error.graphQLErrors.map((e) => e.message).join(". ") || error.message
      return this.renderErrorState(errors)
    }
    return (
      <LoadingWrapper isLoading={loading}>
        {data?.myStudentCourse ? (
          <StudentCourseWrapper
            shouldPoll={this.props.shouldPoll}
            minimumDurationPercentage={data.minimumDurationPercentage}
            studentCourse={data.myStudentCourse}
            onSaveMessage={this.props.onSaveMessage}
            onUpdateFailure={this.props.checkHeartbeat}
            refetch={refetch}
          />
        ) : (
          this.renderErrorState("Unable to load course information")
        )}
      </LoadingWrapper>
    )
  }

  render() {
    const studentCourseId = this.getStudentCourseId()
    if (!studentCourseId) {
      return this.renderErrorState()
    }
    return (
      <Query<GetMyCourse, GetMyCourseVariables>
        query={getStudentCourse}
        variables={{ studentCourseId }}
      >
        {this.renderQueryData}
      </Query>
    )
  }
}

export default CourseFetcher
