import Button from "@material-ui/core/Button"
import Grid, { GridProps } from "@material-ui/core/Grid"
import React from "react"
import styled from "styled-components"

import CartButtonContainer from "../../cart/containers/CartButtonContainer"
import FontAwesome from "../../helpers/components/FontAwesome"
import FullwidthGrid from "../../helpers/components/FullwidthGrid"
import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"
import ProfileDropdown from "./ProfileDropdown"

interface Props {
  mobile?: boolean
  loggedInUser: User | null
  handleLogout: () => void
  handleProfile: () => void
}

class ProfileNav extends React.PureComponent<Props> {
  renderLoginButton() {
    const { mobile } = this.props
    const lg = mobile && 12
    const FWG = mobile ? FullwidthGrid : Grid
    return (
      <>
        <FWG item lg={lg}>
          <RouterLink to={routes.AUTH.LOGIN} underline="none">
            <NoWrapButton variant="contained" color="primary" fullWidth>
              <FontAwesome icon="fas fa-sign-in-alt" gapRight />
              Sign In
            </NoWrapButton>
          </RouterLink>
        </FWG>
        <FWG item lg={lg}>
          <RouterLink to={routes.AUTH.REGISTER} underline="none">
            <NoWrapButton variant="outlined" color="primary" fullWidth>
              <FontAwesome icon="fas fa-user-plus" gapRight />
              Register
            </NoWrapButton>
          </RouterLink>
        </FWG>
      </>
    )
  }

  renderMyCoursesButton() {
    const { mobile } = this.props
    return (
      <RouterLink to={routes.APP.MY_COURSES} underline="none">
        <NoWrapButton fullWidth={mobile}>
          <FontAwesome icon="fas fa-book" gapRight />
          My Courses
        </NoWrapButton>
      </RouterLink>
    )
  }

  renderAdminDashboardButton() {
    const { mobile } = this.props
    return (
      <RouterLink to={routes.ADMIN_NAV.HOME} underline="none">
        <NoWrapButton fullWidth={mobile}>
          <FontAwesome icon="fas fa-tachometer-alt" gapRight />
          Admin Dashboard
        </NoWrapButton>
      </RouterLink>
    )
  }

  renderProfileButton(loggedInUser: User) {
    const { handleLogout, handleProfile, mobile } = this.props
    const xs = mobile && 12
    return (
      <>
        <Grid item xs={xs}>
          {loggedInUser.isStaff
            ? this.renderAdminDashboardButton()
            : this.renderMyCoursesButton()}
        </Grid>
        <Grid item xs={xs}>
          <ProfileDropdown
            fullWidth={mobile}
            user={loggedInUser}
            logout={handleLogout}
            goToProfile={handleProfile}
          />
        </Grid>
      </>
    )
  }

  render() {
    const { loggedInUser, mobile } = this.props
    return (
      <div>
        <Grid
          container
          spacing={2}
          alignItems="center"
          wrap={mobile ? "wrap" : "nowrap"}
        >
          <CartButtonWrapper item fullWidth={mobile}>
            <CartButtonContainer disabled={!loggedInUser} fullWidth />
          </CartButtonWrapper>
          {loggedInUser
            ? this.renderProfileButton(loggedInUser)
            : this.renderLoginButton()}
        </Grid>
      </div>
    )
  }
}

interface StyleProps extends GridProps {
  fullWidth?: boolean
}
const CartButtonWrapper = styled(({ fullWidth, ...rest }) => (
  <Grid {...rest} />
))`
  margin-right: 8px;
  ${({ fullWidth }: StyleProps) => fullWidth && "width: 100%"}
`
const NoWrapButton = styled(Button)`
  white-space: nowrap;
`

export default ProfileNav
