import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import React, { Component } from "react"
import { RouteComponentProps } from "react-router-dom"

import ErrorBus from "../../../errors/middleware/ErrorBus"
import { getPathParams } from "../../../helpers/operators/getPathParams"
import TitledWrapper from "../../../sidebar/components/TitledWrapper"
import { unsubscribe } from "../../api/unsubscribe"
import UnsubscribeBody from "./UnsubscribeBody"

type Props = RouteComponentProps

interface State {
  loading: boolean
}

class Unsubscribe extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    const { token } = getPathParams(this.props)
    this.setState({ loading: true })
    unsubscribe(token)
      .catch((error) =>
        ErrorBus.dispatch(error?.message || "Unable to unsubscribe")
      )
      .finally(() => this.setState({ loading: false }))
  }

  render() {
    return (
      <div className="container">
        <TitledWrapper title="Unsubscribe from Email Communications" showBanner>
          <Card>
            <CardContent>
              <UnsubscribeBody loading={this.state.loading} />
            </CardContent>
          </Card>
        </TitledWrapper>
      </div>
    )
  }
}

export default Unsubscribe
