import Button from "@material-ui/core/Button"
import React from "react"

import { StudentCourseStatus } from "../../../types/Global-Types"

interface Props {
  status: StudentCourseStatus
}

const getText = (status: StudentCourseStatus) => {
  switch (status) {
    case StudentCourseStatus.NOT_STARTED:
      return "Start Course"
    case StudentCourseStatus.IN_PROGRESS:
      return "Resume Course"
    case StudentCourseStatus.FINISHED:
      return "View Certificate"
    case StudentCourseStatus.EXPIRED:
      return "Expired"
  }
}

const RecentCourseListItem = ({ status }: Props) => {
  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      disabled={status === StudentCourseStatus.EXPIRED}
    >
      {getText(status)}
    </Button>
  )
}

export default RecentCourseListItem
