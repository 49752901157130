import { Middleware } from "redux"

import { routes } from "../../../routes/constants/routes"
import { history } from "../../../store"
import { ADD_DISCOUNT, SELECT_DISCOUNT } from "../constants/ActionTypes"

export const handleDiscountRedirects: Middleware = () => (next) => (action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_DISCOUNT:
      history.push(routes.ADMIN_NAV.DISCOUNTS.EDITOR)
      break
    case SELECT_DISCOUNT:
      if (payload) {
        const route = routes.ADMIN_NAV.DISCOUNTS.EDITOR_ACTIVE.replace(
          ":id",
          payload
        )
        history.push(route)
        return
      }
      history.push(routes.ADMIN_NAV.DISCOUNTS.EDITOR)
      break
  }
  return next(action)
}
