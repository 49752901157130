import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import { LAW_COLOR, NON_LAW_COLOR } from "../../../helpers/constants/lawColors"
import { CourseCourseType } from "../../../types/Global-Types"
import { getCurrentLessonInfo } from "../../operators/getCurrentLessonInfo"
import {
  getCourseTypeString,
  getFriendlyStatusString,
  getHourString,
} from "../../operators/getFriendlyCourseInfo"
import { GetMyCourses_myStudentCourses } from "../../queries/types/GetMyCourses"
import DateCompleted from "./DateCompleted"

type StudentCourse = GetMyCourses_myStudentCourses

interface Props {
  studentCourse: StudentCourse
}

const TextContent = ({ studentCourse }: Props) => {
  const isLaw = studentCourse.course.courseType === CourseCourseType.LAW
  const { currentLesson, totalLessons } = getCurrentLessonInfo(studentCourse)

  return (
    <Wrapper>
      <Typography variant="body1" component="p" gutterBottom>
        {studentCourse.course.title}
      </Typography>
      <Typography variant="caption" gutterBottom>
        <i>{`${studentCourse.tag} | ${getCourseTypeString(
          studentCourse.course.courseType
        )} | ${getHourString(studentCourse.course.hours)}`}</i>
      </Typography>
      <ColoredTypography
        isLaw={isLaw}
        color="primary"
        component="div"
        gutterBottom
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            {getFriendlyStatusString(studentCourse.status)}{" "}
            <DateCompleted dateCompleted={studentCourse.dateCompleted} />
          </Grid>
          <Grid item>
            <ColoredTypography isLaw={isLaw} color="primary" variant="caption">
              <b>{currentLesson}</b> of <b>{totalLessons}</b> lessons done
            </ColoredTypography>
          </Grid>
        </Grid>
      </ColoredTypography>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
`
interface ColoredTypographyProps {
  isLaw: boolean
}
const ColoredTypography = styled<any>(({ isLaw, ...rest }: any) => (
  <Typography {...rest} />
))`
  color: ${({ isLaw }: ColoredTypographyProps) =>
    isLaw ? LAW_COLOR : NON_LAW_COLOR};
`

export default TextContent
