import Button, { ButtonProps } from "@material-ui/core/Button"
import React from "react"
import styled from "styled-components"

import RouterLink from "../../../helpers/components/RouterLink"
import { LAW_COLOR, NON_LAW_COLOR } from "../../../helpers/constants/lawColors"
import { routes } from "../../../routes/constants/routes"
import { StudentCourseStatus } from "../../../types/Global-Types"

interface Props {
  isLaw: boolean
  studentCourseId: string
  studentCourseStatus: StudentCourseStatus
}

const getRouterLink = (
  studentCourseId: string,
  studentCourseStatus: StudentCourseStatus
): string | null => {
  switch (studentCourseStatus) {
    case StudentCourseStatus.NOT_STARTED:
    case StudentCourseStatus.IN_PROGRESS:
      return routes.APP.COURSE.replace(":id", studentCourseId)
    case StudentCourseStatus.FINISHED:
      return routes.APP.COURSE_CERTIFICATE.replace(":id", studentCourseId)
    case StudentCourseStatus.EXPIRED:
    default:
      return null
  }
}

const getButtonText = (status: StudentCourseStatus) => {
  switch (status) {
    case StudentCourseStatus.NOT_STARTED:
      return "Start Course"
    case StudentCourseStatus.IN_PROGRESS:
      return "Continue Course"
    case StudentCourseStatus.FINISHED:
      return "View Certificate"
    case StudentCourseStatus.EXPIRED:
      return "Course Expired"
    default:
      return null
  }
}

const PrimaryButton = ({
  isLaw,
  studentCourseId,
  studentCourseStatus,
}: Props) => {
  const routerLink = getRouterLink(studentCourseId, studentCourseStatus)
  const text = getButtonText(studentCourseStatus)

  if (!routerLink) {
    return (
      <DisabledButton disabled variant="outlined">
        {text}
      </DisabledButton>
    )
  }

  return (
    <RouterLink to={routerLink}>
      <StyledButton isLaw={isLaw} variant="outlined">
        {text}
      </StyledButton>
    </RouterLink>
  )
}

interface StyledProps {
  isLaw: boolean
}

const DisabledButton = styled(Button)`
  font-size: 12px;
  text-transform: none;
  border-width: 2px !important;
`

const StyledButton = styled(({ isLaw, ...rest }: StyledProps & ButtonProps) => (
  <Button {...rest} />
))`
  color: ${({ isLaw }: StyledProps) => (isLaw ? LAW_COLOR : NON_LAW_COLOR)};
  border-color: ${({ isLaw }: StyledProps) =>
    isLaw ? LAW_COLOR : NON_LAW_COLOR};
  font-size: 12px;
  text-transform: none;
  margin-right: 8px;
`

export default PrimaryButton
