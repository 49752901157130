import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import { editorCSS } from "../constants/editorCSS"

interface Props {
  html: string
}

const StyledHTML = ({ html }: Props) => {
  return (
    <Typography component="div">
      <StyledDiv dangerouslySetInnerHTML={{ __html: html }} />
    </Typography>
  )
}

const StyledDiv = styled.div`
  ${editorCSS}
`

export default StyledHTML
