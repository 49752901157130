import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import React from "react"

type Props = WithStyles

class AppWrapper extends React.Component<Props> {
  render() {
    const { classes, children } = this.props
    return <div className={classes.root}>{children}</div>
  }
}

export default withStyles({
  root: {
    height: "100vh",
    width: "100vw",
    minWidth: "320px",
    overflow: "hidden",
  },
})(AppWrapper)
