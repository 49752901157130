import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useForm } from "react-hook-form"

import Divider from "../../../helpers/components/Divider"
import StateSelectField from "../../../input/components/v2/address/StateSelectField"
import ZipCodeField from "../../../input/components/v2/address/ZipCodeField"
import ControlledTextField from "../../../input/components/v2/core/ControlledTextField"
import LicenseTextField from "../../../input/components/v2/profile/LicenseTextField"
import PhoneNumberField from "../../../input/components/v2/profile/PhoneNumberField"
import SSNTextField from "../../../input/components/v2/profile/SSNTextField"
import FormHolder from "../FormHolder"
import LoadingButton from "../LoadingButton"

interface Props {
  onSubmit: (user: User) => void
  currentUser: User
  requestErrors?: RestError
  requestLoading: boolean
}

type State = User

const PartTwoForm = ({
  onSubmit,
  currentUser,
  requestLoading,
  requestErrors,
}: Props) => {
  const { handleSubmit, control, errors } = useForm<State>({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: currentUser,
  })

  const getError = (field: string) => {
    if (requestErrors) {
      return requestErrors[field]?.join("")
    }
  }

  return (
    <FormHolder
      maxWidth={600}
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <Typography variant="h5" gutterBottom>
        Complete Your Profile
      </Typography>
      <Typography gutterBottom>
        Complete this form to receive full course access.
      </Typography>

      <Divider />

      <Typography variant="h6" gutterBottom>
        Address Details
      </Typography>
      <Grid container>
        <Grid item sm={12}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="address.street"
            autoComplete="street-address"
            label="Street"
          />
        </Grid>
        <Grid item sm={12}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="address.city"
            autoComplete="address-level2"
            label="City"
          />
        </Grid>
        <Grid item sm={8}>
          <ZipCodeField
            control={control}
            errors={errors}
            name="address.zipCode"
          />
        </Grid>
        <Grid item sm={4}>
          <StateSelectField
            control={control}
            errors={errors}
            name="address.state"
          />
        </Grid>
      </Grid>

      <Divider />

      <Typography variant="h6" gutterBottom>
        License Details
      </Typography>
      <Grid container>
        <Grid item sm={12}>
          <PhoneNumberField
            control={control}
            errors={errors}
            name="phone"
            error={Boolean(getError("phone"))}
            helperText={getError("phone")}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item sm={12}>
          <LicenseTextField
            control={control}
            errors={errors}
            name="licenseNumber"
            rules={{ required: true }}
          />
        </Grid>
        <Grid item sm={12}>
          <SSNTextField
            control={control}
            errors={errors}
            name="ssn"
            rules={{ required: true }}
          />
        </Grid>

        <Grid item sm={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={requestLoading}
          >
            Finish Registration
          </LoadingButton>
        </Grid>
      </Grid>
    </FormHolder>
  )
}

export default PartTwoForm
