import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import React from "react"
import { useQuery } from "react-apollo"

import ContactCTA from "../../../footer/components/ContactCTA"
import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import PageError from "../../../helpers/components/PageError"
import StyledHTML from "../../../helpers/components/StyledHTML"
import TitledWrapper from "../../../sidebar/components/TitledWrapper"
import loadAboutPage from "../queries/loadAboutPage"
import { LoadAboutPage } from "../queries/types/LoadAboutPage"

const AboutWrapper = () => {
  const { data, loading, refetch } = useQuery<LoadAboutPage>(loadAboutPage, {})

  return (
    <div className="container">
      <TitledWrapper title="About 123 ConEd LLC" gapTop showBanner>
        <Card elevation={1}>
          <CardContent>
            <LoadingWrapper isLoading={loading}>
              {data?.aboutPage ? (
                <StyledHTML html={data.aboutPage.body} />
              ) : (
                <PageError refetch={refetch} />
              )}
            </LoadingWrapper>
            <ContactCTA />
          </CardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

export default AboutWrapper
