import OutlinedInput from "@material-ui/core/OutlinedInput"
import debounce from "lodash.debounce"
import React, { Component } from "react"

import { FillInTheBlankSerializer } from "../operators/FillInTheBlankSerializer"

interface Props {
  content: string | null
  showAnswer: boolean
  submissionAnswer?: string
  onAnswerChange: (stagedAnswer: string) => void
}

interface State {
  stagedAnswer: string[]
}

class FillInTheBlankQuestion extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      stagedAnswer: [],
    }
    this.handleChange = this.handleChange.bind(this)
    this.updateQuiz = debounce(this.updateQuiz.bind(this), 100, {
      maxWait: 250,
    })
  }

  componentDidMount() {
    this.resetStagedAnswer()
  }

  componentDidUpdate({ showAnswer: prevShowAnswer }: Props) {
    if (this.props.showAnswer && !prevShowAnswer) {
      this.resetStagedAnswer()
    }
  }

  updateQuiz() {
    this.props.onAnswerChange(
      FillInTheBlankSerializer.serialize(this.state.stagedAnswer)
    )
  }

  handleChange(index: number) {
    return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const stagedAnswer = this.state.stagedAnswer.slice()
      stagedAnswer[index] = value
      this.setState(
        {
          stagedAnswer,
        },
        this.updateQuiz
      )
    }
  }

  renderInput(index: number) {
    const disabled = Boolean(this.props.showAnswer)
    // Don't return text input at the end of the sentence
    if (
      index >=
      FillInTheBlankSerializer.getAnswers(this.props.content || "").length
    ) {
      return null
    }
    const sharedProps = {
      variant: "outlined" as any,
      margin: "dense" as any,
      labelWidth: 0,
    }
    // Only render submitted answers when disabled
    if (disabled) {
      const answers = FillInTheBlankSerializer.deserialize(
        this.props.submissionAnswer || "[]"
      )
      const value: string =
        answers.length && answers.length > index ? answers[index] : ""
      return <OutlinedInput {...sharedProps} value={value} disabled={true} />
    }
    return (
      <OutlinedInput
        {...sharedProps}
        value={this.state.stagedAnswer[index] || ""}
        onChange={this.handleChange(index)}
      />
    )
  }

  render() {
    const options = FillInTheBlankSerializer.getContentWithoutAnswers(
      this.props.content || ""
    )
    return options.map((option, index) => {
      return (
        <span key={index} style={{ fontSize: 16 }}>
          {option}
          {this.renderInput(index)}
        </span>
      )
    })
  }

  private resetStagedAnswer() {
    const answers = FillInTheBlankSerializer.getAnswers(
      this.props.content || ""
    )
    this.setState(
      { stagedAnswer: new Array(answers.length).fill("") },
      this.updateQuiz
    )
  }
}

export default FillInTheBlankQuestion
