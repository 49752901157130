import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import React from "react"
import { Controller } from "react-hook-form"

import {
  getErrorFromName,
  getErrorMessageFromFieldError,
} from "../../../operators/errorGetters"
import { ControlledProps } from "./ControlledProps"

type Props = ControlledProps<CheckboxProps> & {
  value?: boolean
  children: React.ReactNode
}

const ControlledCheckbox = ({
  name,
  control,
  errors,
  rules,
  value,
  children,
  onChange: propOnChange,
  ...rest
}: Props) => {
  const fieldError = getErrorFromName(name, errors)
  const errorMessage = getErrorMessageFromFieldError(fieldError)
  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (propOnChange) {
      propOnChange(event, checked)
    }
    control.setValue(name, checked)
  }
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={value || false}
      render={({ value, ...cRest }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                {...cRest}
                {...rest}
                value={value}
                checked={value}
                required={rules?.required}
                onChange={onChange}
              />
            }
            label={children}
          />
          {errorMessage && (
            <FormHelperText error={Boolean(errorMessage)}>
              {errorMessage}
            </FormHelperText>
          )}
        </>
      )}
    />
  )
}

export default ControlledCheckbox
