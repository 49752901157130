import Button from "@material-ui/core/Button"
import Container, { ContainerProps } from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React, { PureComponent } from "react"
import styled from "styled-components"

import RouterLink from "../../helpers/components/RouterLink"
import { getStaticFile } from "../../helpers/operators/getStaticFile"
import { routes } from "../../routes/constants/routes"
import { overlayEnd, overlayStart } from "../../Theme"

export interface Props extends Pick<ContainerProps, "maxWidth"> {
  loggedIn: boolean
  title: string
  subtitle: string
}

class Hero extends PureComponent<Props> {
  render() {
    const { loggedIn, maxWidth, title, subtitle } = this.props

    return (
      <Banner>
        <FullHeightContainer maxWidth={maxWidth}>
          <FullHeightGrid container alignItems="center">
            <Grid item xs={12} md={8}>
              <TypographyHeader variant="h1">{title}</TypographyHeader>
              <Subtitle>{subtitle}</Subtitle>
              {loggedIn ? (
                <RouterLink to={routes.APP.MY_COURSES}>
                  <WhiteButton variant="outlined" size="large">
                    My Courses
                  </WhiteButton>
                </RouterLink>
              ) : (
                <>
                  <RouterLink to={routes.AUTH.LOGIN}>
                    <WhiteButton variant="outlined" size="large">
                      Sign In
                    </WhiteButton>
                  </RouterLink>
                  <RouterLink to={routes.AUTH.REGISTER}>
                    <WhiteButton variant="outlined" size="large">
                      Register
                    </WhiteButton>
                  </RouterLink>
                </>
              )}
            </Grid>
          </FullHeightGrid>
        </FullHeightContainer>
      </Banner>
    )
  }
}

const Banner = styled.div`
  height: auto;
  min-height: 800px;
  padding-top: 100px;
  margin-bottom: -200px;
  padding-bottom: 220px;
  color: white;
  background-image: linear-gradient(${overlayStart}, ${overlayEnd}),
    url(${getStaticFile("images/bg.jpg")});
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 480px) {
    margin-bottom: -48px;
    padding-top: 48px;
    padding-bottom: 60px;
  }
`
const FullHeightContainer = styled(Container)`
  height: calc(100% - 200px);
  @media screen and (max-width: 480px) {
    height: calc(100% - 48px);
  }
`
const FullHeightGrid = styled(Grid)`
  height: 100%;
`
const TypographyHeader = styled(Typography)`
  font-family: "Quattrocento", sans-serif;
  font-weight: bold;
  @media screen and (max-width: 480px) {
    font-size: 48px;
  }
`
const Subtitle = styled(Typography)`
  && {
    font-size: 24px;
    color: white;
    margin-bottom: 32px;
  }
`
const WhiteButton = styled(Button)`
  border-color: #fff !important;
  color: #fff !important;
  font-size: 20px;
  padding: 0.5em 1em;
  margin-right: 8px;
`

export default Hero
