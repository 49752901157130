import { connect } from "react-redux"

import { getCurrentUser } from "../../../auth/selectors/currentUserGetters"
import withPageTitle from "../../../routes/containers/withPageTitle"
import { State } from "../../../store"
import FAQWrapper from "../components/FAQWrapper"

const mapStateToProps = (state: State) => ({
  user: getCurrentUser(state),
})

export default withPageTitle(
  "FAQ",
  "General Information What is 123ConEd.com? 123 ConEd LLC is a leading online provider of continuing education courses to real estate professionals in Michigan."
)(connect(mapStateToProps)(FAQWrapper))
