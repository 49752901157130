import { client } from "../../../graphql/client/client"
import finishStudentCourse from "../finishStudentCourse"
import {
  FinishStudentCourse,
  FinishStudentCourseVariables,
} from "../types/FinishStudentCourse"

export const finishCourseClient = async (
  studentCourseId: FinishStudentCourseVariables["studentCourseId"],
  timeElapsed: FinishStudentCourseVariables["timeElapsed"]
) => {
  return client
    .mutate<FinishStudentCourse, FinishStudentCourseVariables>({
      mutation: finishStudentCourse,
      variables: {
        studentCourseId,
        timeElapsed,
      },
    })
    .then(({ data }) => Boolean(data?.finishStudentCourse?.ok))
}
