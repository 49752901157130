import { useTheme } from "@material-ui/core/styles"
import React from "react"
import styled from "styled-components"

const CalendarIcon = () => {
  const theme = useTheme()
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="512"
      height="512"
      fill={theme.palette.primary.main}
    >
      <g>
        <g>
          <path d="m410.856 247.874v-45.37h-339.679v209.808l159.08.002v-15h-14.208c0-6.249 0-43.694 0-49.939h22.288v-15h-22.288v-49.93l64.936-.005.002-64.936h49.931v31.95h15v-31.95h49.939v30.37zm-259.743 34.565h49.934v49.936h-49.934zm64.936-64.935h49.936v49.936h-49.936zm-64.936 0h49.936v49.936h-49.936zm-64.936 0h49.937v49.936h-49.937zm0 64.935h49.937v49.936h-49.937zm49.936 114.873h-49.936v-49.937h49.937v49.937zm15 0v-49.937h49.934v49.937z" />
          <path d="m374.326 267.444c-67.464 0-122.35 54.886-122.35 122.35 0 30.755 11.46 60.152 32.27 82.777l11.041-10.154c-18.257-19.849-28.311-45.64-28.311-72.623 0-59.192 48.157-107.35 107.35-107.35s107.351 48.157 107.351 107.35-48.157 107.35-107.351 107.35c-25.173 0-49.639-8.892-68.891-25.037l-9.639 11.494c21.949 18.406 49.838 28.543 78.529 28.543 67.464 0 122.351-54.886 122.351-122.35s-54.886-122.35-122.35-122.35z" />
          <path d="m366.358 306.973v97.676h70.152v-15h-55.152v-82.676z" />
          <path d="m30.467 175.714h40.71v-15h-40.71v-111.87h32.66v37.29h.001v.003h60.208v-.003h.001v-37.29h38.31v37.29h.005v.003h60.207v-37.293h38.316v37.293h60.207v-.003h.005v-37.29h38.31v37.29h.001v.003h60.208v-.003h.001v-37.29h32.66v111.87h-365.39v15h365.39v96.67h15v-238.54h-47.661v-15.165l-13.893-18.679h-32.423l-13.893 18.679v15.165h-38.315v-15.165l-13.893-18.679h-32.422l-13.893 18.679v15.165h-38.316v-15.165l-13.892-18.679h-32.422l-13.893 18.679v15.165h-38.315v-15.165l-13.893-18.679h-32.422l-13.893 18.679v15.165h-47.661v417.811h224.59v-15h-209.59zm349.66-160.714h17.349l6.43 8.646v47.488h-30.208v-47.488zm-104.952 8.646 6.43-8.646h17.348l6.43 8.646v47.488h-30.205v-37.29h-.002v-10.198zm-92.094-8.646h17.348l6.43 8.646v10.198h-.002v37.29h-30.205v-47.488zm-98.523 0h17.349l6.43 8.646v47.488h-30.209v-47.488z" />
        </g>
        <g>
          <path d="m293.017 136.068h-15v-15h15zm-45 0h-15v-15h15zm-45 0h-15v-15h15z" />
        </g>
        <g>
          <path d="m287.618 389.649h15v15h-15z" />
        </g>
        <g>
          <path d="m366.931 462.292h15v15h-15z" />
        </g>
      </g>
    </Wrapper>
  )
}

const Wrapper = styled.svg`
  height: 100px;
  width: 100px;
`

export default CalendarIcon
