import { connect } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router-dom"

import withPageTitle from "../../routes/containers/withPageTitle"
import { State } from "../../store"
import ConfirmEmail, { Props } from "../components/ConfirmEmail"
import { isCurrentUser } from "../selectors/currentUserGetters"

const mapStateToProps = (state: State): Partial<Props> => ({
  isLoggedIn: isCurrentUser(state),
})

const ConnectedConfirmEmail = connect<
  Partial<Props>,
  unknown,
  RouteComponentProps
>(mapStateToProps)(ConfirmEmail)

export default withPageTitle("Confirming Email")(
  withRouter(ConnectedConfirmEmail)
)
