import { gql } from "apollo-boost"

export default gql`
  mutation SubmitQuiz(
    $studentCourseId: ID!
    $answers: [QuestionAnswerType!]!
    $lessonId: ID!
  ) {
    submitQuiz(
      answers: $answers
      lessonId: $lessonId
      studentCourseId: $studentCourseId
    ) {
      submission {
        id
        answers {
          answer
          correct
          question {
            id
          }
        }
      }
    }
  }
`
