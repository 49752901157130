import React from "react"
import styled from "styled-components"

import { getStaticFile } from "../operators/getStaticFile"

interface Props {
  isLaw: boolean
  primary?: boolean
}

const LawIcon = ({
  isLaw,
  primary,
  ...rest
}: Props & React.ImgHTMLAttributes<HTMLImageElement>) => {
  return (
    <Img
      primary={primary}
      alt={isLaw ? "law" : "non-law"}
      {...rest}
      src={getStaticFile(`images/category-${isLaw ? "law" : "non-law"}.png`)}
    />
  )
}

// https://codepen.io/sosuke/pen/Pjoqqp
const Img = styled.img`
  ${({ primary }: Partial<Props>) =>
    primary &&
    "filter: invert(50%) sepia(61%) saturate(578%) hue-rotate(152deg) brightness(83%) contrast(91%);"}
`

export default LawIcon
