import { gql } from "apollo-boost"

export default gql`
  query CurrentLicenseRequirements {
    cycleLicenseRequirements {
      cycleName
      yearlyHoursRequirement
      totalHoursRequirement
      startDate
      endDate
    }
    licenseRequirementsPage {
      body
    }
  }
`
