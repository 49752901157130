import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"
import { useQuery } from "react-apollo"

import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import PageError from "../../../helpers/components/PageError"
import StyledHTML from "../../../helpers/components/StyledHTML"
import TitledWrapper from "../../../sidebar/components/TitledWrapper"
import loadUserAgreementPage from "../queries/loadUserAgreementPage"
import { LoadUserAgreementPage } from "../queries/types/LoadUserAgreementPage"

const UserAgreement = () => {
  const { data, loading, refetch } = useQuery<LoadUserAgreementPage>(
    loadUserAgreementPage
  )

  return (
    <div className="container">
      <TitledWrapper title="User Agreement" gapTop showBanner>
        <Card elevation={1}>
          <CardContent>
            <LoadingWrapper isLoading={loading}>
              {data?.userAgreementPage ? (
                <>
                  <Typography gutterBottom variant="caption" component="p">
                    Last revised on{" "}
                    {moment(data.userAgreementPage.created).format(
                      "MMMM D, YYYY"
                    )}
                  </Typography>
                  <StyledHTML html={data.userAgreementPage.body} />
                </>
              ) : (
                <PageError refetch={refetch} />
              )}
            </LoadingWrapper>
          </CardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

export default UserAgreement
