import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { useForm } from "react-hook-form"

import Divider from "../../../helpers/components/Divider"
import FullwidthGrid from "../../../helpers/components/FullwidthGrid"
import RouterLink from "../../../helpers/components/RouterLink"
import PasswordField from "../../../input/components/v2/auth/PasswordField"
import ControlledTextField from "../../../input/components/v2/core/ControlledTextField"
import {
  emailPattern,
  passwordValidate,
} from "../../../input/constants/validationPatterns"
import { routes } from "../../../routes/constants/routes"
import FormHolder from "../FormHolder"
import LoadingButton from "../LoadingButton"
import AcceptPolicyCheckbox from "./AcceptPolicyCheckbox"

interface Props {
  register: (payload: State) => void
  requestLoading: boolean
  emailError?: string
  passwordError?: string
}

interface State {
  email: string
  confirmEmail: string
  password: string
  confirmPassword: string
  firstName: string
  lastName: string
}

const PartOneForm = ({
  register,
  emailError,
  passwordError,
  requestLoading,
}: Props) => {
  const { handleSubmit, control, errors, watch } = useForm<State>({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  })
  return (
    <FormHolder
      maxWidth={600}
      onSubmit={handleSubmit((data) => register(data))}
    >
      <Typography variant="h5" gutterBottom>
        Create your account with 123 ConEd LLC
      </Typography>
      <Grid container>
        <FullwidthGrid item sm={6}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="firstName"
            autoComplete="given-name"
            label="First name"
            rules={{ required: true }}
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={6}>
          <ControlledTextField
            control={control}
            errors={errors}
            autoComplete="family-name"
            label="Last name"
            name="lastName"
            rules={{ required: true }}
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="email"
            autoComplete="username"
            label="Email address"
            rules={{ required: true, pattern: emailPattern }}
            error={Boolean(emailError)}
            helperText={
              emailError ? emailError : "You will use this email for login"
            }
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <ControlledTextField
            control={control}
            errors={errors}
            name="confirmEmail"
            autoComplete="username"
            label="Confirm email address"
            rules={{
              required: true,
              validate: (data: any) =>
                String(data).toLowerCase() ===
                  String(watch("email")).toLowerCase() || "Emails do not match",
            }}
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <PasswordField
            showPhrase
            control={control}
            errors={errors}
            name="password"
            autoComplete="new-password"
            label="Password"
            error={Boolean(passwordError)}
            helperText={passwordError}
            rules={{ required: true, validate: passwordValidate }}
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <PasswordField
            control={control}
            errors={errors}
            name="confirmPassword"
            autoComplete="new-password"
            label="Confirm password"
            rules={{
              required: true,
              validate: (data: any) =>
                data === watch("password") || "Passwords do not match",
            }}
          />
        </FullwidthGrid>
        <FullwidthGrid item sm={12}>
          <AcceptPolicyCheckbox
            control={control}
            errors={errors}
            name="acceptPolicy"
            rules={{ required: true }}
          />
        </FullwidthGrid>
      </Grid>

      <Divider />

      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <RouterLink to={routes.AUTH.LOGIN} blue>
            Sign In Instead
          </RouterLink>
        </Grid>
        <Grid item>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={requestLoading}
          >
            Register
          </LoadingButton>
        </Grid>
      </Grid>
    </FormHolder>
  )
}

export default PartOneForm
