import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"

import { logoutUser } from "../../auth/actions/logoutUser"
import { isSidebarVisible } from "../../sidebar/operators/isSidebarVisible"
import { State } from "../../store"
import Navbar, { Props } from "../components/Navbar"
import { getSecondaryNavigationLinks } from "../operators/getSecondaryNavigationLinks"
import { getPathname } from "../selectors/getPathname"

const mapStateToProps = (state: State, ownProps: Props): Partial<Props> => ({
  loggedInUser: state.auth.currentUser,
  navigationLinks: getSecondaryNavigationLinks(),
  isSidebarVisible: isSidebarVisible(getPathname(state, ownProps)),
  activeLink: getPathname(state, ownProps),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): Partial<Props> => ({
  logout: () => dispatch(logoutUser()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navbar) as any
)
