import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

interface Props {
  title: string | number
  subtitle: React.ReactNode
  children: React.ReactNode
}

const BaseCard = ({ title, subtitle, children }: Props) => {
  return (
    <StyledCard elevation={1}>
      <CardContent>
        <Typography variant="h2" className="underline" color="primary">
          <b>{title}</b>
        </Typography>
        <Typography>{subtitle}</Typography>
        {children}
      </CardContent>
    </StyledCard>
  )
}

const StyledCard = styled(Card)`
  position: relative;

  .underline {
    margin-bottom: 30px !important;
  }

  p {
    color: #666;
    line-height: 1.8;
    font-size: 18px;
  }

  svg {
    position: absolute;
    top: 30px;
    right: 30px;
    opacity: 0.5;
    width: 50px;
    height: 50px;
  }
`

export default BaseCard
