import { api } from "../../helpers/api/handler"

export const passwordResetConfirm = (
  newPassword1: string,
  newPassword2: string,
  uid: string,
  token: string
) =>
  api
    .post<{ detail: string }>(
      "/auth/password/reset/confirm/",
      JSON.stringify({
        new_password1: newPassword1,
        new_password2: newPassword2,
        uid,
        token,
      })
    )
    .then(({ detail }) => detail)
