import Grid from "@material-ui/core/Grid"
import React, { Component } from "react"
import { DataProps } from "react-apollo"
import { RouteComponentProps } from "react-router-dom"

import FullwidthGrid from "../../helpers/components/FullwidthGrid"
import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import { getQueryParams } from "../../helpers/operators/getQueryParams"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import { Dashboard } from "../queries/types/Dashboard"
import CTAWrapper from "./cta/CTAWrapper"
import RecentCourseWrapper from "./recents/RecentCourseWrapper"
import StatsWrapper from "./stats/StatsWrapper"
import WelcomeDialog from "./WelcomeDialog"

interface Props extends DataProps<Dashboard, unknown>, RouteComponentProps {}

class DashboardWrapper extends Component<Props> {
  render() {
    const { data } = this.props

    return (
      <TitledWrapper
        title={data.dashboardPage?.showCycleStats ? "Dashboard" : ""}
      >
        <LoadingWrapper isLoading={data.loading}>
          <Grid container>
            {data.dashboardPage?.showCycleStats ? (
              <FullwidthGrid item lg={12}>
                <StatsWrapper
                  dashboardPage={data.dashboardPage}
                  licenseRequirements={data.cycleLicenseRequirements}
                />
              </FullwidthGrid>
            ) : null}
            <FullwidthGrid item lg={6}>
              <RecentCourseWrapper
                zeroStateText={data.dashboardPage?.zeroStateBody}
                mostRecentCourses={data.mostRecentCourses || []}
              />
            </FullwidthGrid>
            <FullwidthGrid item lg={6}>
              <CTAWrapper />
            </FullwidthGrid>
          </Grid>
        </LoadingWrapper>
        <WelcomeDialog show={this.showWelcomeDialog()} />
      </TitledWrapper>
    )
  }

  private showWelcomeDialog(): boolean {
    const { welcome } = getQueryParams(this.props)
    return Boolean(welcome)
  }
}

export default DashboardWrapper
