import { gql } from "apollo-boost"

export default gql`
  query MyTransactions {
    myTransactions {
      id
      date
      netTotal
      refundTotal
      referenceId
      status
      studentCourses {
        id
        course {
          id
          title
        }
      }
    }
  }
`
