import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"

import StyledHTML from "../../../helpers/components/StyledHTML"

interface Props {
  introduction: string | null
}

class CourseIntroduction extends Component<Props> {
  renderText() {
    const { introduction } = this.props
    if (!introduction) {
      return (
        <Typography variant="body1">
          Begin the following course by clicking the &quot;Next&quot; button
          below
        </Typography>
      )
    }
    return <StyledHTML html={introduction} />
  }

  render() {
    return (
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h6">Introduction</Typography>
        </Grid>
        <Grid item md={12}>
          {this.renderText()}
        </Grid>
      </Grid>
    )
  }
}

export default CourseIntroduction
