import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"

const ContactCTA = () => {
  const theme = useTheme()
  return (
    <Wrapper backgroundColor={theme.palette.primary.main}>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Typography variant="h4">Have a question?</Typography>
        </Grid>
        <Grid item>
          <RouterLink to={routes.SECONDARY_NAV.CONTACT_US}>
            <WhileButton size="large" variant="outlined">
              Contact Us
            </WhileButton>
          </RouterLink>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

interface Themer {
  backgroundColor: string
}
const Wrapper = styled.div`
  color: #fff;
  padding: 2em;
  margin-top: 24px;
  background-color: ${({ backgroundColor }: Themer) => backgroundColor};
  h4 {
    text-align: right;
  }
`
const WhileButton = styled(Button)`
  border-color: #fff !important;
  color: #fff !important;
  font-size: 20px;
  padding: 0.5em 1em;
`

export default ContactCTA
