import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import ErrorBoundaryWithMessage from "../../errors/components/ErrorBoundaryWithMessage"
import BannerIconsContainer from "../containers/BannerIconsContainer"

interface Props {
  title: string
  gapTop?: boolean
  showBanner?: boolean
  upperRightComponent?: JSX.Element
}

class TitledWrapper extends React.PureComponent<Props> {
  renderRightGrid() {
    const { title, children, upperRightComponent } = this.props
    return (
      <Grid container direction="column">
        <LeftAlignGrid item>
          <div>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h5">
                  <b>{title}</b>
                </Typography>
              </Grid>
              {upperRightComponent && <Grid item>{upperRightComponent}</Grid>}
            </Grid>
          </div>
        </LeftAlignGrid>
        <Grid className="titled-content-grid" item>
          <div className="titled-content">
            <ErrorBoundaryWithMessage>{children}</ErrorBoundaryWithMessage>
          </div>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { showBanner, gapTop } = this.props
    return (
      <Wrapper gapTop={gapTop}>
        {showBanner ? (
          <Grid container direction="row">
            <FullwidthGrid item md="auto">
              <BannerIconsContainer />
            </FullwidthGrid>
            <FullwidthGrid item md>
              <div>{this.renderRightGrid()}</div>
            </FullwidthGrid>
          </Grid>
        ) : (
          this.renderRightGrid()
        )}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  padding: 0.75em;
  @media screen and (min-width: 961px) {
    padding: 2em;
    ${({ gapTop }: { gapTop?: boolean }) => gapTop && `padding-top: 60px;`}
  }
`

const LeftAlignGrid = styled(Grid)`
  text-align: left;
`
const FullwidthGrid = styled(Grid)`
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
`

export default TitledWrapper
