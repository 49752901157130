import React from "react"
import { useQuery } from "react-apollo"

import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import getFooter from "../queries/getFooter"
import { GetFooter } from "../queries/types/GetFooter"
import Footer from "./Footer"

const FooterWrapper = () => {
  const { data, loading } = useQuery<GetFooter>(getFooter)
  return (
    <LoadingWrapper isLoading={loading && !data?.footer}>
      {data?.footer ? (
        <Footer data={data.footer} />
      ) : (
        <div style={{ textAlign: "center", padding: "2em" }}>
          Unable to load footer information
        </div>
      )}
    </LoadingWrapper>
  )
}

export default FooterWrapper
