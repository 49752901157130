import Icon, { IconProps } from "@material-ui/core/Icon"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import React from "react"

type Props = IconProps &
  WithStyles & {
    icon: string
    gapRight?: boolean
  }

const styles = {
  root: {
    width: "initial",
  },
  gapRight: {
    marginRight: "0.5em",
  },
}

class FontAwesome extends React.PureComponent<Props> {
  render() {
    const { icon, classes, gapRight, className, ...rest } = this.props
    const realClasses = [icon, classes.root]
    if (className) {
      realClasses.push(className)
    }
    if (gapRight) {
      realClasses.push(classes.gapRight)
    }
    return <Icon className={`${realClasses.join(" ")}`} {...rest} />
  }
}

export default withStyles(styles)(FontAwesome)
