import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import React from "react"

import FontAwesome from "./FontAwesome"

interface Props {
  title: string
}

const QuestionTooltip = ({ title: propTitle }: Props) => {
  const title = <Typography>{propTitle}</Typography>
  return (
    <Tooltip title={title}>
      <FontAwesome icon="fas fa-question-circle" />
    </Tooltip>
  )
}

export default QuestionTooltip
