import { useTheme } from "@material-ui/core/styles"
import React from "react"
import styled from "styled-components"

const Courses = () => {
  const theme = useTheme()
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="512"
      height="512"
      fill={theme.palette.primary.main}
    >
      <g id="Outline">
        <g>
          <path d="M61,16a1,1,0,0,0-1,1V53H54.57a85.082,85.082,0,0,0-19.12,2.178,1.992,1.992,0,0,1-2.1-.834A22.732,22.732,0,0,1,45.211,51H57a1,1,0,0,0,1-1V43a1,1,0,0,0-2,0v6H45.211A24.728,24.728,0,0,0,33,52.212V25a1,1,0,0,0-2,0V52.211A24.724,24.724,0,0,0,18.789,49H8V13H23.4A7.605,7.605,0,0,1,31,20.605a1,1,0,1,0,2,0A7.605,7.605,0,0,1,40.6,13H56V39a1,1,0,0,0,2,0V12a1,1,0,0,0-1-1H40.6A9.613,9.613,0,0,0,32,16.332,9.613,9.613,0,0,0,23.4,11H7a1,1,0,0,0-1,1V50a1,1,0,0,0,1,1H18.789a22.732,22.732,0,0,1,11.863,3.344,2,2,0,0,1-2.1.834A85.069,85.069,0,0,0,9.43,53H4V17a1,1,0,0,0-2,0V54a1,1,0,0,0,1,1H9.43A83.117,83.117,0,0,1,28.1,57.127,3.98,3.98,0,0,0,32,55.859a3.978,3.978,0,0,0,3.9,1.268A83.145,83.145,0,0,1,54.57,55H61a1,1,0,0,0,1-1V17A1,1,0,0,0,61,16Z" />
          <path d="M28.723,19.613A9.579,9.579,0,0,0,23.4,18H12a1,1,0,0,0,0,2H23.4a7.576,7.576,0,0,1,4.218,1.277,1,1,0,1,0,1.11-1.664Z" />
          <path d="M52,18H40.6a9.581,9.581,0,0,0-5.328,1.613,1,1,0,0,0,1.11,1.664A7.581,7.581,0,0,1,40.6,20H52a1,1,0,0,0,0-2Z" />
          <path d="M28.723,24.613A9.579,9.579,0,0,0,23.4,23H12a1,1,0,0,0,0,2H23.4a7.576,7.576,0,0,1,4.218,1.277,1,1,0,1,0,1.11-1.664Z" />
          <path d="M52,23H40.6a9.581,9.581,0,0,0-5.328,1.613,1,1,0,0,0,1.11,1.664A7.581,7.581,0,0,1,40.6,25H52a1,1,0,0,0,0-2Z" />
          <path d="M28.723,29.613A9.579,9.579,0,0,0,23.4,28H12a1,1,0,0,0,0,2H23.4a7.576,7.576,0,0,1,4.218,1.277,1,1,0,1,0,1.11-1.664Z" />
          <path d="M52,28H40.6a9.581,9.581,0,0,0-5.328,1.613,1,1,0,0,0,1.11,1.664A7.581,7.581,0,0,1,40.6,30H52a1,1,0,0,0,0-2Z" />
          <path d="M28.723,34.613A9.579,9.579,0,0,0,23.4,33H12a1,1,0,0,0,0,2H23.4a7.576,7.576,0,0,1,4.218,1.277,1,1,0,1,0,1.11-1.664Z" />
          <path d="M52,33H40.6a9.581,9.581,0,0,0-5.328,1.613,1,1,0,0,0,1.11,1.664A7.581,7.581,0,0,1,40.6,35H52a1,1,0,0,0,0-2Z" />
          <path d="M28.723,39.613A9.579,9.579,0,0,0,23.4,38H12a1,1,0,0,0,0,2H23.4a7.576,7.576,0,0,1,4.218,1.277,1,1,0,1,0,1.11-1.664Z" />
          <path d="M52,38H40.6a9.581,9.581,0,0,0-5.328,1.613,1,1,0,0,0,1.11,1.664A7.581,7.581,0,0,1,40.6,40H52a1,1,0,0,0,0-2Z" />
          <path d="M23.4,43H12a1,1,0,0,0,0,2H23.4a7.576,7.576,0,0,1,4.218,1.277,1,1,0,1,0,1.11-1.664A9.579,9.579,0,0,0,23.4,43Z" />
          <path d="M52,43H40.6a9.581,9.581,0,0,0-5.328,1.613,1,1,0,0,0,1.11,1.664A7.581,7.581,0,0,1,40.6,45H52a1,1,0,0,0,0-2Z" />
        </g>
      </g>
    </Wrapper>
  )
}

const Wrapper = styled.svg`
  height: 100px;
  width: 100px;
`

export default Courses
