import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import React, { Component, RefObject } from "react"
import styled from "styled-components"

import FontAwesome from "../../../helpers/components/FontAwesome"
import QuestionTooltip from "../../../helpers/components/QuestionTooltip"
import { StudentCourseStatus } from "../../../types/Global-Types"
import { GetMyCourse_myStudentCourse } from "../../queries/types/GetMyCourse"
import FixedTopElement from "./FixedTopElement"
import PauseButton from "./PauseButton"
import TimeElapsed from "./TimeElapsed"

interface Props {
  activeLessonId: string | null
  studentCourse: GetMyCourse_myStudentCourse
  secondsRequired: number
  timeElapsedRef: RefObject<TimeElapsed>
  shouldPoll: boolean
  onSave: (redirect: boolean) => void
  setActiveLesson: (lessonId: string | null) => void
  allowCourseFinish: () => void
}

class ProgressWrapper extends Component<Props> {
  renderTimeElapsed() {
    const { timeElapsed, status } = this.props.studentCourse
    const secondary = (
      <TimeElapsed
        allowCourseFinish={this.props.allowCourseFinish}
        ref={this.props.timeElapsedRef}
        timeElapsed={timeElapsed}
        timeRequired={this.props.secondsRequired}
        disabled={
          status === StudentCourseStatus.FINISHED || !this.props.shouldPoll
        }
      />
    )
    const primary = (
      <>
        Time Elapsed{" "}
        <QuestionTooltip title="The Department of Licensing and Regulatory Affairs requires online continuing education schools to have a timer" />
      </>
    )
    return <ListItemText primary={primary} secondary={secondary} />
  }

  renderLessonList() {
    const {
      activeLessonId,
      setActiveLesson,
      studentCourse: {
        course: { lessons },
      },
    } = this.props

    return (
      <List>
        <ListItem button onClick={() => setActiveLesson(null)}>
          <LessonTitle
            primary={
              activeLessonId === null ? <b>Introduction</b> : "Introduction"
            }
          />
        </ListItem>
        {lessons.map((lesson, index) => {
          const title = `Lesson: ${index + 1}: ${lesson.title}`
          const disabled = !this.isLessonUnlocked(lesson.id)
          const activeLesson = lesson.id === this.props.activeLessonId
          const checkmark = this.shouldDisplayCheckmark(lesson.id) ? (
            <FontAwesome icon="fa fa-check" gapRight />
          ) : null
          const primary = (
            <>
              {checkmark}
              {activeLesson ? <b>{title}</b> : title}
            </>
          )
          return (
            <ListItem
              button
              dense
              disabled={disabled}
              key={index}
              onClick={() => setActiveLesson(lesson.id)}
            >
              <LessonTitle
                primary={primary}
                primaryTypographyProps={{
                  color: activeLesson ? "primary" : "initial",
                }}
              />
            </ListItem>
          )
        })}
      </List>
    )
  }

  renderLessonProgress() {
    return (
      <ListItemText
        primary="Lesson Progress"
        secondary={this.renderLessonList()}
        secondaryTypographyProps={{ component: "div" }}
      />
    )
  }

  renderPauseButton() {
    return (
      <PauseButton
        shouldPoll={this.props.shouldPoll}
        onSave={this.props.onSave}
      />
    )
  }

  render() {
    return (
      <FixedTopElement>
        <Card elevation={1}>
          <CardContent>
            <List>
              <ListItem>{this.renderTimeElapsed()}</ListItem>
              <Divider component="li" />
              <ListItem>{this.renderLessonProgress()}</ListItem>
              <Divider component="li" />
              <ListItem>{this.renderPauseButton()}</ListItem>
            </List>
          </CardContent>
        </Card>
      </FixedTopElement>
    )
  }

  private isLessonUnlocked(lessonId: string) {
    const {
      course: { lessons },
      lastSubmissions,
    } = this.props.studentCourse

    const hasSubmission = lastSubmissions.find(
      (submission) => submission.lesson.id === lessonId
    )
    if (hasSubmission) {
      return true
    }

    const allSubmissionsCorrect = !lastSubmissions.find(
      (submission) => !!submission.answers.find((answer) => !answer.correct)
    )
    if (!allSubmissionsCorrect) {
      return false
    }

    const firstUnsubmittedLesson = lessons.find(
      (lesson) =>
        !lastSubmissions.find(
          (submission) => submission.lesson.id === lesson.id
        )
    )
    return firstUnsubmittedLesson?.id === lessonId
  }

  private shouldDisplayCheckmark(lessonId: string) {
    const { lastSubmissions } = this.props.studentCourse

    const hasSubmission = lastSubmissions.find(
      (submission) =>
        submission.lesson.id === lessonId &&
        !submission.answers.find((answer) => !answer.correct)
    )
    return hasSubmission
  }
}

const LessonTitle = styled(ListItemText)`
  margin: 0;
  span {
    font-size: 12px;
  }
`

export default ProgressWrapper
