import Button from "@material-ui/core/Button"
import React from "react"
import styled from "styled-components"

import RouterLink from "../../../helpers/components/RouterLink"
import { routes } from "../../../routes/constants/routes"
import { StudentCourseStatus } from "../../../types/Global-Types"

interface Props {
  studentCourseId: string
  studentCourseStatus: StudentCourseStatus
}

const getRouterLink = (
  studentCourseId: string,
  studentCourseStatus: StudentCourseStatus
): string | null => {
  switch (studentCourseStatus) {
    case StudentCourseStatus.FINISHED:
      return routes.APP.COURSE.replace(":id", studentCourseId)
    case StudentCourseStatus.NOT_STARTED:
    case StudentCourseStatus.IN_PROGRESS:
    case StudentCourseStatus.EXPIRED:
    default:
      return null
  }
}

const getButtonText = (status: StudentCourseStatus) => {
  switch (status) {
    case StudentCourseStatus.FINISHED:
      return "View Course"
    case StudentCourseStatus.NOT_STARTED:
    case StudentCourseStatus.IN_PROGRESS:
    case StudentCourseStatus.EXPIRED:
    default:
      return null
  }
}

const SecondaryButton = ({ studentCourseId, studentCourseStatus }: Props) => {
  const routerLink = getRouterLink(studentCourseId, studentCourseStatus)
  const text = getButtonText(studentCourseStatus)

  if (!routerLink) {
    return null
  }

  return (
    <RouterLink to={routerLink}>
      <StyledButton variant="contained">{text}</StyledButton>
    </RouterLink>
  )
}

const StyledButton = styled(Button)`
  font-size: 12px;
  text-transform: none;
  margin-right: 8px;
`

export default SecondaryButton
