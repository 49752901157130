import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React from "react"

import RouterLink from "../../../helpers/components/RouterLink"
import StyledHTML from "../../../helpers/components/StyledHTML"
import { routes } from "../../../routes/constants/routes"
import { Dashboard_mostRecentCourses } from "../../queries/types/Dashboard"
import RecentCourseList from "./RecentCourseList"

interface Props {
  zeroStateText?: string
  mostRecentCourses: Dashboard_mostRecentCourses[]
}

const RecentCourseWrapper = ({ mostRecentCourses, zeroStateText }: Props) => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        <b>Recent Courses</b>
      </Typography>
      <Card elevation={1}>
        <CardContent>
          {mostRecentCourses.length === 0 ? (
            <Typography component="div" gutterBottom>
              <StyledHTML html={zeroStateText || ""} />
            </Typography>
          ) : (
            <>
              <RecentCourseList mostRecentCourses={mostRecentCourses} />
              <Typography align="center">
                <RouterLink to={routes.APP.MY_COURSES} blue>
                  View All Purchased Courses
                </RouterLink>
              </Typography>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default RecentCourseWrapper
