import { connect } from "react-redux"

import { isLoading } from "../../helpers/selectors"
import withPageTitle from "../../routes/containers/withPageTitle"
import { State } from "../../store"
import { registerUser } from "../actions/registerUser"
import { saveProfile } from "../actions/saveProfile"
import RegisterWrapper, {
  Props,
} from "../components/registration/RegisterWrapper"
import { getCurrentUser } from "../selectors/currentUserGetters"
import { getStatus } from "../selectors/getStatus"
import { getUserErrors } from "../selectors/getUserErrors"
import {
  getEmailError,
  getPasswordError,
} from "../selectors/registrationGetters"

const mapStateToProps = (state: State): Partial<Props> => ({
  currentUser: getCurrentUser(state),
  emailInvalidError: getEmailError(state.auth),
  passwordInvalidError: getPasswordError(state.auth),
  isLoading: isLoading(state.auth),
  status: getStatus(state),
  errors: getUserErrors(state),
})

const mapDispatchToProps = {
  registerUser,
  saveUser: saveProfile,
}

export default withPageTitle("Register")(
  connect(mapStateToProps, mapDispatchToProps)(RegisterWrapper)
)
