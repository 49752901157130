export enum States {
  AK = "AK",
  AL = "AL",
  AZ = "AZ",
  AR = "AR",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  IA = "IA",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  ME = "ME",
  MD = "MD",
  MA = "MA",
  MI = "MI",
  MN = "MN",
  MS = "MS",
  MO = "MO",
  MT = "MT",
  NE = "NE",
  NV = "NV",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NY = "NY",
  NC = "NC",
  ND = "ND",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

const stateMap: { [key: string]: States } = {
  Arizona: States.AZ,
  Alabama: States.AL,
  Alaska: States.AK,
  Arkansas: States.AR,
  California: States.CA,
  Colorado: States.CO,
  Connecticut: States.CT,
  Delaware: States.DE,
  Florida: States.FL,
  Georgia: States.GA,
  Hawaii: States.HI,
  Idaho: States.ID,
  Illinois: States.IL,
  Indiana: States.IN,
  Iowa: States.IA,
  Kansas: States.KS,
  Kentucky: States.KY,
  Louisiana: States.LA,
  Maine: States.ME,
  Maryland: States.MD,
  Massachusetts: States.MA,
  Michigan: States.MI,
  Minnesota: States.MN,
  Mississippi: States.MS,
  Missouri: States.MO,
  Montana: States.MT,
  Nebraska: States.NE,
  Nevada: States.NV,
  "New Hampshire": States.NH,
  "New Jersey": States.NJ,
  "New Mexico": States.NM,
  "New York": States.NY,
  "North Carolina": States.NC,
  "North Dakota": States.ND,
  Ohio: States.OH,
  Oklahoma: States.OK,
  Oregon: States.OR,
  Pennsylvania: States.PA,
  "Rhode Island": States.RI,
  "South Carolina": States.SC,
  "South Dakota": States.SD,
  Tennessee: States.TN,
  Texas: States.TX,
  Utah: States.UT,
  Vermont: States.VT,
  Virginia: States.VA,
  Washington: States.WA,
  "West Virginia": States.WV,
  Wisconsin: States.WI,
  Wyoming: States.WY,
}

export const states = Object.keys(States) as [keyof typeof States]

export const getStates = (value: string) => {
  value = value.toLowerCase()
  return Object.keys(stateMap)
    .filter((key) => {
      const kvalue = stateMap[key]
      return (
        key.toLowerCase().includes(value) ||
        kvalue?.toLowerCase().includes(value)
      )
    })
    .map((key) => stateMap[key])
}
