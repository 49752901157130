import "./index.css"

import CssBaseline from "@material-ui/core/CssBaseline"
import React from "react"
import ReactDOM from "react-dom"
import Helmet from "react-helmet"
import { Provider } from "react-redux"

import App from "./App"
import ErrorBoundary from "./errors/components/ErrorBoundary"
import GraphQLProvider from "./graphql/provider/GraphQLProvider"
import * as serviceWorker from "./serviceWorker"
import { store } from "./store"
import Theme from "./Theme"

const Root = (
  <GraphQLProvider>
    <Provider store={store}>
      <ErrorBoundary>
        <Helmet>
          <title>123 ConEd</title>
          <meta
            name="description"
            content="With 123 ConEd LLC, it's all about time management. We know your time is precious. Learn more about our Michigan real estate continuing education online classes"
          />
        </Helmet>
        <Theme>
          <CssBaseline>
            <App />
          </CssBaseline>
        </Theme>
      </ErrorBoundary>
    </Provider>
  </GraphQLProvider>
)

ReactDOM.render(Root, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
