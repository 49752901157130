import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"

import { combineObjects } from "../../auth/operators/combineObjects"
import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"
import ProfileEditorForm from "./ProfileEditorForm"

interface Props {
  profile: User
  isLoading: boolean
  errors: RestError
  saveProfile: (profile: User) => void
}

interface State {
  showBlurb: boolean
}

class ProfileEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showBlurb: false,
    }
  }

  componentDidUpdate(prevProps: Props) {
    const doneSaving = prevProps.isLoading && !this.props.isLoading
    const noErrors =
      !this.props.errors || Object.keys(this.props.errors).length === 0
    if (doneSaving && noErrors && !this.state.showBlurb) {
      this.setState({
        showBlurb: true,
      })
    }
  }

  renderBlurb() {
    return (
      <Typography>
        Your profile has been saved successfully. To see your courses, you may
        navigate to the{" "}
        <RouterLink to={routes.APP.MY_COURSES} blue>
          My Courses
        </RouterLink>{" "}
        tab
      </Typography>
    )
  }

  render() {
    const { errors, isLoading, profile } = this.props
    return (
      <Card elevation={1}>
        <CardContent>
          <ProfileEditorForm
            currentUser={profile}
            requestLoading={isLoading}
            onSubmit={this.onSubmit}
            errors={errors || {}}
          />
          {this.state.showBlurb && this.renderBlurb()}
        </CardContent>
      </Card>
    )
  }

  private readonly onSubmit = (user: User) => {
    const existingDetails: User = { ...(this.props.profile || ({} as User)) }
    const combinedDetails: User = combineObjects(existingDetails, user)
    this.props.saveProfile(combinedDetails)
  }
}

export default ProfileEditor
