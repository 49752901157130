import { History, Location } from "history"
import { createSelector } from "reselect"

const getLocation = (_: unknown, { history }: { history: History }) =>
  history.location

export const getPathname = createSelector(
  getLocation,
  (location: Location) => location?.pathname
)
