import { connect } from "react-redux"

import { saveProfile } from "../../auth/actions/saveProfile"
import {
  getCurrentUser,
  isCurrentUserLoading,
} from "../../auth/selectors/currentUserGetters"
import { getUserErrors } from "../../auth/selectors/getUserErrors"
import { State } from "../../store"
import ProfileEditor from "../components/ProfileEditor"

const mapStateToProps = (state: State) => ({
  profile: getCurrentUser(state),
  isLoading: isCurrentUserLoading(state),
  errors: getUserErrors(state),
})

const mapDispatchToProps = {
  saveProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditor)
