import { createSelector } from "reselect"

import { AuthState } from "../reducers"

const loginError = (state: AuthState) => state.loginError

export const getLoginError = createSelector(
  [loginError],
  (error: string | undefined) => error
)
