import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core/styles/createMuiTheme"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

import DraggableHandle from "./DraggableHandle"

interface Props {
  title: string
  draggable?: boolean
  children: React.ReactNode
  primary?: boolean
  expanded?: boolean
  defaultExpanded?: boolean
  mountOnEnter?: boolean
  unmountOnExit?: boolean
}

interface StyleProps {
  primary: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: "16px !important",
  },
  summary: (props: StyleProps) => ({
    background: props.primary
      ? theme.palette.primary.main
      : theme.palette.grey[600],
    borderRadius: 3,
  }),
  summaryExpanded: {
    borderRadius: "3px 3px 0 0",
  },
  white: {
    color: "#fff",
  },
}))

const ExpandingContent = ({
  title,
  children,
  expanded,
  defaultExpanded,
  mountOnEnter = false,
  unmountOnExit = true,
  primary = true,
  draggable = false,
}: Props) => {
  const { summary, summaryExpanded, white, root } = useStyles({ primary })
  return (
    <ExpansionPanel
      classes={{ root }}
      elevation={0}
      TransitionProps={{
        mountOnEnter,
        unmountOnExit,
        appear: defaultExpanded,
      }}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
    >
      <ExpansionPanelSummary
        classes={{ root: summary, expanded: summaryExpanded }}
        expandIcon={<ExpandMoreIcon className={white} />}
      >
        <Typography className={white}>
          {draggable && <DraggableHandle />}
          {title}
        </Typography>
      </ExpansionPanelSummary>
      {children}
    </ExpansionPanel>
  )
}

export default ExpandingContent
