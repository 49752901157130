import { getItem, setItem } from "../../helpers/operators/localStorage"

export interface FilterOptions {
  law: boolean
  nonLaw: boolean
  completed: boolean
  inProgress: boolean
  notStarted: boolean
}
const DEFAULT: FilterOptions = {
  law: true,
  nonLaw: true,
  completed: true,
  inProgress: true,
  notStarted: true,
}
const LOCAL_STORAGE_KEY = "MY_COURSES_FILTER"

export const getFilterOptions = (): FilterOptions => {
  const initialState = getItem(LOCAL_STORAGE_KEY) || "{}"
  const options = { ...DEFAULT, ...JSON.parse(initialState) }
  setFilterOptions(options)
  return options
}

export const setFilterOptions = (filterOptions: FilterOptions) => {
  setItem(LOCAL_STORAGE_KEY, JSON.stringify(filterOptions))
}
