import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress"
import React from "react"
import styled from "styled-components"

interface Props {
  isLoading: boolean
  large?: boolean
  loadingElement?: JSX.Element
}

class LoadingWrapper extends React.Component<Props> {
  render() {
    const { isLoading, loadingElement, large, children } = this.props
    return isLoading ? (
      <ContentWrapper large={Boolean(large)}>
        <ProgressWrapper>
          <StyledCircularProgress
            large={Boolean(large)}
            disableShrink={large}
          />
        </ProgressWrapper>
        <ElementWrapper large={Boolean(large)}>
          {loadingElement ? loadingElement : null}
        </ElementWrapper>
      </ContentWrapper>
    ) : (
      children
    )
  }
}

const SMALL_HEIGHT = 64
const LARGE_HEIGHT = 128

const ProgressWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

interface StyledProps {
  large: boolean
}

const StyledCircularProgress = styled(
  ({ large, ...rest }: StyledProps & CircularProgressProps) => (
    <CircularProgress {...rest} />
  )
)`
  ${({ large }: StyledProps) => `
    width: ${large ? LARGE_HEIGHT : SMALL_HEIGHT}px !important;
    height: ${large ? LARGE_HEIGHT : SMALL_HEIGHT}px !important;
  `}
`

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  ${({ large }: StyledProps) => `
    min-height: ${large ? LARGE_HEIGHT : SMALL_HEIGHT}px;
  `}
`

const ElementWrapper = styled.div`
  margin-top: 4px;
  ${({ large }: StyledProps) => `
    padding-top: ${(large ? LARGE_HEIGHT : SMALL_HEIGHT) * 1.5}px;
  `}
`

export default LoadingWrapper
