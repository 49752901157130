import MuiDialog, { DialogProps } from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"
import styled from "styled-components"

type DialogEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

interface Props extends DialogProps {
  onClose: (event: DialogEvent, reason?: string) => void
  whiteIcon?: boolean
}

const Dialog = ({ onClose, children, whiteIcon, ...rest }: Props) => {
  return (
    <RelativeDialog onClose={onClose} {...rest}>
      {onClose ? (
        whiteIcon ? (
          <AbsoluteWhiteIconButton
            aria-label="close"
            onClick={(event: DialogEvent) => onClose(event, "backdropClick")}
          >
            <CloseIcon />
          </AbsoluteWhiteIconButton>
        ) : (
          <AbsoluteIconButton
            aria-label="close"
            onClick={(event: DialogEvent) => onClose(event, "backdropClick")}
          >
            <CloseIcon />
          </AbsoluteIconButton>
        )
      ) : null}
      {children}
    </RelativeDialog>
  )
}

const RelativeDialog = styled(MuiDialog)`
  position: relative;
`
const AbsoluteIconButton = styled(IconButton)`
  position: absolute;
  right: 16px;
  top: 16px;
`
const AbsoluteWhiteIconButton = styled(AbsoluteIconButton)`
  color: #fff;
`

export default Dialog
