import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React, { Component } from "react"
import { Query, QueryResult } from "react-apollo"
import { RouteComponentProps } from "react-router"
import styled from "styled-components"

import FullwidthGrid from "../../helpers/components/FullwidthGrid"
import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import RouterLink from "../../helpers/components/RouterLink"
import { getPathParams } from "../../helpers/operators/getPathParams"
import { missingProfileDetails } from "../../models/user/operators/missingProfileDetails"
import { getCourseTypeString } from "../../my-courses/operators/getFriendlyCourseInfo"
import { routes } from "../../routes/constants/routes"
import TitledWrapper from "../../sidebar/components/TitledWrapper"
import { history } from "../../store"
import { shouldShowCourseNumber } from "../operators/shouldShowCourseNumber"
import getFinishedCourse from "../queries/getFinishedCourse"
import {
  GetFinishedCourse,
  GetFinishedCourse_myStudentCourse,
  GetFinishedCourseVariables,
} from "../queries/types/GetFinishedCourse"
import ViewCertificateButton from "./ViewCertificateButton"

interface Props extends RouteComponentProps {
  currentUser: User | null
}

interface State {
  firefoxDialogOpen: boolean
}

/**
 * Responsible for rendering a preview of a completed course certificate. Will show the same
 * details that we display in the PDF template with options to either view or directly download
 * the PDF template.
 */
class CourseCertificate extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      firefoxDialogOpen: false,
    }
    this.renderLoadingContent = this.renderLoadingContent.bind(this)
  }

  renderCertificateButtons(studentCourse: GetFinishedCourse_myStudentCourse) {
    const { currentUser } = this.props
    if (!currentUser || missingProfileDetails(currentUser)) {
      return (
        <RouterLink to={routes.AUTH.PROFILE}>
          <Button fullWidth variant="outlined" color="secondary">
            Complete profile before obtaining a certificate
          </Button>
        </RouterLink>
      )
    }
    return (
      <FullwidthGrid item>
        <ViewCertificateButton
          href={
            this.getPDFUrl(studentCourse.id) +
            `/${this.getDownloadName(studentCourse.course.title)}`
          }
        />
        <Button
          color="primary"
          variant="outlined"
          href={this.getPDFUrl(studentCourse.id)}
          download={this.getDownloadName(studentCourse.course.title)}
        >
          Save Certificate
        </Button>
      </FullwidthGrid>
    )
  }

  renderCourseInfo(studentCourse: GetFinishedCourse_myStudentCourse) {
    const showCourseNumber = shouldShowCourseNumber(studentCourse.dateCompleted)
    return (
      <div>
        <MetaContainer>
          <Typography variant="h4" align="center" gutterBottom>
            Course Completed
          </Typography>
          <Typography align="center">
            You have successfully completed the following course
          </Typography>
        </MetaContainer>
        <Typography align="center" variant="h6">
          {studentCourse.course.title}
        </Typography>
        <Grid container justify="center">
          <Grid item md>
            <List>
              <ListItemRight>
                <ListItemText
                  primary="Number of Credit Hours"
                  secondary={studentCourse.course.hours}
                />
              </ListItemRight>
              <ListItemRight>
                <ListItemText
                  primary={showCourseNumber ? "Course Number" : "Course Code"}
                  secondary={
                    showCourseNumber
                      ? studentCourse.course.number
                      : studentCourse.course.code
                  }
                />
              </ListItemRight>
            </List>
          </Grid>
          <Grid item md>
            <List>
              <ListItemLeft>
                <ListItemText
                  primary="Course Type"
                  secondary={getCourseTypeString(
                    studentCourse.course.courseType
                  )}
                />
              </ListItemLeft>
              <ListItemLeft>
                <ListItemText
                  primary="Date Completed"
                  secondary={moment(studentCourse.dateCompleted).format(
                    "MMMM D YYYY"
                  )}
                />
              </ListItemLeft>
            </List>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ textAlign: "center" }}>
          {this.renderCertificateButtons(studentCourse)}
          <FullwidthGrid item>
            <Button variant="contained" onClick={this.navigateToMyCourses}>
              Return to My Courses
            </Button>
          </FullwidthGrid>
        </Grid>
      </div>
    )
  }

  renderLoadingContent({
    data,
    loading,
    error,
  }: QueryResult<GetFinishedCourse, GetFinishedCourseVariables>) {
    const errorMessage =
      error &&
      (error.graphQLErrors.map((obj) => obj.message).join(". ") ||
        error.message)
    return (
      <LoadingWrapper isLoading={loading}>
        <CardContent>
          {data?.myStudentCourse
            ? this.renderCourseInfo(data.myStudentCourse)
            : errorMessage || "Failed to load course certificate"}
        </CardContent>
      </LoadingWrapper>
    )
  }

  render() {
    return (
      <TitledWrapper title="Course Certificate">
        <Wrapper>
          <Card elevation={1}>
            <Query<GetFinishedCourse, GetFinishedCourseVariables>
              query={getFinishedCourse}
              variables={{ studentCourseId: this.getStudentCourseId() }}
            >
              {this.renderLoadingContent}
            </Query>
          </Card>
        </Wrapper>
      </TitledWrapper>
    )
  }

  private getStudentCourseId(): string {
    return getPathParams(this.props).id
  }

  private getPDFUrl(studentCourseId: string) {
    return `/api/course/${studentCourseId}/certificate`
  }

  private getDownloadName(title: string) {
    const sanitizedTitle = title.replace(/[^a-zA-Z0-9-_\s]/g, "_")
    const pdfName = `123 Con Ed Certificate - ${sanitizedTitle}.pdf`
    return encodeURIComponent(pdfName)
  }

  private navigateToMyCourses() {
    history.push(routes.APP.MY_COURSES)
  }
}

const Wrapper = styled.div`
  max-width: 600px;
`
const MetaContainer = styled.div`
  margin-bottom: 32px;
`
const ListItemRight = styled(ListItem as any)`
  && {
    text-align: right;
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: 794px) {
      text-align: center !important;
      width: 100%;
    }
  }
`
const ListItemLeft = styled(ListItemRight)`
  && {
    text-align: left;
    @media (max-width: 794px) {
      text-align: center !important;
      width: 100%;
    }
  }
`

export default CourseCertificate
