import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import ExpandingContent from "../../../helpers/components/ExpandingContent"
import StyledHTML from "../../../helpers/components/StyledHTML"
import { LAW_COLOR } from "../../../helpers/constants/lawColors"

interface Props {
  name: string
  content: string
}

const FAQGroup = ({ name, content }: Props) => {
  return (
    <ExpandingContent title={name}>
      <FAQPadding>
        <Typography variant="h6" align="left" gutterBottom>
          <b>{name}</b>
        </Typography>
        <Typography component="div">
          <StyledHTML html={content} />
        </Typography>
      </FAQPadding>
    </ExpandingContent>
  )
}

const FAQPadding = styled.div`
  padding: 1em;
  p {
    text-align: justify;
  }
  li {
    text-align: left;
  }
  a {
    color: ${LAW_COLOR};
    text-decoration: underline !important;
  }
`

export default FAQGroup
