import { gql } from "apollo-boost"

export default gql`
  query GetCart {
    shopCourseCart {
      id
      paymentIntent
      shopCourses {
        id
        course {
          id
          title
          hours
          price
          number
          code
          courseType
        }
      }
      discount {
        id
        code
        percentage
      }
    }
  }
`
