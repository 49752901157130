import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { APP, routes, SHOP_ROOT } from "../../routes/constants/routes"
import { history } from "../../store"
import AmberAlert from "./AmberAlert"

interface Props extends RouteComponentProps {
  shouldShow: boolean
}

const isWhitelistedRoute = (currentRoute: string) => {
  return (
    currentRoute.startsWith(APP) ||
    currentRoute.startsWith(SHOP_ROOT) ||
    currentRoute === routes.AUTH.PROFILE
  )
}

const MissingDetailsAlert = ({ shouldShow, location }: Props) => {
  const isWhitelisted = isWhitelistedRoute(location.pathname)
  const goToProfile = () => history.push(routes.AUTH.PROFILE)
  return (
    <AmberAlert shouldShow={shouldShow && isWhitelisted}>
      <Typography style={{ marginRight: 8 }}>
        Missing profile details required to complete your courses.
      </Typography>
      <Button variant="outlined" size="small" onClick={goToProfile}>
        Edit Profile
      </Button>
    </AmberAlert>
  )
}

export default MissingDetailsAlert
