import { connect } from "react-redux"
import { withRouter } from "react-router"

import { getCurrentUser } from "../../auth/selectors/currentUserGetters"
import withPageTitle from "../../routes/containers/withPageTitle"
import { State } from "../../store"
import CourseCertificate from "../components/CourseCertificate"

const mapStateToProps = (state: State) => {
  return {
    currentUser: getCurrentUser(state),
  }
}

export default withPageTitle("Course Certificate")(
  withRouter(connect(mapStateToProps)(CourseCertificate))
)
