import Grid from "@material-ui/core/Grid"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import RouterLink from "../../helpers/components/RouterLink"
import { getClasses as getShouldUseExtendedClasses } from "../operators/getClasses"
import { isActiveLink } from "../operators/isActiveLink"

interface Props extends WithStyles {
  navigationLinks: NavigationLink[]
  activeLink: string
  mobile?: boolean
}

class LinksNav extends React.PureComponent<Props> {
  render() {
    const { navigationLinks, classes, activeLink, mobile } = this.props
    const getClasses = (navLink: NavigationLink) =>
      getShouldUseExtendedClasses(isActiveLink(navLink, activeLink))(
        classes.navbarLink,
        classes.navbarLinkActive
      )
    return (
      <div>
        <Grid container spacing={5} wrap={mobile ? "wrap" : "nowrap"}>
          {navigationLinks.map((navLink, index) => {
            return (
              <Grid
                item
                key={index}
                xs={mobile && 12}
                className={mobile ? "" : classes.flexGrid}
              >
                <RouterLink to={navLink.url} color="textPrimary">
                  <Typography className={getClasses(navLink)}>
                    {navLink.text}
                  </Typography>
                </RouterLink>
              </Grid>
            )
          })}
        </Grid>
      </div>
    )
  }
}

const styles = {
  navbarLink: {
    textTransform: "uppercase" as any,
    color: "#222",
    whiteSpace: "nowrap" as any,
  },
  navbarLinkActive: {
    fontWeight: 700,
  },
  flexGrid: {
    display: "flex",
    alignItems: "center",
  },
}

export default withStyles(styles)(LinksNav)
