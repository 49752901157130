import { createSelector } from "reselect"

import { State } from "../../store"
import { NotificationState } from "../reducers"

const getState = (state: State) => state.notifications
export const getNotificationState = createSelector(
  getState,
  (state: NotificationState) => state
)
