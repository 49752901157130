import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import RouterLink from "../../../helpers/components/RouterLink"
import { routes } from "../../../routes/constants/routes"

const CTAWrapper = () => {
  const theme = useTheme()
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        <EmSpace>&emsp;</EmSpace>
      </Typography>
      <StyledCard background={theme.palette.primary.main} elevation={1}>
        <CardContent>
          <Typography variant="h5" align="center">
            Need More Con Ed Hours?{" "}
            <RouterLink to={routes.SHOP.COURSE_LIST}>Shop here!</RouterLink>
          </Typography>
        </CardContent>
      </StyledCard>
    </div>
  )
}

const EmSpace = styled.b`
  pointer-events: none;
  opacity: 0;
`
const StyledCard = styled(Card)`
  background: ${({ background }: { background: string }) => background};
  color: #fff;
  && a {
    text-decoration: underline;
  }
`

export default CTAWrapper
