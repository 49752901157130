import { connect } from "react-redux"

import { hasFailed } from "../../helpers/selectors/hasFailed"
import { isLoading } from "../../helpers/selectors/isLoading"
import withOnSaveMessage from "../../notifications/containers/withOnSaveMessage"
import withPageTitle from "../../routes/containers/withPageTitle"
import { State } from "../../store"
import { passwordResetRequest } from "../actions/passwordResetRequest"
import { resetStatus } from "../actions/resetStatus"
import RequestWrapper from "../components/password/RequestWrapper"

const mapStateToProps = (state: State) => ({
  isLoading: isLoading(state.auth),
  resetFailed: hasFailed(state.auth),
})

const mapDispatchToProps = {
  onPasswordReset: passwordResetRequest,
  resetStatus,
}

const ConnectedPasswordResetRequest = connect(
  mapStateToProps,
  mapDispatchToProps
)(withOnSaveMessage(RequestWrapper))

export default withPageTitle("Reset Password")(ConnectedPasswordResetRequest)
