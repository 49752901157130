import { green } from "@material-ui/core/colors"
import Icon from "@material-ui/core/Icon"
import { Theme } from "@material-ui/core/styles/createMuiTheme"
import MaterialCheckIcon from "@material-ui/icons/CheckCircle"
import MaterialErrorIcon from "@material-ui/icons/Error"
import { makeStyles } from "@material-ui/styles"
import React from "react"

interface Props {
  count: number
  errorState: boolean
}

const NotificationIcon = ({ count, errorState }: Props) => {
  const { singleIcon, multiIcon } = useStyles({ errorState })

  if (!count) {
    return null
  }

  if (count === 1) {
    return errorState ? (
      <MaterialErrorIcon className={singleIcon} />
    ) : (
      <MaterialCheckIcon className={singleIcon} />
    )
  }

  return <Icon className={multiIcon}>{count}</Icon>
}

interface StyleProps {
  errorState: boolean
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  singleIcon: ({ errorState }) => ({
    backgroundColor: errorState ? theme.palette.error.dark : green[600],
    fontSize: 24,
    opacity: 0.9,
    marginRight: 16,
  }),
  multiIcon: ({ errorState }) => ({
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    borderRadius: 10,
    color: errorState ? theme.palette.error.dark : green[600],
    fontSize: 14,
    opacity: 0.9,
    marginRight: 16,
    textAlign: "center",
  }),
}))

export default NotificationIcon
