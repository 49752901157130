import { AnyAction, Dispatch } from "redux"

import ErrorBus from "../../errors/middleware/ErrorBus"
import { createErrorNotification } from "../../notifications/actions/createErrorNotification"

export const subscribeToErrors = (dispatch: Dispatch<AnyAction>) => {
  const onError = (errorMessage: string) => {
    dispatch(createErrorNotification(errorMessage))
  }
  ErrorBus.subscribe(onError)
}
