import { gql } from "apollo-boost"

export default gql`
  query LoadUserAgreementPage {
    userAgreementPage {
      created
      body
    }
  }
`
