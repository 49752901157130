import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import CloseIcon from "@material-ui/icons/Close"
import debounce from "lodash.debounce"
import MUIDataTable, {
  MUIDataTableProps,
  MUIDataTableColumnDef,
} from "mui-datatables"
import React, { memo } from "react"
import styled from "styled-components"

const DEFAULT_ROWS_PER_PAGE = [10, 20, 100]

type Props = MUIDataTableProps

interface CustomSearchProps {
  searchText: string
  handleSearch: any
  hideSearch: any
  options: any
}

const CustomSearch = memo(
  ({ searchText, handleSearch, hideSearch }: CustomSearchProps) => {
    const onChange = ({ target: { value } }: any) => {
      handleSearch(value)
    }
    return (
      <SearchWrapper>
        <TextField
          fullWidth
          label="Search"
          name="search"
          defaultValue={searchText}
          onChange={onChange}
          variant="outlined"
        />
        <IconButton onClick={hideSearch}>
          <CloseIcon />
        </IconButton>
      </SearchWrapper>
    )
  }
)

CustomSearch.displayName = "CustomSearch"

export type Columns = MUIDataTableColumnDef

class TableWrapper extends React.Component<Props> {
  render() {
    const { options = {}, ...rest } = this.props
    options.print = !!options.print
    options.elevation = 0
    options.responsive = "standard"
    options.rowsPerPage = options.rowsPerPage || 20
    options.rowsPerPageOptions =
      options.rowsPerPageOptions ||
      DEFAULT_ROWS_PER_PAGE.includes(options.rowsPerPage)
        ? DEFAULT_ROWS_PER_PAGE
        : [...DEFAULT_ROWS_PER_PAGE, options.rowsPerPage].sort()
    options.customSearchRender = (
      searchText: string,
      handleSearch,
      hideSearch,
      opt
    ) => (
      <CustomSearch
        searchText={searchText}
        handleSearch={debounce(handleSearch, 500, { maxWait: 1000 })}
        hideSearch={hideSearch}
        options={opt}
      />
    )

    const o = options as any
    o.fixedSelectColumn = true
    o.fixedHeader = true
    return <StyledDataTable options={options} {...rest} />
  }
}

const StyledDataTable = styled(MUIDataTable)`
  border: none;
  th > span > div {
    align-items: center;
  }
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

export default TableWrapper
