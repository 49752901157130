import { PaymentIntent } from "@stripe/stripe-js"
import { Action, handleActions } from "redux-actions"

import { CheckoutPayload, CheckoutStatus } from "../actions/checkout"
import { CHECKOUT_STATUS_CHANGE } from "../constants/ActionTypes"

export interface PartialPaymentIntent extends Partial<PaymentIntent> {
  id: string
}

export interface NamedStripeError extends stripe.Error {
  name: string
}

export interface CheckoutState {
  status: CheckoutStatus | null
  paymentIntent?: PartialPaymentIntent
  error?: NamedStripeError
}

const defaultState: CheckoutState = {
  status: null,
}

export const checkoutReducer = handleActions<CheckoutState>(
  {
    [CHECKOUT_STATUS_CHANGE](
      state: CheckoutState,
      action: Action<CheckoutPayload>
    ) {
      return action.payload || state
    },
  },
  defaultState
)
