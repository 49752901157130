import Button from "@material-ui/core/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { useState } from "react"

import Dialog from "../../helpers/components/Dialog"

interface Props {
  show: boolean
}

const WelcomeDialog = ({ show }: Props) => {
  const [open, setOpen] = useState(show)
  const hide = () => setOpen(false)
  return (
    <Dialog open={open} onClose={hide} maxWidth="md">
      <DialogTitle>Welcome!</DialogTitle>
      <DialogContent>
        <DialogContentText gutterBottom>
          Welcome to 123ConEd LLC, the leading online provider of continuing
          education courses to real estate professionals in Michigan.
        </DialogContentText>

        <DialogContentText gutterBottom>
          We&apos;re glad to see you here! The page you are currently on is your
          Dashboard. You will automatically be navigated to this page each time
          you log in, and you can check back here for helpful information
          regarding licensing cycles and recent purchases.
        </DialogContentText>

        <DialogContentText gutterBottom>
          You can navigate around the website using links in the sidebar on the
          left side of your screen or using links in the navigation menu at the
          top of the page. You may purchase courses by visting the shop using
          the &quot;Courses&quot; link above.
        </DialogContentText>

        <DialogContentText gutterBottom>
          If at any time you have questions, you can reach us by using the
          &quot;Contact Us&quot; link in either the navigation menu above or the
          footer below. Happy learning!
        </DialogContentText>

        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={hide}
          style={{ marginTop: 16 }}
        >
          Okay
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeDialog
