import { client } from "../../../graphql/client/client"
import getStudentCourse from "../../queries/getStudentCourse"
import {
  GetMyCourse,
  GetMyCourseVariables,
} from "../../queries/types/GetMyCourse"
import setActiveLesson from "../setActiveLesson"
import {
  SetActiveLesson,
  SetActiveLessonVariables,
} from "../types/SetActiveLesson"

export const updateActiveLesson = async ({
  studentCourseId,
  timeElapsed,
  lessonId,
}: SetActiveLessonVariables & GetMyCourseVariables) => {
  return client.mutate<SetActiveLesson, SetActiveLessonVariables>({
    mutation: setActiveLesson,
    variables: { studentCourseId, timeElapsed, lessonId },
    update: (store, { data }) => {
      if (data?.setActiveLesson?.ok) {
        const studentCourse = store.readQuery<
          GetMyCourse,
          GetMyCourseVariables
        >({ query: getStudentCourse, variables: { studentCourseId } })
        const newData = studentCourse?.myStudentCourse
        if (!newData) {
          return
        }

        // If the lesson ID was updated
        if (lessonId) {
          const activeLesson = newData.course.lessons.find(
            (lesson) => lesson.id === lessonId
          )
          newData.activeLesson = activeLesson || null
        }

        // If the time elapsed was update
        if (timeElapsed) {
          newData.timeElapsed = timeElapsed
        }

        store.writeQuery<GetMyCourse, GetMyCourseVariables>({
          query: getStudentCourse,
          variables: { studentCourseId },
          data: {
            myStudentCourse: newData,
            minimumDurationPercentage:
              studentCourse?.minimumDurationPercentage || 0,
          },
        })
      }
    },
  })
}
