import { routes } from "../../routes/constants/routes"

export const SidebarNavigationLinks: NavigationLink[] = [
  {
    text: "Dashboard",
    url: routes.APP.DASHBOARD,
    icon: "fa-home",
  },
  {
    text: "My Courses",
    url: routes.APP.MY_COURSES,
    icon: "fa-book",
  },
  {
    text: "My Purchases",
    url: routes.APP.MY_PURCHASES,
    icon: "fa-shopping-cart",
  },
  {
    text: "My Profile",
    url: routes.AUTH.PROFILE,
    icon: "fa-user-circle",
  },
]
