import Button from "@material-ui/core/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import React, { useMemo, useState } from "react"
import styled from "styled-components"

import Dialog from "../../../helpers/components/Dialog"

interface Props {
  currentLessonId: string | null
  hasSuccessfullyCompletedQuiz: boolean
  timeMet: boolean
}

const sessionHidden: { [key: string]: boolean } = {}

const MinimumTimeRequirementDialog = ({
  currentLessonId,
  hasSuccessfullyCompletedQuiz,
  timeMet,
}: Props) => {
  const [hide, setHide] = useState(
    timeMet || (currentLessonId && sessionHidden[currentLessonId])
  )
  const shouldShow = useMemo(() => {
    return !hide && !timeMet && hasSuccessfullyCompletedQuiz
  }, [hide, timeMet, hasSuccessfullyCompletedQuiz])

  const onClose = () => {
    setHide(true)
    // Hide dialog for the entire browser session
    if (currentLessonId) {
      sessionHidden[currentLessonId] = true
    }
  }

  return (
    <Dialog open={shouldShow} onClose={onClose}>
      <DialogTitle>Minimum Time Requirement Not Met</DialogTitle>
      <StyledDialogContent>
        <DialogContentText gutterBottom>
          Minimum time requirement not met. Please go back through the course
          material to add more time to your clock.
        </DialogContentText>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={onClose}
        >
          Okay
        </Button>
      </StyledDialogContent>
    </Dialog>
  )
}

const StyledDialogContent = styled(DialogContent)`
  overflow-y: hidden;
`

export default MinimumTimeRequirementDialog
