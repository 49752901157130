// https://github.com/apollographql/apollo-client/issues/6011
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-client"
import { ApolloLink, from } from "apollo-link"
import { onError } from "apollo-link-error"
import { createHttpLink } from "apollo-link-http"
import ApolloLinkTimeout from "apollo-link-timeout"
import Cookies from "js-cookie"

import { reportError } from "../../errors/api/reportError"
import ErrorBus from "../../errors/middleware/ErrorBus"
import { isDevelopment } from "../../helpers/operators/isDevelopment"

const csrfMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  }))
  return forward(operation)
})

const TIMEOUT_STATUS_CODE = 408
const timeoutLink = new ApolloLinkTimeout(20000, TIMEOUT_STATUS_CODE)
interface TimeoutError {
  timeout: number
  statusCode?: number
}

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    reportError(networkError, networkError)
    const n = networkError as Error | ApiError | TimeoutError
    if ((n as TimeoutError)?.statusCode === TIMEOUT_STATUS_CODE) {
      ErrorBus.dispatch("The network request timed out. Please try again", true)
    } else {
      ErrorBus.dispatch(networkError.message, true)
    }
    console.info("Network error detected")
    console.error(networkError)
  }
  if (graphQLErrors) {
    console.info("GraphQL errors detected")
    graphQLErrors.forEach((error) => {
      reportError(error, error)
      console.error(error)
      ErrorBus.dispatch(error.message, true)
    })
  }
})

const httpLink = createHttpLink({
  uri: isDevelopment() ? "http://localhost:3000/api/graphql" : "/api/graphql",
  credentials: "include",
})

/**
 * Wraps every query and mutation to avoid unhandled promise rejections.
 *
 * @param apolloClient the global Apollo client
 */
const safeClient = <T>(apolloClient: ApolloClient<T>): ApolloClient<T> => {
  const mutate = apolloClient.mutate
  apolloClient.mutate = (...args) => mutate(...args).catch((e) => e)
  const query = apolloClient.query
  apolloClient.query = (...args) => query(...args).catch((e) => e)
  return apolloClient
}

export const client = safeClient(
  new ApolloClient({
    link: from([
      errorLink,
      timeoutLink,
      csrfMiddleware,
      httpLink as ApolloLink,
    ]),
    cache: new InMemoryCache(),
  })
)
