import { gql } from "apollo-boost"

export default gql`
  mutation SetActiveLesson(
    $lessonId: ID
    $studentCourseId: ID!
    $timeElapsed: Int
  ) {
    setActiveLesson(
      studentCourseId: $studentCourseId
      lessonId: $lessonId
      timeElapsed: $timeElapsed
    ) {
      ok
    }
  }
`
