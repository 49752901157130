import withStyles from "@material-ui/core/styles/withStyles"
import Tab from "@material-ui/core/Tab"

const VerticalTab = withStyles(
  (theme) => ({
    selected: {
      color: theme.palette.primary.main,
      borderRight: "3px solid",
      borderColor: theme.palette.primary.main,
    },
  }),
  { name: "VerticalTab" }
)(Tab)

export default VerticalTab
