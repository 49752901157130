import React, { memo, useState } from "react"
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3"
import { useInterval } from "../../../hooks/useInterval"

interface Props {
  action: string
  onVerifyCaptcha: (token: string) => void
}

// Just under two minutes
const REFRESH_PERIOD = 115000

const CaptchaField = memo(({ action, onVerifyCaptcha }: Props) => {
  const [reactKey, setReactKey] = useState(new Date().toISOString())

  useInterval(() => {
    setReactKey(new Date().toISOString())
  }, REFRESH_PERIOD)

  const siteKey = process.env.REACT_APP_CAPTCHA_KEY
  if (!siteKey) {
    return null
  }
  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      <GoogleReCaptcha
        key={reactKey}
        action={action}
        onVerify={onVerifyCaptcha}
      />
    </GoogleReCaptchaProvider>
  )
})

CaptchaField.displayName = "CaptchaField"

export default CaptchaField
