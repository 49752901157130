import React from "react"

import { getStates, States, states } from "../../../constants/states"
import { BaseControlledProps } from "../core/ControlledProps"
import ControlledSelect, {
  ControlledSelectProps,
} from "../core/ControlledSelect"

interface FilterOptionsState {
  inputValue: string
  getOptionLabel: (option: States) => string
}

const StateSelectField = (
  props: Partial<ControlledSelectProps<States>> & BaseControlledProps
) => {
  const filterOptions = (_: States[], state: FilterOptionsState): States[] => {
    return getStates(state.inputValue)
  }
  return (
    <ControlledSelect<States>
      {...props}
      size="small"
      autoSelect
      textAutoComplete="address-level1"
      options={states as States[]}
      filterOptions={filterOptions}
    />
  )
}

export default StateSelectField
