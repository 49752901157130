import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import RouterLink from "../../../helpers/components/RouterLink"
import StyledHTML from "../../../helpers/components/StyledHTML"
import { getStaticFile } from "../../../helpers/operators/getStaticFile"
import { routes } from "../../../routes/constants/routes"
import CardImage from "./CardImage"

interface Props {
  title: string
  body: string
}

const MichiganWrapper = ({ title, body }: Props) => {
  return (
    <Wrapper>
      <Grid container spacing={6} alignItems="flex-start">
        <Grid item md={6}>
          <ImageOne>
            <CardImage src={getStaticFile("images/bridge.jpg")} height={350} />
          </ImageOne>
          <ImageTwo>
            <CardImage src={getStaticFile("images/cliffs.jpg")} height={350} />
          </ImageTwo>
        </Grid>
        <ElevatedGrid item md={6}>
          <Card elevation={1}>
            <PaddedCardContent>
              <Typography variant="h3" className="underline" color="primary">
                <b>{title}</b>
              </Typography>
              <Typography className="typography" component="div">
                <StyledHTML html={body} />
              </Typography>
              <RouterLink to={routes.SECONDARY_NAV.ABOUT}>
                <AboutUsButton color="primary" variant="contained" size="large">
                  About Us
                </AboutUsButton>
              </RouterLink>
            </PaddedCardContent>
          </Card>
        </ElevatedGrid>
      </Grid>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 50px 0;
`
const ElevatedGrid = styled(Grid)`
  z-index: 3;
`
const PaddedCardContent = styled(CardContent)`
  padding: 50px !important;
  @media screen and (max-width: 375px) {
    padding: 24px !important;
  }
`
const AboutUsButton = styled(Button)`
  width: 140px;
  margin-top: 32px;
  font-size: 16px;
`
const ImageOne = styled.div`
  margin-top: 50px;
  z-index: 1;
  @media screen and (max-width: 960px) {
    width: 960px;
    margin-top: 0;
    div {
      margin-right: 10%;
    }
  }
`
const ImageTwo = styled.div`
  margin-top: -75px;
  margin-left: 40%;
  margin-right: -50%;
  z-index: 2;
  @media screen and (max-width: 960px) {
    margin-top: -75px;
    margin-left: 25%;
    margin-right: 5%;
  }
`

export default MichiganWrapper
