import React from "react"
import { Redirect, Route, RouteProps } from "react-router"

import { routes } from "../constants/routes"

interface Props extends RouteProps {
  isAllowed: boolean
  anonymous?: boolean
  redirectRoute: string
}

export default class ProtectedRoute extends React.Component<Props> {
  render() {
    const {
      component,
      isAllowed,
      anonymous,
      redirectRoute,
      ...rest
    } = this.props
    const Component = component as any
    return (
      <Route
        {...rest}
        render={(restProps) =>
          isAllowed ? (
            <Component {...restProps} />
          ) : (
            <Redirect to={anonymous ? redirectRoute : routes.AUTH.LOGIN} />
          )
        }
      />
    )
  }
}
