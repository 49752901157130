import { api } from "../../helpers/api/handler"
import { buildNotificationError } from "../../notifications/operators/errorBuilder"

export const confirmEmail = (key: string) =>
  api
    .post<{ detail: string }>(
      "/auth/register/verify-email/",
      JSON.stringify({
        key,
      })
    )
    .then(({ detail }) => {
      return detail === "ok"
    })
    .catch(
      buildNotificationError(
        "Unable to confirm email address. Please try again later."
      )
    )
