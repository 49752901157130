import * as Sentry from "@sentry/browser"
import { Middleware } from "redux"
import { Action } from "redux-actions"

import { getCurrentUser } from "../../auth/selectors/currentUserGetters"

export const sentryMiddleware: Middleware = (store) => {
  let lastAction: Action<any>

  return (next) => (action) => {
    const state = store.getState()
    const user = getCurrentUser(state)

    Sentry.addBreadcrumb({
      category: "redux-action",
      data: {
        type: lastAction?.type,
        user,
      },
    })

    lastAction = action
    return next(action)
  }
}
