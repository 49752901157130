import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"
import styled from "styled-components"

import Divider from "../../../helpers/components/Divider"
import RouterLink from "../../../helpers/components/RouterLink"
import TableWrapper from "../../../helpers/components/TableWrapper"
import { routes } from "../../../routes/constants/routes"
import { getFriendlyPrice } from "../../../shop-courses/operators/getFriendlyPrice"
import { TransactionStatus } from "../../../types/Global-Types"
import { MyTransactions_myTransactions } from "../../queries/types/MyTransactions"
import MyPurchaseStatus from "./MyPurchaseStatus"

interface Props {
  transactions: MyTransactions_myTransactions[]
  setPaymentId: (referenceId: string, status: TransactionStatus) => void
}

const MyPurchasesList = ({ transactions, setPaymentId }: Props) => {
  if (transactions.length === 0) {
    return (
      <Typography>
        You have no purchase history associated with this account. If you&apos;d
        like to purchase a course,{" "}
        <RouterLink to={routes.SHOP.COURSE_LIST} blue>
          click here
        </RouterLink>
        .
      </Typography>
    )
  }

  const data = transactions
    .filter((transaction) => transaction.status !== TransactionStatus.FAILED)
    .map(({ id, date, netTotal, referenceId, status, refundTotal }, index) => [
      id,
      moment(date).format("YYYY-MM-DD"),
      getFriendlyPrice(netTotal),
      <MyPurchaseStatus
        key={index}
        referenceId={referenceId}
        status={status}
        netTotal={netTotal}
        refundTotal={refundTotal}
      />,
    ])

  const onRowClick = (
    _: unknown[],
    rowMeta: { rowIndex: number; dataIndex: number }
  ) => {
    const transaction = transactions[rowMeta.dataIndex]
    setPaymentId(transaction.referenceId, transaction.status)
  }

  return (
    <Wrapper>
      <Typography gutterBottom align="center">
        Your purchase history is located below. To view purchase details and
        display the receipt, you may click on the row.
      </Typography>
      <Divider />
      <TableWrapper
        title=""
        data={data}
        columns={[
          "Transaction ID",
          "Date Purchased",
          "Net Total",
          {
            name: "Transaction Status",
            options: {
              sort: false,
            },
          },
        ]}
        options={{
          rowsPerPage: 10,
          download: false,
          selectableRows: "none",
          search: false,
          sortOrder: {
            name: "Date Purchased",
            direction: "desc",
          },
          filter: false,
          viewColumns: false,
          setTableProps: () => ({
            size: "small",
          }),
          onRowClick,
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  th {
    font-weight: bold;
  }
  tr {
    cursor: pointer;
  }
`

export default MyPurchasesList
