import { routes } from "../../routes/constants/routes"

export const getSecondaryNavigationLinks = (): NavigationLink[] => {
  return [
    {
      text: "Home",
      url: routes.HOME,
    },
    {
      text: "About",
      url: routes.SECONDARY_NAV.ABOUT,
    },
    {
      text: "Courses",
      url: routes.SHOP.COURSE_LIST,
    },
    {
      text: "FAQ",
      url: routes.SECONDARY_NAV.FAQ,
    },
    {
      text: "Blog",
      url: routes.SECONDARY_NAV.BLOG,
    },
    {
      text: "Contact Us",
      url: routes.SECONDARY_NAV.CONTACT_US,
    },
  ]
}
