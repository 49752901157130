import { gql } from "apollo-boost"

export default gql`
  query Dashboard {
    cycleLicenseRequirements {
      cycleName
      yearlyHoursRequirement
      totalHoursRequirement
      startDate
      endDate
    }
    dashboardPage {
      calendarDaysText
      cycleDaysText
      cycleRangeText
      showCycleStats
      zeroStateBody
    }
    mostRecentCourses {
      id
      status
      course {
        id
        title
        courseType
        lessons {
          id
          order
        }
      }
      activeLesson {
        id
        order
      }
    }
  }
`
