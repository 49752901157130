import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import Divider from "../../helpers/components/Divider"
import PaddedGrid from "../../helpers/components/PaddedGrid"
import { GetMyCourses_myStudentCourses } from "../queries/types/GetMyCourses"
import CourseCard from "./cards/CourseCard"

type StudentCourse = GetMyCourses_myStudentCourses

interface Props {
  title: string
  studentCourses: StudentCourse[]
  divider?: boolean
}

const CoursesSection = ({ title, studentCourses, divider }: Props) => {
  if (studentCourses.length === 0) {
    return null
  }

  return (
    <>
      {divider && <Divider />}
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <PaddedGrid>
          {studentCourses.map((studentCourse, index) => {
            return <CourseCard key={index} studentCourse={studentCourse} />
          })}
        </PaddedGrid>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  padding: 1em 0;
`

export default CoursesSection
