import React from "react"

import { reportError } from "../api/reportError"

type Props = unknown
interface ComponentState {
  hasError: boolean
}
class ErrorBoundary extends React.Component<Props, ComponentState> {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch(error: Error, info: any) {
    // You can also log the error to an error reporting service
    reportError(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong</div>
    }
    return this.props.children
  }
}

export default ErrorBoundary
