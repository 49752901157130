import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import React, { useEffect, useState } from "react"

import {
  FilterOptions,
  getFilterOptions,
  setFilterOptions,
} from "../operators/filterOptions"

interface Props {
  onChange: (options: FilterOptions) => void
}

const optionDetails: { name: string; key: keyof FilterOptions }[] = [
  { name: "Law", key: "law" },
  { name: "Non-Law", key: "nonLaw" },
  { name: "Completed", key: "completed" },
  { name: "In Progress", key: "inProgress" },
  { name: "Not Started", key: "notStarted" },
]

const Filter = ({ onChange }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [options, setOptions] = useState(getFilterOptions())
  useEffect(() => {
    onChange(options)
    setFilterOptions(options)
  }, [options, onChange])

  const toggleOption = (key: keyof FilterOptions) => () => {
    setOptions({
      ...options,
      [key]: !options[key],
    })
  }
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="contained"
        color="primary"
      >
        Filter Courses (
        {Object.keys(options).filter((k) => (options as any)[k]).length})
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {optionDetails.map((option) => {
          return (
            <MenuItem key={option.key} disableRipple>
              <FormControlLabel
                control={
                  <Checkbox
                    name={option.key}
                    checked={options[option.key]}
                    onChange={toggleOption(option.key)}
                    color="primary"
                  />
                }
                label={option.name}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default Filter
