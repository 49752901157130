import { graphql } from "react-apollo"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import withPageTitle from "../../routes/containers/withPageTitle"
import { navigateToCourse } from "../actions/navigateToCourse"
import MyCourses from "../components/MyCourses"
import getMyCourses from "../queries/getMyCourses"
import { GetMyCourses } from "../queries/types/GetMyCourses"

const mapDispatchToProps = {
  navigateToCourse,
}

const connectedMyCourses = withRouter(
  connect(null, mapDispatchToProps)(MyCourses) as any
)

export default withPageTitle("My Courses")(
  graphql<unknown, GetMyCourses, unknown, unknown>(getMyCourses, {
    options: { fetchPolicy: "cache-and-network" },
  })(connectedMyCourses)
)
