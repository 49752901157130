import { useEffect, useRef } from "react"

type Callback = () => void

export const useInterval = (callback: Callback, delay: number | null) => {
  const savedCallback = useRef<Callback>()

  // Reassign reference to callback each time the property changes
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Returning the teardown function means this interval is cleared on unmount
  useEffect(() => {
    const tick = () => {
      const { current } = savedCallback
      if (current) {
        current()
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
