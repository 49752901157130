import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import React from "react"
import styled from "styled-components"

import { unimpersonate } from "../../auth/api/unimpersonate"
import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"

interface Props {
  user: User
  logout: () => void
  goToProfile: () => void
  fullWidth?: boolean
}

interface State {
  anchorEl: EventTarget | null
}

class ProfileDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  handleMenu = (event: React.MouseEvent) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  renderUnimpersonate() {
    if (!this.props.user.impersonating) {
      return null
    }
    const onClick = async () => {
      await unimpersonate()
      window.location.assign(routes.ADMIN_NAV.STUDENTS.LIST)
    }
    return <MenuItem onClick={onClick}>Stop Impersonating</MenuItem>
  }

  renderLogout() {
    const onClick = async () => {
      if (this.props.user.impersonating) {
        await unimpersonate()
      }
      this.props.logout()
      this.handleClose()
    }
    return <MenuItem onClick={onClick}>Log Out</MenuItem>
  }

  render() {
    const { user, fullWidth } = this.props
    const { anchorEl } = this.state
    const open = !!anchorEl

    return (
      <>
        <NameButton
          fullWidth={fullWidth}
          variant="outlined"
          color="primary"
          onClick={this.handleMenu}
        >
          {`${user.firstName || "User"} ${user.lastName || ""}`}
        </NameButton>
        <StyledMenu
          id="menu-appbar"
          anchorEl={anchorEl as any}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={this.handleClose}
        >
          <RouterLink to={routes.APP.DASHBOARD}>
            <MenuItem onClick={this.handleClose}>Dashboard</MenuItem>
          </RouterLink>
          <RouterLink to={routes.AUTH.PROFILE}>
            <MenuItem onClick={this.handleClose}>My Profile</MenuItem>
          </RouterLink>
          {this.renderUnimpersonate()}
          {this.renderLogout()}
        </StyledMenu>
      </>
    )
  }
}

const NameButton = styled(Button)`
  && {
    text-transform: none;
  }
`
const StyledMenu = styled(Menu)`
  && {
    margin-top: 20px;
  }
  ul {
    width: 200px;
  }
`

export default ProfileDropdown
