import styled from "styled-components"

const PaddedGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

export default PaddedGrid
