import Grid from "@material-ui/core/Grid"
import React, { ComponentProps, useEffect, useRef } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import styled from "styled-components"

interface Props extends RouteComponentProps<undefined>, ComponentProps<"div"> {}

const ScrollResetGrid = ({ children, location }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const { current } = ref
    if (current?.hasChildNodes()) {
      const firstChild = current.childNodes[0] as HTMLDivElement
      firstChild.scrollTop = 0
      firstChild.scrollLeft = 0
    }
  }, [location.pathname])
  return (
    <ScrollGrid item innerRef={ref}>
      {children}
    </ScrollGrid>
  )
}

const ScrollGrid = styled(Grid)`
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  width: 100%;
`

export default withRouter(ScrollResetGrid)
