import { ChildDataProps, graphql } from "react-apollo"
import { connect } from "react-redux"

import { setPaymentId } from "../../checkout/actions/setPaymentId"
import withPageTitle from "../../routes/containers/withPageTitle"
import MyPurchases from "../components/MyPurchases"
import getMyTransactions from "../queries/getMyTransactions"
import { MyTransactions } from "../queries/types/MyTransactions"

const mapDispatchToProps = {
  setPaymentId,
}

export default withPageTitle("My Purchases")(
  graphql<
    unknown,
    MyTransactions,
    unknown,
    ChildDataProps<unknown, MyTransactions>
  >(getMyTransactions)(connect(null, mapDispatchToProps)(MyPurchases))
)
