import { connect } from "react-redux"

import { getCurrentUser } from "../../auth/selectors/currentUserGetters"
import { createErrorNotification } from "../../notifications/actions/createErrorNotification"
import { createSuccessNotification } from "../../notifications/actions/createSuccessNotification"
import { State } from "../../store"
import ContactForm from "../components/ContactForm"

const mapStateToProps = (state: State) => ({
  user: getCurrentUser(state),
})

const mapDispatchToProps = {
  onSuccess: createSuccessNotification,
  onFailure: createErrorNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
