import Typography from "@material-ui/core/Typography"
import React from "react"
import { useForm } from "react-hook-form"

import PasswordField from "../../../input/components/v2/auth/PasswordField"
import { passwordValidate } from "../../../input/constants/validationPatterns"
import FormHolder from "../FormHolder"
import LoadingButton from "../LoadingButton"

interface Props {
  requestLoading: boolean
  requestError: boolean
  onSubmit: (data: State) => void
}

interface State {
  password: string
  confirmPassword: string
}

const ConfirmForm = ({ requestLoading, requestError, onSubmit }: Props) => {
  const { watch, handleSubmit, control, errors } = useForm<State>({
    mode: "onBlur",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  })

  return (
    <FormHolder onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Typography variant="h5" gutterBottom>
        Reset Password
      </Typography>
      <Typography gutterBottom>
        Choose a new password. Once it is successfully changed, you can log in
        to your account right away.
      </Typography>
      <PasswordField
        fullWidth
        showPhrase
        name="password"
        autoComplete="new-password"
        label="New Password"
        variant="outlined"
        margin="normal"
        control={control}
        errors={errors}
        error={Boolean(requestError)}
        helperText={
          requestError
            ? "There was an error resetting your password. Please try again."
            : undefined
        }
        rules={{ required: true, validate: passwordValidate }}
      />
      <PasswordField
        fullWidth
        name="confirmPassword"
        autoComplete="new-password"
        label="Confirm New Password"
        variant="outlined"
        margin="normal"
        control={control}
        errors={errors}
        rules={{
          required: true,
          validate: (data: any) =>
            watch("password") === data || "Passwords do not match",
        }}
      />
      <LoadingButton
        type="submit"
        disabled={requestLoading}
        isLoading={requestLoading}
        variant="contained"
        color="primary"
        size="large"
      >
        Reset Password
      </LoadingButton>
    </FormHolder>
  )
}

export default ConfirmForm
