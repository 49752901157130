import React from "react"
import { Route } from "react-router-dom"

import CourseCertificateContainer from "../../certificate/containers/CourseCertificateContainer"
import CourseTakerContainer from "../../course/containers/CourseTakerContainer"
import DashboardContainer from "../../dashboard/containers/DashboardContainer"
import MyCoursesContainer from "../../my-courses/containers/MyCoursesContainer"
import MyPurchasesContainer from "../../my-purchases/containers/MyPurchasesContainer"
import ProfileContainer from "../../profile/containers/ProfileContainer"
import { routes } from "../constants/routes"

export const AppRoutes = () => {
  return (
    <>
      <Route exact path={routes.APP.DASHBOARD} component={DashboardContainer} />
      <Route
        exact
        path={routes.APP.MY_COURSES}
        component={MyCoursesContainer}
      />
      <Route
        exact
        path={routes.APP.MY_PURCHASES}
        component={MyPurchasesContainer}
      />
      <Route exact path={routes.APP.COURSE} component={CourseTakerContainer} />
      <Route
        exact
        path={routes.APP.COURSE_CERTIFICATE}
        component={CourseCertificateContainer}
      />
      <Route exact path={routes.AUTH.PROFILE} component={ProfileContainer} />
    </>
  )
}
