import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import React from "react"
import { Controller } from "react-hook-form"

import {
  getErrorFromName,
  getErrorMessageFromFieldError,
} from "../../../operators/errorGetters"
import { ControlledProps } from "./ControlledProps"

export type ControlledTextFieldProps = ControlledProps<TextFieldProps>

const ControlledTextField = ({
  name,
  control,
  errors,
  rules,
  value,
  defaultValue,
  variant,
  helperText,
  error,
  onControlChange,
  ...rest
}: ControlledTextFieldProps) => {
  const fieldError = getErrorFromName(name, errors)
  const errorMessage = getErrorMessageFromFieldError(fieldError)
  return (
    <Controller
      as={
        <TextField
          name={name}
          required={rules?.required}
          variant={variant}
          value={value || defaultValue || ""}
          {...rest}
          helperText={errorMessage || helperText}
          error={Boolean(errorMessage) || error}
        />
      }
      name={name}
      control={control}
      onChange={onControlChange}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

export default ControlledTextField
