import Loadable from "react-loadable"
import Loading from "./Loader"

const loader = () =>
  import(
    /* webpackChunkName: "admin-routes" */ "../routes/components/AdminRoutes"
  ) as Promise<any>

const AsyncAdminRoutes = Loadable({
  loader,
  loading: Loading,
})

export default AsyncAdminRoutes
