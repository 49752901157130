import { api } from "../../helpers/api/handler"
import { convertToUser } from "../../models/user/serializers/User"
import { buildNotificationError } from "../../notifications/operators/errorBuilder"

export const getCurrentUser = () =>
  api
    .get("/auth/user/")
    .then((user: string | BE_User) => {
      if (typeof user === "string") {
        return null
      }
      return convertToUser(user)
    })
    .catch((err) => {
      // Don't throw on 403 -- they're logged out!
      if (err?.code === 403) {
        return null
      }
      throw err
    })
    .catch(
      buildNotificationError(
        "Unable to fetch profile details. Please try again later."
      )
    )
