import MomentUtils from "@date-io/moment"
import { createMuiTheme } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import React, { Component } from "react"

const rgb = "24, 79, 128"

const primary = `rgb(${rgb})`
const secondary = "#f50000"
export const overlayStart = `rgba(${rgb}, 1)`
export const overlayEnd = `rgba(${rgb}, 0.6)`
export const graphColor = `rgba(${rgb}, 0.7)`
export const projectedGraphColor = `rgba(${rgb}, 0.5)`
// The alpha 99 hack is to get around TinyMCE converting this to hex
export const textBoxBorder = `rgba(${rgb}, 0.99)`
export const textBoxBackground = `rgba(${rgb}, 0.25)`

export default class Theme extends Component<React.ComponentProps<"div">> {
  static getBreakpoints() {
    return createBreakpoints({})
  }

  getTheme() {
    return createMuiTheme({
      palette: {
        primary: {
          main: primary,
        },
        secondary: {
          main: secondary,
        },
        background: {
          default: "#eee",
        },
      },
      typography: {
        fontFamily: [
          "Lato",
          "Roboto",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
      props: {
        MuiCard: {
          elevation: 0,
        },
        MuiGrid: {
          spacing: 3,
        },
        MuiInput: {
          fullWidth: true,
        },
        MuiTab: {
          color: "primary",
          textColor: "primary",
        },
        MuiTabs: {
          color: "primary",
          textColor: "primary",
          variant: "scrollable",
        },
        MuiTextField: {
          variant: "outlined",
          fullWidth: true,
          margin: "normal",
        },
      },
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            color: primary,
            fontFamily: '"Quattrocento", sans-serif',
            backgroundColor: "#fff",
          },
        },
        MuiButton: {
          root: {
            borderRadius: "8px",
            fontFamily: '"Quattrocento", sans-serif',
            fontSize: 16,
            fontWeight: 600,
            textTransform: "none",
          },
          contained: {
            boxShadow: "none !important",
          },
          outlined: {
            borderWidth: 2,
          },
          outlinedPrimary: {
            border: `2px solid ${primary} !important`,
          },
          outlinedSecondary: {
            border: `2px solid ${secondary} !important`,
          },
        },
        MuiCard: {
          root: {
            overflow: "inherit",
            border: "1px solid #dddddd",
            borderRadius: 8,
          },
        },
        MuiCardContent: {
          root: {
            padding: 32,
            [Theme.getBreakpoints().down("sm")]: {
              padding: 12,
            },
          },
        },
        MuiDialog: {
          paper: {
            padding: 24,
          },
        },
        MuiDialogContent: {
          root: {
            overflowY: "initial",
          },
        },
        MuiExpansionPanelDetails: {
          root: {
            padding: 24,
          },
        },
        MuiGrid: {
          root: {
            minWidth: 0,
            "& > div": {
              maxWidth: "100%",
            },
          },
        },
        MuiIcon: {
          root: {
            fontSize: 14,
          },
        },
        MuiInputLabel: {
          outlined: {
            transform: "translate(12px, 14px) scale(1)",
          },
        },
        MuiOutlinedInput: {
          input: {
            padding: "14px 18px",
          },
          notchedOutline: {
            borderRadius: 4,
          },
        },
        MuiToolbar: {
          root: {
            background: "#fff",
          },
        },
        MuiPaper: {
          elevation0: {
            border: "1px solid #dddddd",
          },
          elevation1: {
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
          },
        },
        MuiTabs: {
          root: {
            borderBottom: "1px solid #dddddd",
          },
        },
        MuiTypography: {
          h1: {
            fontFamily: '"Quattrocento", sans-serif',
          },
          h2: {
            fontFamily: '"Quattrocento", sans-serif',
          },
          h3: {
            fontFamily: '"Quattrocento", sans-serif',
          },
          h4: {
            fontFamily: '"Quattrocento", sans-serif',
          },
          h5: {
            fontFamily: '"Quattrocento", sans-serif',
          },
          h6: {
            fontFamily: '"Quattrocento", sans-serif',
          },
          body2: {
            fontWeight: 500,
          },
        },
      },
    })
  }

  render() {
    return (
      <ThemeProvider theme={this.getTheme()}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          {this.props.children}
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    )
  }
}
