const getBigHTime = (value: number) => (value < 10 ? `0${value}` : `${value}`)

export const getFriendlyTimeElapsed = (time: number) => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = time % 60
  return `${getBigHTime(hours)}h ${getBigHTime(minutes)}m ${getBigHTime(
    seconds
  )}s`
}
