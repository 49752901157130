import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import React from "react"

import MinimumTimeRequirementDialog from "./MinimumTimeRequirementDialog"

interface Props {
  onClick: (lessonId: string) => void
  onFinish: () => void
  currentLessonId: string | null
  nextLessonId?: string
  hasCompletedCourse: boolean
  hasRequiredTimeElapsed: boolean
  hasSuccessfullyCompletedQuiz: boolean
}

const NextButton = ({
  currentLessonId,
  nextLessonId,
  hasSuccessfullyCompletedQuiz,
  hasRequiredTimeElapsed,
  hasCompletedCourse,
  onClick,
  onFinish,
}: Props) => {
  const showTooltip = Boolean(currentLessonId && !hasSuccessfullyCompletedQuiz)
  if (nextLessonId) {
    return (
      <Tooltip title={showTooltip ? "You must take the quiz to proceed" : ""}>
        <div>
          <Button
            disabled={showTooltip}
            variant="contained"
            color="primary"
            onClick={() => onClick(nextLessonId)}
          >
            Next
          </Button>
        </div>
      </Tooltip>
    )
  }
  if (!hasCompletedCourse) {
    return (
      <>
        <MinimumTimeRequirementDialog
          currentLessonId={currentLessonId}
          hasSuccessfullyCompletedQuiz={hasSuccessfullyCompletedQuiz}
          timeMet={hasRequiredTimeElapsed}
        />
        <Tooltip
          title={
            showTooltip ? "You must take the quiz to complete this course" : ""
          }
        >
          <div>
            <Button
              disabled={
                !hasSuccessfullyCompletedQuiz || !hasRequiredTimeElapsed
              }
              variant="contained"
              color="primary"
              onClick={() => onFinish()}
            >
              {hasRequiredTimeElapsed
                ? "Finish Course"
                : `Minimum Time Requirement Not Met`}
            </Button>
          </div>
        </Tooltip>
      </>
    )
  }
  return (
    <Button variant="contained" color="primary" onClick={() => onFinish()}>
      View Certificate
    </Button>
  )
}

export default NextButton
