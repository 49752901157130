import { Middleware } from "redux"

import { routes } from "../../routes/constants/routes"
import { history } from "../../store"
import { NAVIGATE_TO_COURSE } from "../constants/ActionTypes"

export const courseRedirect: Middleware = () => (next) => (action) => {
  switch (action.type) {
    case NAVIGATE_TO_COURSE:
      const { payload } = action
      if (payload) {
        history.push(routes.APP.COURSE.replace(":id", payload))
      }
      break
  }
  return next(action)
}
