import { ChildDataProps, graphql } from "react-apollo"

import withPageTitle from "../../../routes/containers/withPageTitle"
import LicenseRequirements from "../components/LicenseRequirements"
import licenseRequirements from "../queries/licenseRequirements"
import { CurrentLicenseRequirements } from "../queries/types/CurrentLicenseRequirements"

export default withPageTitle("License Requirements")(
  graphql<
    unknown,
    CurrentLicenseRequirements,
    unknown,
    ChildDataProps<unknown, CurrentLicenseRequirements>
  >(licenseRequirements, {
    options: { fetchPolicy: "cache-and-network" },
  })(LicenseRequirements)
)
