import { connect } from "react-redux"

import { createSuccessNotification } from "../actions/createSuccessNotification"

export interface OnSaveProps {
  onSaveMessage: (message: string) => void
}

const withOnSaveMessage = connect<any, OnSaveProps>(null, {
  onSaveMessage: createSuccessNotification,
})

export default withOnSaveMessage
