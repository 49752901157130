import { connect } from "react-redux"

import { onPasswordResetSuccess } from "../actions/onPasswordResetSuccess"
import ChangePassword from "../components/ChangePassword"

const mapDispatchToProps = {
  onResetSuccess: onPasswordResetSuccess,
}

export default connect(null, mapDispatchToProps)(ChangePassword)
