import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { State } from "../../store"
import MissingDetailsAlert from "../components/MissingDetailsAlert"
import { shouldShowMissingDetailsContainer } from "../selectors/shouldShowMissingDetailsContainer"

const mapStateToProps = (state: State) => {
  return {
    shouldShow: shouldShowMissingDetailsContainer(state),
  }
}

export default withRouter(connect(mapStateToProps)(MissingDetailsAlert))
