import { asyncActionTypes } from "../../helpers/reducers"

export const RESET_STATUS = "RESET_STATUS"

export const FETCH_CURRENT_USER = asyncActionTypes("FETCH_CURRENT_USER")
export const LOGIN_USER = asyncActionTypes("LOGIN_USER")
export const LOGOUT_USER = asyncActionTypes("LOGOUT_USER")
export const REGISTER_USER = asyncActionTypes("REGISTER_USER")
export const SAVE_PROFILE = asyncActionTypes("SAVE_PROFILE")
export const PASSWORD_RESET_REQUEST = asyncActionTypes("PASSWORD_RESET_REQUEST")
export const PASSWORD_RESET_CONFIRM = asyncActionTypes("PASSWORD_RESET_CONFIRM")
export const HEARTBEAT = asyncActionTypes("HEARTBEAT")
