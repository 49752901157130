import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React from "react"
import RouterLink from "../../helpers/components/RouterLink"
import { routes } from "../../routes/constants/routes"

import { reportError } from "../api/reportError"

type Props = unknown
interface ComponentState {
  error?: Record<string, unknown>
}
class ErrorBoundaryWithMessage extends React.Component<Props, ComponentState> {
  static getDerivedStateFromError(error: unknown) {
    return { error }
  }

  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error, info: unknown) {
    reportError(error, info)
  }

  private getErrorMessage(): React.ReactNode {
    const { error } = this.state
    if (!error) {
      return undefined
    }
    if (typeof error.toString === "function") {
      return error.toString()
    }
    if (error.message) {
      return error.message as string
    }
    console.error(error)
    return "Unknown error encountered. Please see console"
  }

  private getErrorStack(): React.ReactNode {
    const { error } = this.state
    if (!error) {
      return undefined
    }
    if (typeof error.stack === "string") {
      return error.stack.substring(0, 1000)
    }
    return error.stack as string
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <Card elevation={1}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              <b>Fatal Error</b>
            </Typography>
            <Typography gutterBottom>
              It looks like this page has encountered a fatal error. Please try
              refreshing to return to your work. If the error persists, please
              reach out to us by using the contact form. You can navigate to the
              form by{" "}
              <RouterLink to={routes.SECONDARY_NAV.CONTACT_US} blue>
                clicking here
              </RouterLink>
              .
            </Typography>
            <Typography component="div" variant="caption">
              <details>
                <summary>Technical Details</summary>
                <br />
                <code>{this.getErrorMessage()}</code>
                <br />
                <code>{this.getErrorStack()}</code>
              </details>
            </Typography>
          </CardContent>
        </Card>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundaryWithMessage
