import React, { Component } from "react"

import { Status } from "../../../helpers/reducers"
import { routes } from "../../../routes/constants/routes"
import { history } from "../../../store"
import { RegisterPayload } from "../../actions/registerUser"
import { combineObjects } from "../../operators/combineObjects"
import PartOneForm from "./PartOneForm"
import PartTwoForm from "./PartTwoForm"

export interface Props {
  status: Status
  errors?: RestError
  currentUser: User | null
  registerUser: (payload: RegisterPayload) => void
  saveUser: (user: User) => void
  isLoading: boolean
  emailInvalidError: string
  passwordInvalidError: string
}

class RegisterWrapper extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const onScreenTwo = Boolean(prevProps.currentUser) && this.props.currentUser
    const doneLoading = prevProps.isLoading && !this.props.isLoading
    const hasSavedScreenTwo =
      onScreenTwo && doneLoading && this.props.status === Status.SUCCEEDED
    if (hasSavedScreenTwo) {
      history.push(`${routes.APP.DASHBOARD}?welcome=open`)
    }
  }

  render() {
    const {
      currentUser,
      isLoading,
      emailInvalidError,
      passwordInvalidError,
      registerUser,
      errors,
    } = this.props
    return !currentUser ? (
      <PartOneForm
        register={registerUser}
        requestLoading={isLoading}
        emailError={emailInvalidError}
        passwordError={passwordInvalidError}
      />
    ) : (
      <PartTwoForm
        currentUser={currentUser}
        onSubmit={this.onSubmit}
        requestLoading={isLoading}
        requestErrors={errors}
      />
    )
  }

  private readonly onSubmit = (user: User) => {
    const existingDetails: User = { ...(this.props.currentUser || ({} as any)) }
    const combinedDetails: User = combineObjects(existingDetails, user)
    this.props.saveUser(combinedDetails)
  }
}

export default RegisterWrapper
