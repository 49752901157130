import React from "react"
import { ApolloProvider } from "react-apollo"

import { client } from "../client/client"

const GraphQLProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

GraphQLProvider.displayName = "GraphQLProvider"

export default GraphQLProvider
