import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import React, { Component } from "react"

import { ErrorMessages, resetPassword } from "../api/resetPassword"
import ChangePasswordForm, { State as FormState } from "./ChangePasswordForm"

interface Props {
  onResetSuccess: () => void
}

interface State {
  errors?: ErrorMessages
  loading: boolean
}

class ChangePassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  render() {
    return (
      <Card elevation={1}>
        <CardContent>
          <Typography align="left" gutterBottom>
            <b>Note:</b> Both password fields must match.
          </Typography>
          <ChangePasswordForm
            onSubmit={this.resetPassword}
            requestLoading={this.state.loading}
            requestErrors={this.state.errors}
          />
        </CardContent>
      </Card>
    )
  }

  private readonly resetPassword = async (
    { newPassword, confirmNewPassword }: FormState,
    reset: () => void
  ) => {
    this.setState({
      errors: undefined,
      loading: true,
    })

    try {
      await resetPassword(newPassword, confirmNewPassword)
      this.props.onResetSuccess()
      reset()
    } catch (err) {
      const errors: ErrorMessages = err?.messages
      this.setState({
        errors,
      })
    }

    this.setState({
      loading: false,
    })
  }
}

export default ChangePassword
