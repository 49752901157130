import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"

interface Props {
  dateCompleted: string | null
}

const DateCompleted = ({ dateCompleted }: Props) => {
  if (!dateCompleted) {
    return null
  }
  return (
    <Typography variant="caption">
      ({moment(dateCompleted).format("ll")})
    </Typography>
  )
}

export default DateCompleted
