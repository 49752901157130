import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import React from "react"
import styled from "styled-components"

import LawIcon from "../../../helpers/components/LawIcon"
import { getCurrentLessonInfo } from "../../../my-courses/operators/getCurrentLessonInfo"
import { CourseCourseType } from "../../../types/Global-Types"
import { Dashboard_mostRecentCourses } from "../../queries/types/Dashboard"
import RecentCourseListButton from "./RecentCourseListButton"

interface Props {
  studentCourse: Dashboard_mostRecentCourses
}

const RecentCourseListItem = ({ studentCourse }: Props) => {
  const { currentLesson, totalLessons } = getCurrentLessonInfo(studentCourse)
  const isLaw = studentCourse.course.courseType === CourseCourseType.LAW
  return (
    <Wrapper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item zeroMinWidth md>
          <Typography variant="h6" color="primary">
            <b>{studentCourse.course.title}</b>
          </Typography>
          <Typography>
            {isLaw ? "Law" : "Non-Law"} | <b>{currentLesson}</b> of{" "}
            <b>{totalLessons}</b> lessons done
          </Typography>
        </Grid>
        <Grid item zeroMinWidth>
          <RecentCourseListButton status={studentCourse.status} />
        </Grid>
      </Grid>
      <LawIcon isLaw={isLaw} primary />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 80px;
  padding: 12px;

  img {
    opacity: 0.1;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default RecentCourseListItem
