import { connect } from "react-redux"
import { withRouter } from "react-router"

import { checkHeartbeat } from "../../auth/actions/checkHeartbeat"
import { shouldPollHeartbeat } from "../../auth/selectors/heartbeatGetters"
import withOnSaveMessage from "../../notifications/containers/withOnSaveMessage"
import { State } from "../../store"
import CourseFetcher from "../components/CourseFetcher"

const mapStateToProps = (state: State) => ({
  shouldPoll: shouldPollHeartbeat(state),
})

const mapDispatchToProps = {
  checkHeartbeat,
}

export default withRouter(
  withOnSaveMessage(connect(mapStateToProps, mapDispatchToProps)(CourseFetcher))
)
