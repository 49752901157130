import { gql } from "apollo-boost"

export default gql`
  query AllBlogPosts {
    blogPosts {
      id
      title
      modified
      thumbnail {
        id
        file
      }
    }
  }
`
