import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { isActiveLink } from "../../navbar/operators/isActiveLink"
import SidebarLink from "./SidebarLink"

export interface Props extends WithStyles {
  navigationLinks: NavigationLink[]
  activeLink: string
  collapsed: boolean
}

class AdminSidebarExtension extends React.Component<Props> {
  render() {
    const { navigationLinks, activeLink, collapsed, classes } = this.props
    return (
      <Grid item>
        <Divider />
        {!collapsed && (
          <Typography className={classes.title}>Admin Links</Typography>
        )}
        <List>
          {navigationLinks.map((link, index) => (
            <ListItem key={index} button className={classes.listItem}>
              <SidebarLink
                {...(link as any)}
                isActive={isActiveLink(link, activeLink)}
                collapsed={collapsed}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    )
  }
}

export default withStyles({
  title: {
    padding: "0.5em",
    color: "#fff",
  },
  listItem: {
    padding: 0,
  },
})(AdminSidebarExtension)
