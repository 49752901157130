export const combineObjects = (a: any, b: any): any => {
  const combined = { ...a }
  Object.keys(b).forEach((key: any) => {
    if (typeof b[key] === "object" && typeof a[key] === "object") {
      combined[key] = combineObjects(a[key], b[key])
    } else {
      combined[key] = b[key]
    }
  })
  return combined
}
