import Typography from "@material-ui/core/Typography"
import React from "react"
import { useForm } from "react-hook-form"

import CaptchaField from "../../../input/components/v2/auth/CaptchaField"
import ControlledTextField from "../../../input/components/v2/core/ControlledTextField"
import { emailPattern } from "../../../input/constants/validationPatterns"
import FormHolder from "../FormHolder"
import LoadingButton from "../LoadingButton"

interface Props {
  requestLoading: boolean
  requestError: boolean
  onSubmit: (data: State) => void
}

interface State {
  email: string
  captchaToken?: string
}

const RequestForm = ({ requestLoading, requestError, onSubmit }: Props) => {
  const { setValue, handleSubmit, control, errors } = useForm<State>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
  })

  return (
    <FormHolder onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Typography variant="h5" gutterBottom>
        Reset Password
      </Typography>
      <Typography>
        Enter the email address you used to create your account. If valid,
        we&apos;ll send you an email with a unique link to reset your password.
        Password reset emails are only sent to existing users. If you don&apos;t
        see an email delivered, please check your Spam folder.
      </Typography>
      <ControlledTextField
        fullWidth
        name="email"
        variant="outlined"
        margin="normal"
        defaultValue=""
        control={control}
        errors={errors}
        error={Boolean(requestError)}
        helperText={
          requestError
            ? "There was an error sending a request to reset this account's password"
            : undefined
        }
        rules={{ required: true, pattern: emailPattern }}
      />
      <CaptchaField
        action="resetpassword"
        onVerifyCaptcha={(token) => setValue("captchaToken", token)}
      />
      <LoadingButton
        type="submit"
        disabled={requestLoading}
        isLoading={requestLoading}
        variant="contained"
        color="primary"
        size="large"
      >
        Send
      </LoadingButton>
    </FormHolder>
  )
}

export default RequestForm
