import React from "react"
import styled from "styled-components"

import { overlayEnd } from "../../../Theme"

interface Props {
  src: string
  height: number
}

const CardImage = ({ src, height }: Props) => {
  return <ImageWrapper src={src} height={height} />
}

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(${overlayEnd}, ${overlayEnd}),
    url(${({ src }: Props) => src});
  background-size: cover;
  height: ${({ height }: Props) => height}px;
`

export default CardImage
