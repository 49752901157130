import { gql } from "apollo-boost"

export default gql`
  query LoadHomePage {
    homePage {
      heroTitle
      heroSubtitle
      philosophyTitle
      philosophyBody
      philosophyListCard
      michiganTitle
      michiganBody
      testimonials {
        id
        body
        author
      }
    }
  }
`
