import { makeStyles, Theme } from "@material-ui/core/styles"
import React from "react"

import FontAwesome from "../../helpers/components/FontAwesome"

interface Props {
  collapsed: boolean
  onClick: () => void
}

const CollapseButton = ({ collapsed, onClick }: Props) => {
  const { root, icon } = useStyles({ collapsed })
  return (
    <div className={root} onClick={onClick}>
      <FontAwesome
        className={icon}
        icon={collapsed ? "fas fa-caret-right" : "fas fa-caret-left"}
      />
    </div>
  )
}

interface StyleProps {
  collapsed: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: (props) => ({
    width: 40,
    height: 40,
    backgroundColor: "#fff",
    border: "1px solid",
    borderColor: "#dfdfdf",
    position: "absolute",
    cursor: "pointer",
    left: props.collapsed ? 159 : 299,
    textAlign: "center",
  }),
  icon: {
    color: theme.palette.primary.main,
    lineHeight: "40px",
    overflow: "visible",
    fontSize: "24px",
  },
}))

export default CollapseButton
