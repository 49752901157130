import Container from "@material-ui/core/Container"
import React from "react"
import { useQuery } from "react-apollo"
import styled from "styled-components"

import ContactCTA from "../../footer/components/ContactCTA"
import LoadingWrapper from "../../helpers/components/LoadingWrapper"
import PageError from "../../helpers/components/PageError"
import HeroContainer from "../containers/HeroContainer"
import loadHomePage from "../queries/loadHomePage"
import { LoadHomePage } from "../queries/types/LoadHomePage"
import LatestCoursesWrapper from "./latest-courses/LatestCoursesWrapper"
import MichiganWrapper from "./michigan/MichiganWrapper"
import Philosophy from "./Philosophy"
import Statistics from "./statistics/StatisticsWrapper"
import TestimonialsWrapper from "./testimonials/TestimonialsWrapper"

const HomePage = () => {
  const { data, loading, refetch } = useQuery<LoadHomePage>(loadHomePage, {})
  return (
    <LoadingWrapper isLoading={loading && !data?.homePage} large>
      {data?.homePage ? (
        <HomeWrapper>
          <HeroContainer
            maxWidth="lg"
            title={data.homePage.heroTitle}
            subtitle={data.homePage.heroSubtitle as any}
          />
          <Container maxWidth="lg" style={{ paddingBottom: 50 }}>
            <Philosophy
              title={data.homePage.philosophyTitle}
              body={data.homePage.philosophyBody}
              listCard={data.homePage.philosophyListCard}
            />
          </Container>
          <Statistics maxWidth="lg" />
          <Container maxWidth="lg">
            <MichiganWrapper
              title={data.homePage.michiganTitle}
              body={data.homePage.michiganBody}
            />
          </Container>
          <TestimonialsWrapper
            maxWidth="lg"
            testimonials={data.homePage.testimonials}
          />
          <Container maxWidth="lg" style={{ paddingTop: 50 }}>
            <LatestCoursesWrapper />
          </Container>
          <ContactCTA />
        </HomeWrapper>
      ) : (
        <PageError refetch={refetch} />
      )}
    </LoadingWrapper>
  )
}

const HomeWrapper = styled.div`
  & > div {
    overflow-x: hidden;
  }
  p,
  .typography {
    color: #666;
    line-height: 1.8;
    font-weight: 300;
    font-size: 20px;
  }
`

export default HomePage
