import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"
import { useQuery } from "react-apollo"

import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import PageError from "../../../helpers/components/PageError"
import StyledHTML from "../../../helpers/components/StyledHTML"
import TitledWrapper from "../../../sidebar/components/TitledWrapper"
import loadPrivacyPolicyPage from "../queries/loadPrivacyPolicyPage"
import { LoadPrivacyPolicyPage } from "../queries/types/LoadPrivacyPolicyPage"

const PrivacyPolicy = () => {
  const { data, loading, refetch } = useQuery<LoadPrivacyPolicyPage>(
    loadPrivacyPolicyPage
  )

  return (
    <div className="container">
      <TitledWrapper title="Privacy Policy" gapTop showBanner>
        <Card elevation={1}>
          <CardContent>
            <LoadingWrapper isLoading={loading}>
              {data?.privacyPolicyPage ? (
                <>
                  <Typography gutterBottom variant="caption" component="p">
                    Last revised on{" "}
                    {moment(data.privacyPolicyPage.created).format(
                      "MMMM D, YYYY"
                    )}
                  </Typography>
                  <StyledHTML html={data.privacyPolicyPage.body} />
                </>
              ) : (
                <PageError refetch={refetch} />
              )}
            </LoadingWrapper>
          </CardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

export default PrivacyPolicy
