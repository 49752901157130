export const APP = "/app"
export const ADMIN_ROOT = "/admin"
export const SHOP_ROOT = "/shop"

export const routes = {
  HOME: "/",
  AUTH: {
    LOGIN: "/login",
    REGISTER: "/register",
    PROFILE: "/profile",
    RESET: "/reset",
    RESET_CONFIRM: "/password-reset/:uid/:token",
    CONFIRM_EMAIL: "/confirm-email/:id",
    UNSUBSCRIBE: "/unsubscribe/:token/",
  },
  SECONDARY_NAV: {
    ABOUT: "/about",
    FAQ: "/faq",
    BLOG: "/blog",
    BLOG_POST: "/blog/:id",
    CONTACT_US: "/contact-us",
    REQUIREMENTS: "/requirements",
    POLICIES: "/privacy-policy",
    USER_AGREEMENT: "/user-agreement",
    ATTRIBUTION: "/attribution",
  },
  SHOP: {
    CART: `${SHOP_ROOT}/cart`,
    CHECKOUT: `${SHOP_ROOT}/checkout`,
    CHECKOUT_RECEIPT: `${SHOP_ROOT}/checkout/receipt`,
    COURSE_LIST: `${SHOP_ROOT}/courses`,
    COURSE_DETAILS: `${SHOP_ROOT}/courses/:id`,
  },
  APP: {
    DASHBOARD: `${APP}/dashboard`,
    COURSE: `${APP}/course/:id`,
    COURSE_CERTIFICATE: `${APP}/course/:id/finished`,
    MY_COURSES: `${APP}/my-courses`,
    MY_PURCHASES: `${APP}/my-purchases`,
  },
  ADMIN_NAV: {
    HOME: `${ADMIN_ROOT}/`,
    STUDENTS: {
      LIST: `${ADMIN_ROOT}/students`,
      EDITOR: `${ADMIN_ROOT}/students/editor`,
      EDITOR_ACTIVE: `${ADMIN_ROOT}/students/editor/:id`,
    },
    BLOG: {
      LIST: `${ADMIN_ROOT}/blog`,
      EDITOR: `${ADMIN_ROOT}/blog/editor`,
      EDITOR_ACTIVE: `${ADMIN_ROOT}/blog/editor/:id`,
    },
    COURSES: {
      LIST: `${ADMIN_ROOT}/courses`,
      EDITOR: `${ADMIN_ROOT}/courses/editor`,
      EDITOR_ACTIVE: `${ADMIN_ROOT}/courses/editor/:id`,
    },
    DISCOUNTS: {
      LIST: `${ADMIN_ROOT}/discounts`,
      EDITOR: `${ADMIN_ROOT}/discounts/editor`,
      EDITOR_ACTIVE: `${ADMIN_ROOT}/discounts/editor/:id`,
    },
    PAGES: {
      ROOT: `${ADMIN_ROOT}/pages`,
    },
    PLAYGROUND: `${ADMIN_ROOT}/playground`,
    REPORTS: `${ADMIN_ROOT}/reports`,
    SETTINGS: `${ADMIN_ROOT}/settings`,
    SUBMISSIONS: `${ADMIN_ROOT}/submissions`,
    TRANSACTIONS: {
      LIST: `${ADMIN_ROOT}/transactions`,
      DETAILS: `${ADMIN_ROOT}/transactions/:id`,
    },
  },
}
