// / <reference types="../" /

import { createSelector } from "reselect"
import { AuthState } from "../reducers"

const fetchStatus = (state: AuthState) => state.hasFetched
export const hasFetched = createSelector(
  fetchStatus,
  (fetched: boolean) => fetched
)
