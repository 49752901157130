import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import React from "react"
import { ChildDataProps } from "react-apollo"

import LoadingWrapper from "../../../helpers/components/LoadingWrapper"
import PageError from "../../../helpers/components/PageError"
import TitledWrapper from "../../../sidebar/components/TitledWrapper"
import { CurrentLicenseRequirements } from "../queries/types/CurrentLicenseRequirements"
import LicenseTypography from "./LicenseTypography"

type Props = ChildDataProps<unknown, CurrentLicenseRequirements>

const LicenseRequirements = ({ data }: Props) => {
  return (
    <div className="container full-width">
      <TitledWrapper title="License Requirements" gapTop showBanner>
        <Card elevation={1}>
          <CardContent>
            <LoadingWrapper isLoading={data.loading}>
              {data.cycleLicenseRequirements ? (
                <LicenseTypography
                  body={data.licenseRequirementsPage?.body || ""}
                  requirements={data.cycleLicenseRequirements}
                />
              ) : (
                <PageError refetch={data.refetch} />
              )}
            </LoadingWrapper>
          </CardContent>
        </Card>
      </TitledWrapper>
    </div>
  )
}

export default LicenseRequirements
