import { connect } from "react-redux"

import { State } from "../../store"
import { deleteErrorNotification } from "../actions/deleteErrorNotification"
import NotificationSnackbar from "../components/NotificationSnackbar"
import { getErrors } from "../selectors/getErrors"

const mapStateToProps = (state: State) => ({
  messages: getErrors(state),
  errorState: true,
})

const mapDispatchToProps = {
  onClose: deleteErrorNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSnackbar)
