import React, { Component } from "react"

import { getFriendlyTimeElapsed } from "../../operators/getFriendlyTimeElapsed"

interface Props {
  timeElapsed: number
  timeRequired: number
  disabled: boolean
  allowCourseFinish: () => void
}

interface State {
  timeElapsed: number
}

class TimeElapsed extends Component<Props, State> {
  intervalHandler?: number

  constructor(props: Props) {
    super(props)
    this.state = {
      timeElapsed: props.timeElapsed || 0,
    }
    this.updateTimeElapsed = this.updateTimeElapsed.bind(this)
  }

  componentDidMount() {
    if (this.props.disabled) {
      return
    }
    this.intervalHandler = window.setInterval(this.updateTimeElapsed, 1000)
  }

  componentDidUpdate({
    timeElapsed: prevTimeElapsed,
    disabled: prevDisabled,
  }: Props) {
    const { timeElapsed, disabled } = this.props
    if (timeElapsed !== prevTimeElapsed) {
      this.setState({
        timeElapsed,
      })
    }
    if (disabled && !prevDisabled) {
      clearInterval(this.intervalHandler)
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandler)
  }

  getTimeElapsed() {
    return this.state.timeElapsed
  }

  render() {
    return <span>{getFriendlyTimeElapsed(this.state.timeElapsed)}</span>
  }

  private updateTimeElapsed() {
    if (this.state.timeElapsed >= this.props.timeRequired) {
      this.props.allowCourseFinish()
    }
    this.setState({
      timeElapsed: this.state.timeElapsed + 1,
    })
  }
}

export default TimeElapsed
