export const getClasses = (activate: boolean) => (
  baseClasses: string | string[],
  activatedClasses: string | string[]
) => {
  let classes: string[] = []
  if (Array.isArray(baseClasses)) {
    classes = classes.concat(baseClasses)
  } else {
    classes.push(baseClasses)
  }
  if (activate) {
    if (Array.isArray(activatedClasses)) {
      classes = classes.concat(activatedClasses)
    } else {
      classes.push(activatedClasses)
    }
  }
  return classes.join(" ")
}
