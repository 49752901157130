import { reportError } from "../../errors/api/reportError"

export const getItem = (itemName: string) => {
  if (!window.localStorage) {
    return
  }
  try {
    return window.localStorage.getItem(itemName) || undefined
  } catch (e) {
    reportError(e, "localStorage get exception")
  }
}

export const setItem = (itemName: string, value: string) => {
  if (!window.localStorage) {
    return
  }
  try {
    window.localStorage.setItem(itemName, value)
  } catch (e) {
    reportError(e, "localStorage set exception")
  }
}
