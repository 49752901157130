import { connect } from "react-redux"

import { State } from "../../store"
import { deleteSuccessNotification } from "../actions/deleteSuccessNotification"
import NotificationSnackbar from "../components/NotificationSnackbar"
import { getSuccesses } from "../selectors/getSuccesses"

const mapStateToProps = (state: State) => ({
  messages: getSuccesses(state),
  errorState: false,
})

const mapDispatchToProps = {
  onClose: deleteSuccessNotification,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSnackbar)
