import { graphql } from "react-apollo"

import withPageTitle from "../../routes/containers/withPageTitle"
import BlogList, { PassthroughProps } from "../components/BlogList"
import allBlogPosts from "../queries/allBlogPosts"
import { AllBlogPosts } from "../queries/types/AllBlogPosts"

export default withPageTitle("Blog")(
  graphql<unknown, AllBlogPosts, unknown, PassthroughProps>(allBlogPosts, {
    options: { fetchPolicy: "cache-and-network" },
  })(BlogList)
)
