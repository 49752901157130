import { gql } from "apollo-boost"

export default gql`
  query LoadFAQPage {
    faqPage {
      title
      body
      groups {
        name
        content
      }
    }
  }
`
